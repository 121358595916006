import React from "react";
import styles from "./CheckboxForCellar.module.scss";
import HexagonIcon from "components/images/hexagon_box.svg";
import HexagonEmptyIcon from "components/images/hexagon_checked.svg";

type TCheckbox = {
    isChecked: boolean;
	onClick: () => void;
};

export const CheckboxForCellar = (params: TCheckbox): JSX.Element => {
    const {isChecked, onClick} = params;

    return (
        <div className={styles.CheckboxForCellar}>
            <div className={styles.CheckboxForCellarImage} onClick={onClick}>
                { isChecked ? <img src={HexagonEmptyIcon} alt="" /> : <img src={HexagonIcon} alt="" />}
            </div>
        </div>
    );
};
