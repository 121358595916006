import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import cwEn from "./locales/cryptowine/en.json";
import cwDe from "./locales/cryptowine/de.json";
import tndEn from "./locales/tradendrink/en.json";
import tndDe from "./locales/tradendrink/de.json";
import {isTnd} from "./env/envConfig";
import merge from "ts-deepmerge";

let transEn = cwEn;
let transDe = cwDe;

if (isTnd) {
    transEn = merge(cwEn, tndEn);
    transDe = merge(cwDe, tndDe);
}

i18n
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
// pass the i18n instance to react-i18next.
    .use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            en: {translation: transEn},
            de: {translation: transDe}
        },
        fallbackLng: "en",
        debug: true,
        keySeparator: ".",
        // not needed for react as it escapes by default
        interpolation: {escapeValue: false}
    });

i18n.on("languageChanged", (lng) => {
    localStorage.setItem("selectedLanguage", lng);
    document.documentElement.setAttribute("lang", lng);
});

export default i18n;
