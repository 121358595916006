import {TDistibutedTokens} from "components/common/B2B";
import {TEmailTextData} from "contexts_and_wrappers/B2BMassSendingContext";
import {Signer} from "ethers";
import {TTransferForTokenId, TTransferToken, TTransferTokenRecipient} from "services/api/b2bApiService";
import {getTransferParamsForEscrow} from "services/api/generalApiService";
import {signDataForTarget} from "utils/email";

/**
 * @FIXME: need to review and refactor later to
 * 1) simplify the logic
 * 2) save different specifal things like deleting extra properties for CSV generation stuff
 * 3) using either "mocked" or real email data list depends on a purpose (csv generating or email template seinding)
 */

export const getSignaturesForRecipientTokenList = async(
    recipientList: TTransferTokenRecipient[],
    account: string,
    signer: Signer
): Promise<TTransferTokenRecipient[]> => {
    if (!recipientList || !Array.isArray(recipientList) || !account || !signer) {
        return [];
    }

    // prepare signatures
    let nonce: number | undefined;

    // eslint-disable-next-line no-plusplus
    for (let recipientIndex = 0; recipientIndex < recipientList.length; recipientIndex++) {
        // eslint-disable-next-line no-plusplus
        for (let tokenIndex = 0; tokenIndex < recipientList[recipientIndex].tokens.length; tokenIndex++) {
            const tokenId = recipientList[recipientIndex].tokens[tokenIndex].token_id;
            const transferParams = await getTransferParamsForEscrow(tokenId);

            const targetAddress = transferParams.escrow_address;
            if (nonce === undefined) {
                nonce = transferParams.signed_transfer_nonce || 0;
            } else {
                nonce = nonce + 1;
            }

            if (!targetAddress) {
                throw new Error("Cannot get escrow address");
            }

            const signature = await signDataForTarget(
                tokenId,
                targetAddress,
                nonce,
                transferParams.contract_address,
                account,
                signer
            );
            recipientList[recipientIndex].tokens[tokenIndex].signature = signature;
        }
    }

    return recipientList;
};

export const getPayloadForEmailsAndTokens = async(
    distributedTokens: TDistibutedTokens[],
    account: string | undefined,
    isAuthorizedCompletely: boolean,
    signer: Signer | null,
    userId: string | null,
    bearerToken: string | null
): Promise<TTransferTokenRecipient[]> => {
    const notAllowedToContinue = (
        distributedTokens.length === 0 ||
        !account ||
        !isAuthorizedCompletely ||
        !signer ||
        !userId ||
        !bearerToken
    );

    if (notAllowedToContinue) {
        return [];
    }

    const tokensToTransfer: TTransferForTokenId[][] = distributedTokens
        .map((dTokens) => {
            return dTokens.tokenIds.map((tokenId) => {
                return {token_id: String(tokenId)};
            });
        });

    const recipientList: TTransferTokenRecipient[] = [];

    // eslint-disable-next-line no-plusplus
    for (let personIndex = 0; personIndex < tokensToTransfer.length; personIndex++) {
        const recipient: TTransferTokenRecipient = {tokens: []};

        // eslint-disable-next-line no-plusplus
        for (let tokenIndex = 0; tokenIndex < tokensToTransfer[personIndex].length; tokenIndex++) {
            const tokenId = tokensToTransfer[personIndex][tokenIndex].token_id;

            const recipientTokens: TTransferToken = {
                token_id: tokenId,
                signature: ""
            };
            recipient.tokens.push(recipientTokens);
        }

        recipientList.push(recipient);
    }

    return await getSignaturesForRecipientTokenList(recipientList, account, signer);
};

const DEFAULT_TOKEN_COUNT_FOR_SLICER = 1;
export const getTokensWithSignatures = async(
    distributedTokens: TDistibutedTokens[],
    personCount: number,
    emailDataList: TEmailTextData[],
    account: string | undefined,
    isAuthorizedCompletely: boolean,
    signer: Signer | null,
    locale: "en-US" | "de" = "de"
): Promise<TTransferTokenRecipient[]> => {
    const notAllowedToContinue = (
        distributedTokens.length === 0 ||
        emailDataList.length === 0 ||
        !account ||
        !isAuthorizedCompletely ||
        !signer
    );

    if (notAllowedToContinue) {
        return [];
    }

    const recipientList: TTransferTokenRecipient[] = [];

    // eslint-disable-next-line no-plusplus
    for (let wineIndex = 0; wineIndex < distributedTokens.length; wineIndex++) {
        const tokenSliceTimesPerPerson = Math.round(
            distributedTokens[wineIndex].tokenIds.length / personCount
        ) || DEFAULT_TOKEN_COUNT_FOR_SLICER;

        // eslint-disable-next-line no-plusplus
        for (let personIndex = 0; personIndex < distributedTokens[wineIndex].tokenIds.length; personIndex++) {
            const currentEmailRow = emailDataList[personIndex];
            if (!currentEmailRow) {
                continue;
            }

            const existingRecipientIndex = recipientList.findIndex((resip) => {
                return (
                    resip.email_address === currentEmailRow.email &&
                    resip.full_name === currentEmailRow.fullName &&
                    resip.salutation === currentEmailRow.salutation
                );
            });

            const recipient: TTransferTokenRecipient = existingRecipientIndex !== -1
                ? recipientList[existingRecipientIndex]
                : {
                    tokens: [],
                    email_address: currentEmailRow.email,
                    full_name: currentEmailRow.fullName,
                    locale: locale,
                    salutation: currentEmailRow.salutation
                };

            const tokenStartIndex = personIndex * tokenSliceTimesPerPerson;

            const currentUserTokenSlice = distributedTokens[wineIndex].tokenIds
                .slice(
                    tokenStartIndex === 0 ? tokenStartIndex : tokenStartIndex,
                    tokenStartIndex + tokenSliceTimesPerPerson
                );

            recipient.tokens = recipient.tokens.concat(
                currentUserTokenSlice.map((tokenId) => {
                    return {
                        token_id: String(tokenId),
                        signature: ""
                    } as TTransferToken;
                })
            );

            if (existingRecipientIndex === -1) {
                recipientList.push(recipient);
            }
        }
    }

    return await getSignaturesForRecipientTokenList(recipientList, account, signer);
};

export const getTokensWithSignaturesWithoutEmails = async(
    distributedTokens: TDistibutedTokens[],
    personCount: number,
    account: string | undefined,
    isAuthorizedCompletely: boolean,
    signer: Signer | null,
    locale: "en-US" | "de" = "de"
): Promise<TTransferTokenRecipient[]> => {
    const notAllowedToContinue = (
        distributedTokens.length === 0 ||
        !account ||
        !isAuthorizedCompletely ||
        !signer ||
        !personCount ||
        isNaN(Number(personCount))
    );

    if (notAllowedToContinue) {
        return [];
    }

    const mockedEmailDataList: TEmailTextData[] = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < personCount; i++) {
        mockedEmailDataList.push({
            salutation: `saluation ${i}`,
            fullName: `fullName ${i}`,
            email: `email ${i}`
        });
    }

    const recipientList: TTransferTokenRecipient[] = [];

    // eslint-disable-next-line no-plusplus
    for (let wineIndex = 0; wineIndex < distributedTokens.length; wineIndex++) {
        const tokenSliceTimesPerPerson = Math.round(
            distributedTokens[wineIndex].tokenIds.length / personCount
        ) || DEFAULT_TOKEN_COUNT_FOR_SLICER;

        // eslint-disable-next-line no-plusplus
        for (let personIndex = 0; personIndex < personCount; personIndex++) {
            const currentEmailRow = mockedEmailDataList[personIndex];
            if (!currentEmailRow) {
                continue;
            }

            const existingRecipientIndex = recipientList.findIndex((resip) => {
                return (
                    resip.email_address === currentEmailRow.email &&
                    resip.full_name === currentEmailRow.fullName &&
                    resip.salutation === currentEmailRow.salutation
                );
            });

            const recipient: TTransferTokenRecipient = existingRecipientIndex !== -1
                ? recipientList[existingRecipientIndex]
                : {
                    tokens: [],
                    email_address: currentEmailRow.email,
                    full_name: currentEmailRow.fullName,
                    locale: locale,
                    salutation: currentEmailRow.salutation
                };

            const tokenStartIndex = personIndex * tokenSliceTimesPerPerson;
            const currentUserTokenSlice = distributedTokens[wineIndex].tokenIds
                .slice(
                    tokenStartIndex === 0 ? tokenStartIndex : tokenStartIndex,
                    tokenStartIndex + tokenSliceTimesPerPerson
                );

            recipient.tokens = recipient.tokens.concat(
                currentUserTokenSlice.map((tokenId) => {
                    return {
                        token_id: String(tokenId),
                        signature: ""
                    } as TTransferToken;
                })
            );

            if (existingRecipientIndex === -1) {
                recipientList.push(recipient);
            }
        }
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < recipientList.length; i++) {
        recipientList.forEach((recipient) => {
            delete recipient.email_address;
            delete recipient.full_name;
            delete recipient.locale;
            delete recipient.salutation;
        });
    }

    return await getSignaturesForRecipientTokenList(recipientList, account, signer);
};
