import Cookie, {CookieSetOptions} from "universal-cookie";
import {getEnvConfig} from "env/envConfig";
import {TLoginResult} from "services/api/authApiService";

const cookies = new Cookie();

export const SESSION_DATA_COOKIES_KEY = "sessionDataCookies";

export type UseSessionDataCookiesParams = {
    setSessionCookies: (sessionData: TLoginResult, account: string | null | undefined) => void;
    clearSessionCookies: () => void;
};

export const useSessionDataCookies = (): UseSessionDataCookiesParams => {
    const {cookieDomain} = getEnvConfig();

    const setSessionCookies = (sessionData: TLoginResult, account: string | null | undefined) => {
        if (!sessionData) {
            return;
        }

        const cookieOptions: CookieSetOptions = {
            path: "/", // for cookies to be accessible on all the pages
            // expires?: Date; // @FIXME: do we need to set it?
            // maxAge?: number; // @FIXME: do we need to set it?
            domain: cookieDomain,
            secure: true
            // sameSite?: boolean | 'none' | 'lax' | 'strict'; // @FIXME: do we need to set it?
        };

        const authCookies: TLoginResult & {ethereumAddress?: string} = {...sessionData};
        if (account) {
            authCookies.ethereumAddress = account;
        }

        cookies.set(SESSION_DATA_COOKIES_KEY, JSON.stringify(authCookies), cookieOptions);
    };

    const clearSessionCookies = () => {
        cookies.remove(SESSION_DATA_COOKIES_KEY);
    };

    return {
        setSessionCookies,
        clearSessionCookies
    };
};
