export const getChunksFromArray = (array: unknown[], perChunk: number): unknown[][] => {
    if (!array || !Array.isArray(array) || !perChunk) {
        return [];
    }
    return array.reduce((result, curr, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!result[chunkIndex]) {
            result[chunkIndex] = [];
        }

        result[chunkIndex].push(curr);

        return result;
    }, []);
};