import React from "react";
import {Link} from "react-router-dom";

import styles from "./Footer.module.scss";
import {useTranslation} from "react-i18next";
import {SelectLanguage} from "../SelectLanguage/SelectLanguage";
import classNames from "classnames";
import {getDocumentLink} from "utils/helpers";

export const Footer = (): JSX.Element => {
    const {t, i18n} = useTranslation();
    const agbLink = getDocumentLink(i18n.language.substring(0, 2), "agb_current");
    const ppLink = getDocumentLink(i18n.language.substring(0, 2), "privacy_policy");

    return (
        <footer className={styles.Footer}>
            <ul
                className={classNames(
                    styles.topSection,
                    styles.siteNavigation
                )}
            >
                <li className={styles.topSectionLinks}>
                    {/*<Link to="/about" className="button_secondary">{t('footer.about')}</Link>*/}
                    <Link to="/contact" className="button_secondary">
                        {t("footer.contacts")}
                    </Link>
                </li>
                <li className={styles.topSectionLinks}>
                    {/*<Link to="/faq" className="button_secondary">{t('footer.faq')}</Link>*/}
                    <Link
                        to={agbLink}
                        target={"_blank"}
                        className="button_secondary"
                    >
                        {t("footer.terms")}
                    </Link>
                </li>
                <li className={styles.topSectionLinks}>
                    <Link to="/dataprivacy" className="button_secondary">
                        {t("footer.privacyPolicy")}
                    </Link>
                </li>
                <li className={styles.topSectionLinks}>
                    <Link to="/faq" className="button_secondary">
                        {t("footer.faq")}
                    </Link>
                </li>
            </ul>

            <div
                className={classNames(
                    styles.topSection,
                    styles.topIndent,
                    styles.iconButtonsWrapper
                )}
            >
                <div className={styles.topSectionLogo}>
                    <h1 className={styles.siteLogo}>
                        <img src="/images/logoPrimary.svg" alt=""/>
                    </h1>
                </div>

                <div className={classNames(styles.socialsWrapper)}>
                    <div
                        className={classNames(
                            styles.topSectionLogo,
                            styles.topIndent
                        )}
                    >
                        <Link
                            to={{pathname: "https://discord.gg/VbHE8ZdYSa"}}
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <h1 className={styles.siteLogo}>
                                <img src="/images/discord-logo.svg" alt=""/>
                            </h1>
                        </Link>
                    </div>
                    <div
                        className={classNames(
                            styles.topSectionLogo,
                            styles.topIndent
                        )}
                    >
                        <Link
                            to={{pathname: "https://twitter.com/cryptowineat"}}
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <h1 className={styles.siteLogo}>
                                <img src="/images/twitter-logo.svg" alt=""/>
                            </h1>
                        </Link>
                    </div>
                    <SelectLanguage footer={true}/>
                </div>
            </div>

            <div className={styles.copyrights}>
                <p className="overline">{t("footer.copyrights")}</p>
            </div>
        </footer>
    );
};
