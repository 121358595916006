import {Close} from "./Close";
import styles from "./InBrowserConfirmationPopup.module.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {Loader} from "components/presentational/Loader/Loader";
import React, {useEffect, useState} from "react";
import {EventFilter} from "ethers";

export type ConfirmationPopupParams = {
	accept: () => void;
	reject: () => void;
	content: string;
};

export const InBrowserConfirmationPopup = (
    params: ConfirmationPopupParams
): JSX.Element => {
    const {accept, reject, content} = params;
    const [modalWindowOpen, setModalWindowOpen] = useState<boolean>();
    const {t} = useTranslation();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const acceptHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setModalWindowOpen(false);

        setTimeout(() => {
            return accept && accept();
        }, 300);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rejectHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setModalWindowOpen(false);

        setTimeout(() => {
            return reject && reject();
        }, 300);
    };

    useEffect(() => {
        setTimeout(() => {
            return setModalWindowOpen(true);
        }, 50);
    }, []);

    return (
        <>
            <div
                className={classNames(
                    styles.bg,
                    modalWindowOpen && styles.activeBg
                )}
                onClick={rejectHandler}
            />
            <div
                className={classNames(
                    styles.PopupContainer,
                    modalWindowOpen && styles.activePopupBg
                )}
            >
                <div className={styles.Popup}>
                    <button
                        type="button"
                        className={styles.close}
                        onClick={rejectHandler}
                    >
                        <Close />
                    </button>
                    <div className={styles.PopupText}>
                        <div className={styles.icon}>
                            {<Loader type="confirmation-popup" />}
                        </div>
                        <p className={styles.mainTitle}>
                            {t("inBrowserPopup.generalMessage")}
                        </p>
                        <p className={styles.messageToSign}>{content}</p>
                    </div>
                    <div className={styles.ActionPanel}>
                        <button
                            type="button"
                            className={styles.PopupButtons}
                            onClick={acceptHandler}
                        >
                            {t("inBrowserPopup.accept")}
                        </button>
                        <button
                            type="button"
                            className={styles.PopupButtons}
                            onClick={rejectHandler}
                        >
                            {t("inBrowserPopup.reject")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
