import {useCallback, useContext, useEffect, useState} from "react";
import {
    AddEthereumChainParameter,
    Web3AuthFacadeContext
} from "cnp-frontend-core";
import mainnet from "hooks/network/mainnet.json";
import ropsten from "hooks/network/ropsten.json";
import goerli from "hooks/network/goerli.json";
import xdai from "hooks/network/xdai.json";

const allNetworks: Record<number, AddEthereumChainParameter> = {
    1: mainnet,
    3: ropsten,
    5: goerli,
    100: xdai
};

const XDAI_CHAIN_ID = 100;
const GOERLI_CHAIN_ID = 5;

const ADD_ETHEREUM_CHAIN_METHOD = "wallet_addEthereumChain";
const SWITCH_ETHEREUM_CHAIN_METHOD = "wallet_switchEthereumChain";

export type UseNetworkParams = {
	// networks: AddEthereumChainParameter[];
	activeNetwork: AddEthereumChainParameter | undefined;
	switchNetwork: (networkConfig: AddEthereumChainParameter) => void;
	switchToChainId: (chainId: number) => void;
	getNetworkName: (chainId: number) => string;
};

export const useNetwork = (): UseNetworkParams => {
    const [provider, setProvider] = useState<any>();
    const {isAuthorizedWithWeb3, getProviderAsync, chainId} =
		useContext(Web3AuthFacadeContext);

    useEffect(() => {
        let mounted = true;

        if (mounted && isAuthorizedWithWeb3) {
            (async() => {
                if (mounted) {
                    const prov = await getProviderAsync();
                    setProvider(prov);
                }
            })();
        }

        return () => {
            mounted = false;
        };
    }, [getProviderAsync, isAuthorizedWithWeb3]);

    const allNetworkChains = [
        allNetworks[XDAI_CHAIN_ID],
        allNetworks[GOERLI_CHAIN_ID]
    ];

    const switchNetwork = useCallback(
        (networkConfig: AddEthereumChainParameter) => {
            const chainIdNum = parseInt(networkConfig.chainId, 16);

            let params: AddEthereumChainParameter | { chainId: string };
            let method: string;

            if (chainIdNum === XDAI_CHAIN_ID) {
                method = ADD_ETHEREUM_CHAIN_METHOD;
                params = networkConfig;
            } else {
                method = SWITCH_ETHEREUM_CHAIN_METHOD;
                params = {chainId: networkConfig.chainId};
            }

            if (provider) {
                provider.request({
                    id: 1,
                    jsonrpc: "2.0",
                    method: method,
                    params: [params]
                });
            } else {
                console.error("Fail network switching");
            }
        },
        [provider]
    );

    const switchToChainId = (chainId: number) => {
        const configuredChain = allNetworkChains.find(
            (chain) => {
                return parseInt(chain.chainId, 16) === chainId;
            }
        );

        if (!configuredChain) {
            throw Error(`chain id ${chainId} not configured`);
        }

        switchNetwork(configuredChain);
    };

    const activeNetwork = chainId ? allNetworks[chainId] : undefined;

    const getNetworkName = (chainId: number) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return allNetworks[chainId].chainName!;
    };

    return {
        // networks: allNetworkChains,
        activeNetwork,
        switchNetwork,
        switchToChainId,
        getNetworkName
    };
};
