import React, {ReactNode} from "react";
import classNames from "classnames";

import styles from "./FormValueWrapper.module.scss";

type TFormValueWrapper = {
	centered?: boolean;
	children: ReactNode;
	blankTop?: boolean;
	largeDesktop?: boolean;
	registerGoogleForm?: boolean;
};

export const FormValueWrapper = (params: TFormValueWrapper): JSX.Element => {
    const {
        centered,
        children,
        blankTop,
        largeDesktop,
        registerGoogleForm
    } = params;

    return (
        <div
            className={classNames(
                styles.FormValueWrapper,
                centered && styles.centered,
                blankTop && styles.blankTop,
                largeDesktop && styles.largeDesktop,
                registerGoogleForm && styles.registerGoogleForm
            )}
        >
            {children}
        </div>
    );
};
