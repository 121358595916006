import {useState} from "react";

export type UseGlobalLoaderParams = {
	isLoaderShown?: boolean;
	loaderText?: string;
	showLoader: (_?: string) => void;
	hideLoader: () => void;
};

export const useGlobalLoader = (): UseGlobalLoaderParams => {
    const [isLoaderShown, setIsLoaderShown] = useState<boolean>(false);
    const [loaderText, setLoaderText] = useState<string>("");

    const showLoader = (text?: string) => {
        setLoaderText(() => {
            return text || "";
        });
        setIsLoaderShown(() => {
            return true;
        });
    };

    const hideLoader = () => {
        setIsLoaderShown(() => {
            return false;
        });
        setLoaderText(() => {
            return "";
        });
    };

    return {
        isLoaderShown,
        loaderText,
        showLoader,
        hideLoader
    };
};
