import classNames from "classnames";
import React, {FC} from "react";
import styles from "./ArrowDown.module.scss";

import {TIconWrapper} from "../declarations";

export const ArrowLeft: FC<TIconWrapper> = ({
    width,
    height,
    type
}) => {
    return (
        <svg
            className={classNames(styles.ArrowDown, type && styles[type])}
            width={width}
            height={height}
            viewBox="0 0 9 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8 1L2 7.5L8 14" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};
