import {validate} from "email-validator";
import {ethers, Signer} from "ethers";

export const isValidEmail = (email: string): boolean => {
    return validate(email);
};

const signedTransfer_selector = "0x71f03793"; //must be equal to (await apiParams()).signedTransfer_selector
export const signDataForTarget = async(
    tokenId: string,
    targetAddress: string,
    signedTransferNonce: number,
    contractAddress: string,
    account: string,
    signer: Signer
): Promise<string> => {
    const dataToSign = ethers.utils.solidityKeccak256(
        ["address", "bytes4", "address", "address", "uint256", "uint256"],
        [
            contractAddress,
            signedTransfer_selector,
            account,
            targetAddress,
            tokenId,
            signedTransferNonce
        ]
    );
    if (!dataToSign) throw new Error("invalid hash..");
    return await signer.signMessage(ethers.utils.arrayify(dataToSign));
};
