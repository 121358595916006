import React, {ReactNode, useEffect, useState} from "react";
// import styles from "../ComingSoon/ComingSoon.module.scss";
import {useTranslation} from "react-i18next";

import {Button} from "components/common/Button/Button";

import styles from "./AgeRestriction.module.scss";

export const AgeRestriction = ({children}: {
	children: ReactNode;
}): JSX.Element => {
    const [isAgeConfirmed, setAgeConfirmation] = useState(
        localStorage.getItem("ageConfirmation")
    );

    const {t} = useTranslation();

    const setLocalstorageAgeConfirmation = () => {
        localStorage.setItem("ageConfirmation", "true");
        setAgeConfirmation("true");
    };

    const goBack = () => {
        return window.history.back();
    };

    useEffect(() => {
        document.title = t("pageTitles.home");
    }, []);

    return isAgeConfirmed ? (
        <>{children}</>
    ) : (
        <>
            <section className={styles.AgeRestriction}>
                <img
                    className={styles.GrapesBackground}
                    src="/images/grapesBackground.svg"
                    alt="grapes"
                />
                <h6 className={styles.WarningMessage}>
                    {t("ageConfirmation.title")}
                </h6>
                <div className={styles.buttonsWrapper}>
                    <Button onClick={setLocalstorageAgeConfirmation}>
                        {t("ageConfirmation.yes")}
                    </Button>
                    <Button onClick={goBack}>{t("ageConfirmation.no")}</Button>
                </div>
            </section>
        </>
    );
};
