import React from "react";
import {FormWrapper} from "../../common/FormComponents/FormWrapper/FormWrapper";

import styles from "./Contacts.module.scss";
import {StaticFormValue} from "../../common/FormComponents/StaticFormValue/StaticFormValue";
import {useTranslation} from "react-i18next";
import {FullPageContentWrapper} from "../../common/FullPageContentWrapper/FullPageContentWrapper";
import {FormHorizontalSpace} from "../../common/FormComponents/FormHorizontalSpace/FormHorizontalSpace";

const Contacts = (): JSX.Element => {
    const {t} = useTranslation();

    return (
        <FullPageContentWrapper>
            <FormWrapper>
                <div className={styles.logo}>
                    <img src="/images/logoPrimaryColor.svg" alt="" />
                </div>
                <div className={styles.description}>
                    <div>
                        <StaticFormValue
                            secondary
                            label={t("contacts.imprint")}
                            value={t("contacts.imprintValue")}
                        />
                        <StaticFormValue
                            secondary
                            label={t("contacts.companyName")}
                            value="Wiener Straße 64, 3100 St. Pölten"
                        />
                    </div>
                    <img src="/images/logoPrimaryColor.svg" alt="" />
                    <div>
                        <StaticFormValue
                            label={t("contacts.managingDirector")}
                            value="Dr. Martin Karner, Dr. Hubert Schultes"
                        />
                        <StaticFormValue
                            label={t("contacts.email")}
                            value="info@cryptoagri.at"
                        />
                        <StaticFormValue
                            label={t("contacts.commercialRegisterNo")}
                            value="561428f"
                        />
                        <StaticFormValue
                            label={t("contacts.shareholder")}
                            value="LK-NÖ Holding GmbH (100%)"
                        />
                        <StaticFormValue
                            label={t("contacts.commercialRegisterCourt")}
                            value="Landesgericht St. Pölten"
                        />
                        <StaticFormValue
                            label={t("contacts.uid")}
                            value="ATU77142147"
                        />
                    </div>
                </div>
            </FormWrapper>
            <FormHorizontalSpace />
        </FullPageContentWrapper>
    );
};

export default Contacts;
