import React, {ReactChildren, ReactNode} from "react";
import classNames from "classnames";
import styles from "./LinkButton.module.scss";
import {Loader} from "components/presentational/Loader/Loader";

type TLinkButtonType = "primary" | "secondary" | "darken" | "lighten";

type TLinkButton = {
	children: ReactNode | ReactChildren | string;
	type?: TLinkButtonType;
	strictPadding?: boolean;
	onClick?: () => void;
	href?: string;
	loading?: boolean;
};

export const LinkButton = (params: TLinkButton): JSX.Element => {
    const {
        children,
        type = "darken",
        strictPadding,
        onClick,
        loading
    } = params;

    return (
        <div
            className={classNames(
                styles.ButtonWrapper,
                loading && styles.loading
            )}
        >
            <button
                className={classNames(
                    styles.LinkButton,
                    styles[type],
                    strictPadding && styles.strictPadding
                )}
                onClick={onClick || (() => {})}
            >
                {children}
            </button>
            <div className={styles.loader}>
                <Loader />
            </div>
        </div>
    );
};
