import {SessionDataContext, SessionDataContextParams} from "cnp-frontend-core";
import React, {useContext, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {TLoginResult} from "services/api/authApiService";
import {extractErrorMessage} from "utils/extractErrorMessage";
import {Button} from "../Button/Button";
import {InfoBox} from "../InfoBox/InfoBox";
import styles from "./ProfileConfirmationLinkBlock.module.scss";

type TProfileConfirmationLinkBlock = {
    confirmLinkAsync: () => Promise<void>;
};

export const ProfileConfirmationLinkBlock = (props: TProfileConfirmationLinkBlock): JSX.Element => {
    const {confirmLinkAsync} = props;
    const {sessionData}: SessionDataContextParams = useContext(SessionDataContext);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [error, setError] = useState<string>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const {t} = useTranslation();

    const isEmailConfirmed = useMemo(() => {
        return (sessionData && (sessionData as TLoginResult).email_confirmed) || false;
    }, [Boolean(sessionData)]);

    const confirmLink = async() => {
        setIsSubmitting(true);
        try {
            await confirmLinkAsync();
            setIsSuccess(true);
        } catch (err) {
            console.error(err);
            setError(extractErrorMessage(err));
        }
        setIsSubmitting(false);
    };

    if (isEmailConfirmed) {
        return <></>;
    }

    return (
        <>
            { !isSuccess && (
                <div className="row">
                    <div className={styles.confirmationLinkWrapper}>
                        <div className={styles.emailNotConfirmedLabel}>
                            {t("profile.yourEmailNotConfirmed")}
                        </div>
                        <div className={styles.emailConfirmationButton}>
                            <Button
                                onClick={confirmLink}
                                type={"red"}
                                loading={isSubmitting}
                            >
                                {t("profile.resendEmail")}
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            { isSuccess && (
                <InfoBox variant={"success"} title={t("profile.emailConfirmed")} />
            ) }

            { error && (
                <InfoBox variant={"danger"} title={error} />
            ) }
        </>
    );
};