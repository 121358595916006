import React, {useState, useEffect} from "react";
import classNames from "classnames";
import styles from "./MultiSelectInput.module.scss";
import {useFormContext, ValidationRule} from "react-hook-form";
import {useMounted} from "../../../../hooks";
import Select from "react-select";

export type TOption = {
	label: string;
	value: string;
};

type TMultiSelectInput = {
	fieldName: string;
	validation: {[name: string]: ValidationRule};
	label: string;
	options: TOption[];
	color?: "white" | "red";
	value?: string;
	manageBottlePage?: boolean;
	disabled?: boolean;
    plaseholder?: string;
};

export const MultiSelectInput = (params: TMultiSelectInput): JSX.Element => {
    const {
        fieldName,
        validation,
        label,
        options,
        color,
        manageBottlePage,
        disabled,
        plaseholder
    } = params;

    const {register, formState, watch, setValue, trigger} = useFormContext();

    const [isRegistered, setIsRegistered] = useState<boolean>(false);
    const mounted = useMounted();

    const touched = watch("touched");

    const formValue = watch(fieldName);

    const error = formState.errors[fieldName];

    const selectOption = (value: any) => {
        setValue<string>(fieldName, value.map((option: TOption) => {
            return option.value;
        }));
        trigger();
    };

    useEffect(() => {
        if (!mounted.current || isRegistered || !fieldName || !validation || !register) {
            return;
        }

        register(fieldName, validation);
        setIsRegistered(true);
    }, [fieldName, validation, register, isRegistered, mounted]);

    return (
        <>
            <div
                className={classNames(
                    styles.SelectInput,
                    error && touched && styles.error,
                    manageBottlePage && styles.manageBottlePage
                )}
            >
                {
                    label && (
                        <label htmlFor="fieldName" className={classNames("overline", color && styles[color])}>
                            {label}
                        </label>
                    )
                }

                <Select
                    className={styles.select}
                    isMulti
                    isSearchable
                    name={fieldName}
                    options={options}
                    classNamePrefix="select"
                    onChange={selectOption}
                    isDisabled={disabled}
                    placeholder={plaseholder || ""}
                />

                {
                    error && touched && (
                        <div className={styles.errorMessage}>
                            {error.message}
                        </div>
                    )
                }
            </div>
        </>
    );
};
