import React, {useContext, useMemo} from "react";
import {Route, Switch} from "react-router-dom";
import {Footer, Header} from "..";
import {
    getFilteredRoutes,
    TConditionalRouter,
    TExtendedRoute,
    Web3AuthFacadeContext,
    Web3EnvContext,
    AuthFacadeContext,
    PageTemplate,
    RequiringEthereum,
    BlockUpdateContextWrapper
} from "cnp-frontend-core";

const BlockchainGatewayNotInstalled = React.lazy(
    () => {
        return import(
            "../../../components/pages/BlockchainGatewayNotInstalled/BlockchainGatewayNotInstalled"
        );
    }
);
const BlockchainGatewayConnectWallet = React.lazy(
    () => {
        return import(
            "../../../components/pages/BlockchainGatewayConnectWallet/BlockchainGatewayConnectWallet"
        );
    }
);

export const Routers = ({routes}: {
	routes: TConditionalRouter[];
}): JSX.Element => {
    const {env: envParams} = useContext(Web3EnvContext);
    const {
        library,
        isEthereumBrowserSupported,
        isWalletConnectSupported,
        isInBrowserWalletSupported
    }: AuthFacadeContext = useContext(Web3AuthFacadeContext);

    const conditions = useMemo(() => {
        //@FIXME: check the conditions
        return {
            isNotInstalled:
				!isEthereumBrowserSupported &&
				!isWalletConnectSupported &&
				!isInBrowserWalletSupported,
            isNotConnected: false,
            isFallback: !library
        };
    }, [
        isEthereumBrowserSupported,
        isWalletConnectSupported,
        isInBrowserWalletSupported,
        library
    ]);

    return useMemo(() => {
        return (
            <Switch>
                {getFilteredRoutes(routes).map((route: TExtendedRoute) => {
                    const {
                        path,
                        withWallet,
                        component: Component,
                        withSidebar,
                        title,
                        listenToBlockChanges,
                        ...props
                    } = route;

                    return (
                        <Route
                            key={path}
                            path={path}
                            {...props}
                            component={(routerParams: JSX.IntrinsicAttributes) => {
                                return (
                                    <BlockUpdateContextWrapper
                                        env={envParams}
                                        listenToBlockChanges={listenToBlockChanges}
                                    >
                                        <PageTemplate
                                            title={title}
                                            header={<Header />}
                                            footer={<Footer />}
                                            env={envParams}
                                        >
                                            {withWallet ? (
                                                <RequiringEthereum
                                                    {...routerParams}
                                                    pageComponent={Component}
                                                    notInstalledComponent={
                                                        BlockchainGatewayNotInstalled
                                                    }
                                                    connectWalletComponent={
                                                        BlockchainGatewayConnectWallet
                                                    }
                                                    conditions={conditions}
                                                />
                                            ) : (
                                                <Component {...routerParams} />
                                            )}
                                        </PageTemplate>
                                    </BlockUpdateContextWrapper>
                                );
                            }}
                        />
                    );
                })}
            </Switch>
        );
    }, [conditions, envParams, routes]);
};
