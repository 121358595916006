import React from "react";

export const Close = (): JSX.Element => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 5.44488L7.5 3.22266L5 5.44488"
                stroke="#2D2E2B"
                strokeWidth="1"
                strokeLinecap="round"
            />
            <path
                d="M5 1.00043L7.5 3.22266L10 1.00043"
                stroke="#2D2E2B"
                strokeWidth="1"
                strokeLinecap="round"
            />
        </svg>
    );
};
