import {EnvContextParams} from "cnp-frontend-core";
import process from "process";

export const tndLocal = "tradendrinklocal";
export const tndTesting = "test.trade-drink.at";
export const tndBase = "trade-drink.at";

const testsubdomains = ["test.", "notverysecrettest", "cryptoagri.customer.capacity.at", tndTesting];
const testvmdomains = ["cryptowine.example"];
const tndFallbackDomains = ["trade-drink.com", "tradendrink.com", "tradendink.at"];
const prodsubdomains = ["cryptowine.at", "notverysecretprod", tndBase, ...tndFallbackDomains];
const localtesting = ["localhost", "192.168", "10.", "127.0.0.", tndLocal];

export function isInList(hostname: string, hostFragments: string[]): boolean {
    for (const testsubdomain of hostFragments) {
        if (hostname.startsWith(testsubdomain)) return true;
    }
    return false;
}

export const isTnd = isInList(window.location.hostname, [tndLocal, tndTesting, tndBase, ...tndFallbackDomains]);
//export const isTnd = true;
const blockchainSymbol = isTnd ? "TND" : "WINE";
const baseHost = isTnd ? tndBase : "cryptowine.at";
const baseHostOld = isTnd ? "tradendrink.com" : "cryptowine.at";

type TSpecificFeatures = {
    blockchainSymbol: string;
    showComingSoonPage: boolean;
    simpleAuthBasePath: string;
    mediaBasePath: string;
    deepLinkOnboarding: boolean;
    appVersion: string;
    showCellarCarousel: boolean;
    shippingEnabled: boolean;
    enableBottleOnClick: boolean;
    cmsTranslationsUrl: string;
    showAboutTeamPageWithCmsData?: boolean;
    showAboutProjectsPageWithCmsData?: boolean;
    showHomePageWithCmsData?: boolean;
    showFaqPageWithCmsData?: boolean;
    showProfile: boolean;
    authorizationConfig: {
        googleClientId?: string;
    };
    stripePk: string;
    useServiceWorkers: boolean;
    xdaiUri: string;
    leftOverAccount: string;
    valentineDayEnabled: boolean;
    valentineTokens: number[];
    cookieDomain: string;
    tradeAccountEmail: string;
    tradingPlatformLink: string | null;
}

export type TAgriContextParams = EnvContextParams & TSpecificFeatures;

const CW_TEST_STRIPE_KEY =
    "pk_test_51K7Qz7BNk46rGWd8jQ7IDH3hTAY5lZnnOlM76uZOZhymvrxKTlH6CHFhKDRXHj9XDDOhy6h2a4PdBqqW7pcghzvD00ifzEzsDz";
const CW_LIVE_STRIPE_KEY =
    "pk_live_51KByI2GZldDnjIrXvGZ6T4VLZGLqhKnSEZBShz0Ksvhhz9EZ2VGH9VuWEKmMA2HMoLeXiNPXssObsM6cOSmPUmzh00vkctONGz";

const TND_TEST_STRIPE_KEY =
    "pk_test_51KwRO6LRGohHN3oCfE75atCO5kPQy90Ey1Rl5hLZPfyY9j7dPoLWdtGxtyiRZ4HHye4hg4eiP0GX82HbnkT9agTe007sWSs7xd";
const TND_LIVE_STRIPE_KEY =
    "pk_live_51KxSh7CjMEGP3kFHlxQlkj8osdJKSPBxMZdtTCIsP1bepvL8aIda3uABFhhiPcGWz5MHUM8Y221WmBqB3lZ76SGI00Wjmq6XN1";

const testStripeKey = isTnd ? TND_TEST_STRIPE_KEY : CW_TEST_STRIPE_KEY;
const liveStripeKey = isTnd ? TND_LIVE_STRIPE_KEY : CW_LIVE_STRIPE_KEY;

const APCW_GOOGLE_CLIENT_ID =
    "602665677156-tcbeuqdkke90v0ec7sa3l2tg5b0srb3m.apps.googleusercontent.com";


export const getEnvConfig: () => TAgriContextParams = () => {
    const prodRamp = {
        url: "https://buy.ramp.network",
        hostApiKey: "r9z4d264j7oryrjuf7cj3j6mx273vvezwjc9rcaa", // @FIXME: need to add host api key and configure Ramp
        currency: "xDai"
    };

    const {hostname} = window.location;

    const localSettings: TAgriContextParams = {
        blockchainSymbol: blockchainSymbol,
        simpleAuthBasePath: `https://test.${baseHost}/simpleauth/`,
        useServiceWorkers: false,
        showProfile: true,
        shippingEnabled: true,
        showCellarCarousel: false,
        enableBottleOnClick: true,
        targetProject: "cryptoWine",
        appVersion: "LOCAL HAS NO VERSION, i guess",
        deepLinkOnboarding: true,
        cmsTranslationsUrl: `https://cms.test.${baseHostOld}`,
        // Change values to true once cms will be filled up
        showAboutTeamPageWithCmsData: true,
        showAboutProjectsPageWithCmsData: true,
        showHomePageWithCmsData: true,
        showFaqPageWithCmsData: true,
        // _____________
        mediaBasePath: `https://test.${baseHost}/`,
        showComingSoonPage: false,
        layer1NetworkId: 3,
        layer2NetworkId: 5,
        baseEnsL1: "", // @FIXME: need to add the actual one
        baseEnsL2: "", // @FIXME: need to add the actual one
        walletConnectRpcs: [
            {chainId: 3, rpcUrl: "https://ropsten.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {chainId: 5, rpcUrl: "https://goerli.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {chainId: 100, rpcUrl: "https://rpc.xdaichain.com"}
        ],
        inBrowserRpc: [
            {3: "https://ropsten.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {5: "https://goerli.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {100: "https://rpc.xdaichain.com"}
        ],
        xdaiUri: "https://rpc.xdaichain.com",
        infuraId: "e44c432502bf45e69ec954fc930c1594",
        apiBaseUri: `https://test.${baseHost}/api`,
        appBaseUri: "http://localhost:3000/",
        inBrowserWalletUri: "http://localhost:4001/api", // @FIXME: need to change to the actual api
        ramp: prodRamp,
        shoplink: "", // @FIXME: need to add the actual one
        defaultChainId: 5,
        leftOverAccount: "0xb3A3c060224E2FA919780B397E54d55A2Db3df6F", // test account
        walletConnect: {
            rpcUrl: "https://goerli.infura.io/v3/e44c432502bf45e69ec954fc930c1594",
            chainId: 5
        },
        valentineDayEnabled: true,
        valentineTokens: [102],
        stripePk: testStripeKey,
        featureConfig: {
            web3Providers: {
                walletConnect: true,
                inBrowserWallet: true
            },
            oauth: {
                google: true,
                apple: false,
                simpleEmail: true
            },
            connectorFeatures: {
                restoreSessionAutomatically: true,
                authMode: "full"
            }
        },
        authorizationConfig: {googleClientId: APCW_GOOGLE_CLIENT_ID},
        cookieDomain: `.${baseHost}`,
        tradeAccountEmail: "office+c2ctshop@3datax.com",
        tradingPlatformLink: `https://trade.test.${baseHost}`
    };

    const testSettings: TAgriContextParams = {
        blockchainSymbol: blockchainSymbol,
        stripePk: testStripeKey,
        useServiceWorkers: false,
        showProfile: true,
        shippingEnabled: true,
        showCellarCarousel: false,
        enableBottleOnClick: true,
        targetProject: "cryptoWine",
        appVersion: process.env.REACT_APP_CRYPTOWINE_VERSION || "NOT SET",
        deepLinkOnboarding: true,
        cmsTranslationsUrl: `https://cms.test.${baseHostOld}`,
        // Change values to true once cms will be filled up
        showAboutTeamPageWithCmsData: true,
        showAboutProjectsPageWithCmsData: true,
        showHomePageWithCmsData: true,
        showFaqPageWithCmsData: true,
        // _____________
        mediaBasePath: `https://test.${baseHost}/`,
        showComingSoonPage: false,
        layer1NetworkId: 3,
        layer2NetworkId: 5,
        baseEnsL1: "", // @FIXME: need to add the actual one
        baseEnsL2: "", // @FIXME: need to add the actual one
        walletConnectRpcs: [
            {chainId: 3, rpcUrl: "https://ropsten.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {chainId: 5, rpcUrl: "https://goerli.infura.io/v3/e44c432502bf45e69ec954fc930c1594"}
        ],
        inBrowserRpc: [
            {3: "https://ropsten.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {5: "https://goerli.infura.io/v3/e44c432502bf45e69ec954fc930c1594"}
        ],
        xdaiUri: "https://rpc.xdaichain.com",
        infuraId: "e44c432502bf45e69ec954fc930c1594",
        apiBaseUri: `https://test.${baseHost}/api`,
        simpleAuthBasePath: `https://test.${baseHost}/simpleauth`,
        appBaseUri: "http://localhost:3000/",
        inBrowserWalletUri: "http://localhost:4001/api", // @FIXME: need to change to the actual api
        ramp: prodRamp,
        shoplink: "", // @FIXME: need to add the actual one
        defaultChainId: 5,
        leftOverAccount: "0xb3A3c060224E2FA919780B397E54d55A2Db3df6F", // test account
        valentineDayEnabled: true,
        valentineTokens: [102],
        walletConnect: {
            rpcUrl: "https://goerli.infura.io/v3/e44c432502bf45e69ec954fc930c1594",
            chainId: 5
        },
        featureConfig: {
            web3Providers: {
                walletConnect: true,
                inBrowserWallet: true
            },
            oauth: {
                google: true,
                apple: false,
                simpleEmail: true
            },
            connectorFeatures: {
                restoreSessionAutomatically: true,
                authMode: "full"
            }
        },
        authorizationConfig: {googleClientId: APCW_GOOGLE_CLIENT_ID},
        cookieDomain: `.test.${baseHost}`,
        tradeAccountEmail: "office+c2ctshop@3datax.com",
        tradingPlatformLink: isTnd? `https://trade.test.${baseHost}`: null
    };

    const prodSettings: TAgriContextParams = {
        blockchainSymbol: blockchainSymbol,
        stripePk: liveStripeKey,
        useServiceWorkers: false,
        showProfile: true,
        shippingEnabled: true,
        showCellarCarousel: false,
        enableBottleOnClick: true,
        targetProject: "cryptoWine",
        appVersion: process.env.REACT_APP_CRYPTOWINE_VERSION || "NOT SET",
        deepLinkOnboarding: true,
        cmsTranslationsUrl: `https://cms.${baseHostOld}`,
        // Change value to true once cms will be filled up
        showAboutTeamPageWithCmsData: true,
        showAboutProjectsPageWithCmsData: true,
        showHomePageWithCmsData: true,
        showFaqPageWithCmsData: true,
        // _____________
        mediaBasePath: `https://${baseHost}/`,
        showComingSoonPage: false,
        layer1NetworkId: 1,
        layer2NetworkId: 100,
        baseEnsL1: "", // @FIXME: need to add the actual one
        baseEnsL2: "", // @FIXME: need to add the actual one
        walletConnectRpcs: [
            {chainId: 1, rpcUrl: "https://mainnet.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {chainId: 100, rpcUrl: "https://rpc.xdaichain.com"}
        ],
        inBrowserRpc: [
            {1: "https://mainnet.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {100: "https://rpc.xdaichain.com"}
        ],
        xdaiUri: "https://rpc.xdaichain.com",
        infuraId: "e44c432502bf45e69ec954fc930c1594",
        apiBaseUri: `https://${baseHost}/api`,
        simpleAuthBasePath: `https://${baseHost}/simpleauth`,
        appBaseUri: "/",
        inBrowserWalletUri: "http://localhost:4001/api", // @FIXME: need to change to the actual api
        ramp: prodRamp,
        shoplink: "", // @FIXME: need to add the actual one
        defaultChainId: 100,
        leftOverAccount: "0x65E1faaD37ea214340F48CE2e7A764e8233cC68B", // prod account
        valentineDayEnabled: true,
        valentineTokens: [102],
        walletConnect: {
            rpcUrl: "https://rpc.xdaichain.com",
            chainId: 100
        },
        featureConfig: {
            web3Providers: {
                walletConnect: false,
                inBrowserWallet: true
            },
            oauth: {
                google: true,
                apple: false,
                simpleEmail: true
            },
            connectorFeatures: {
                restoreSessionAutomatically: true,
                authMode: "full"
            }
        },
        authorizationConfig: {googleClientId: APCW_GOOGLE_CLIENT_ID},
        cookieDomain: `.${baseHost}`,
        tradeAccountEmail: "trade-drink+shop@3datax.com",
        tradingPlatformLink: isTnd ? `https://trade.${baseHost}`:null
    };

    const testVMSettings: TAgriContextParams = {
        blockchainSymbol: blockchainSymbol,
        simpleAuthBasePath: "http://cryptowine.example/simpleauth/",
        useServiceWorkers: false,
        showProfile: true,
        shippingEnabled: true,
        showCellarCarousel: false,
        enableBottleOnClick: true,
        targetProject: "cryptoWine",
        appVersion: process.env.REACT_APP_CRYPTOWINE_VERSION || "NOT SET",
        deepLinkOnboarding: true,
        cmsTranslationsUrl: `https://cms.test.${baseHost}`,
        // Change values to true once cms will be filled up
        showAboutTeamPageWithCmsData: true,
        showAboutProjectsPageWithCmsData: true,
        showHomePageWithCmsData: true,
        showFaqPageWithCmsData: true,
        // _____________
        mediaBasePath: "http://cryptowine.example",
        showComingSoonPage: false,
        layer1NetworkId: 3,
        layer2NetworkId: 5,
        baseEnsL1: "", // @FIXME: need to add the actual one
        baseEnsL2: "", // @FIXME: need to add the actual one
        walletConnectRpcs: [
            {chainId: 3, rpcUrl: "https://ropsten.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {chainId: 5, rpcUrl: "https://goerli.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {chainId: 100, rpcUrl: "https://rpc.xdaichain.com"}
        ],
        inBrowserRpc: [
            {3: "https://ropsten.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {5: "https://goerli.infura.io/v3/e44c432502bf45e69ec954fc930c1594"},
            {100: "https://rpc.xdaichain.com"}
        ],
        xdaiUri: "https://rpc.xdaichain.com",
        infuraId: "e44c432502bf45e69ec954fc930c1594",
        apiBaseUri: "http://cryptowine.example/api",
        appBaseUri: "http://cryptowine.example/",
        inBrowserWalletUri: "http://localhost:4001/api", // @FIXME: need to change to the actual api
        ramp: prodRamp,
        shoplink: "", // @FIXME: need to add the actual one
        defaultChainId: 5,
        leftOverAccount: "0xb3A3c060224E2FA919780B397E54d55A2Db3df6F", // test account
        walletConnect: {
            rpcUrl: "https://goerli.infura.io/v3/e44c432502bf45e69ec954fc930c1594",
            chainId: 5
        },
        valentineDayEnabled: true,
        valentineTokens: [102],
        stripePk: "this will not work..",
        featureConfig: {
            web3Providers: {
                walletConnect: true,
                inBrowserWallet: true
            },
            oauth: {
                google: true,
                apple: false,
                simpleEmail: true
            },
            connectorFeatures: {
                restoreSessionAutomatically: true,
                authMode: "full"
            }
        },
        authorizationConfig: {googleClientId: APCW_GOOGLE_CLIENT_ID},
        cookieDomain: `.${baseHost}`,
        tradeAccountEmail: "office+c2ctshop@3datax.com",
        tradingPlatformLink: "https://trade.test.cryptowine.at"
    };

    if (isInList(hostname, testsubdomains)) {
        // return prodSettings
        return testSettings;
    } else if (isInList(hostname, prodsubdomains)) {
        return prodSettings;
    } else if (isInList(hostname, testvmdomains)) {
        return testVMSettings;
    } else if (isInList(hostname, localtesting)) {
        // return prodSettings;
        return localSettings;
    }

    throw new Error("Environment configuration is not set up!");
};
