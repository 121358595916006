import React, {ReactNode} from "react";

import styles from "./FormWrapper.module.scss";
import classNames from "classnames";
import {TWineDetail} from "services/api/wineApiService";

type TFormWrapper = {
	children?: ReactNode;
	title?: string;
	subTitle?: ReactNode;
	color?: string;
	type?: "red" | "white";
	isFadeBg?: boolean;
	variant?: "wide";
	titleSize?: "title-md" | "title-xl";
	largeDesktop?: boolean;
	profilePage?: boolean;
	registerGoogleForm?: boolean;
	isGray?: boolean;
	auctionPage?: boolean;
	data?: TWineDetail[];
};

export const FormWrapper = (params: TFormWrapper): JSX.Element => {
    const {
        isFadeBg,
        children,
        title,
        type,
        variant,
        subTitle,
        titleSize,
        largeDesktop,
        profilePage,
        isGray,
        registerGoogleForm,
        auctionPage,
        data
    } = params;

    return (
        <div
            className={classNames(
                styles.FormWrapper,
                largeDesktop && styles.largeDesktop,
                isGray && styles.gray,
                profilePage && styles.profilePage,
                isFadeBg && styles.isFadeBg,
                variant && styles[variant],
                titleSize && styles[titleSize],
                profilePage && styles.profilePage,
                registerGoogleForm && styles.registerGoogleForm,
                auctionPage && styles.auctionPage,
                data?.length === 0 && styles.nonPadding
            )}
        >
            {title && (
                <div className={classNames(styles.headline, type && styles[type])}>
                    <h2>{title}</h2>
                    {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
                </div>
            )}
            {children}
        </div>
    );
};
