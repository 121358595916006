import React, {Context, createContext, ReactElement} from "react";
import {TCart, useCart, UseCartParams} from "hooks/useCart";
import {TAssetDetail} from "services/api/wineApiService";

export const CartContext: Context<UseCartParams> = createContext({
    addToCart: (_: TAssetDetail) => {},
    addTokenIdToItemInCart: (_: TCart) => {},
    removeTokenIdFromItemInCart: (_: TCart) => {},
    clearCartStorage: () => {},
    updateSubmittedTokenIds: () => {},
    getCartWithFilteredTokenIdsAfterSubmit: () => {
        return [] as TCart[];
    },
    updateCart: (_cart: TCart[]) => {},
    clearCart: () => {},
    setSubmittedWineIds: (_: any[]) => {}
});

export function CartContextProvider({children}: {
	children: ReactElement;
}): JSX.Element {
    const params = useCart();

    return (
        <CartContext.Provider value={params}>
            {children}
        </CartContext.Provider>
    );
}
