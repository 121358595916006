import {ConnectorNames, ConnectorType, SessionDataContext, Web3AuthFacadeContext} from "cnp-frontend-core";
import {useSessionDataCookies} from "hooks";
import React, {
    Context,
    createContext,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";

export type TServerSaltRespoonse = {
    account_address: string;
    salt: string;
};

export const LOCALLY_SAVED_SALT = "_ghv565_fhgdfbfbhd";

export type Web3GlobalStorageContextParams = {
    isAuthSignerRequested?: boolean;
    isAuthorizedInWithGoole?: boolean;
    serverSaltResponse?: TServerSaltRespoonse | null;
    updateIsAuthSignerRequested: (_: boolean) => void;
    setServerSaltResponse: (_: TServerSaltRespoonse | null) => void;
    setIsAuthorizedInWithGoole: (_: boolean) => void;
    error?: string;
    userProfile?: { email: string; name: string };
    googleError?: string;
    isGoogleTried?: boolean;
    isActivatingEthereum?: boolean;
    isActivatingWalletConnect?: boolean;
    isActivatingInBrowserWallet?: boolean;
    setIsGoogleTried: (_: boolean) => void;
    setGoogleError: (_: string) => void;
    setError: (_: string) => void;
    setUserProfile: (_: { email: string; name: string }) => void;
    resetActivatingLoader: () => void;
    updateCurrentProviderActivating: (provider: ConnectorType) => void;
};

export const Web3GlobalStorageContext: Context<Web3GlobalStorageContextParams> =
    createContext({
        updateIsAuthSignerRequested: (_: boolean) => { },
        setIsAuthorizedInWithGoole: (_: boolean) => { },
        setServerSaltResponse: (_: TServerSaltRespoonse | null) => { },
        setGoogleError: (_: string) => { },
        setError: (_: string) => { },
        setUserProfile: (_: { email: string; name: string }) => { },
        setIsGoogleTried: (_: boolean) => { },
        resetActivatingLoader: () => { },
        updateCurrentProviderActivating: (provider: ConnectorType) => { }
    });

export function Web3GlobalStorageContextProvider({children}: {
    children: ReactElement;
}): JSX.Element {
    const [error, setError] = useState<string>();
    const [isGoogleTried, setIsGoogleTried] = useState<boolean>(false);

    const {account} = useContext(Web3AuthFacadeContext);

    const [userProfile, setUserProfile] =
        useState<{ email: string; name: string }>();

    const [isAuthSignerRequested, setIsAuthSignerRequested] =
        useState<boolean>(false);

    const [serverSaltResponse, setServerSaltResponse] =
        useState<TServerSaltRespoonse | null>(null);

    const [isAuthorizedInWithGoole, setIsAuthorizedInWithGoole] =
        useState(false);
    const [googleError, setGoogleError] = useState<string>();

    const [currentProviderActivating, setCurrentProviderActivating] =
        useState<ConnectorType>(null);

    const isActivatingEthereum = useMemo(() => {
        return (
            currentProviderActivating === ConnectorNames.EthereumBrowser
        );
    }, [currentProviderActivating]);

    const isActivatingWalletConnect = useMemo(() => {
        return currentProviderActivating === ConnectorNames.WalletConnect;
    }, [currentProviderActivating]);

    const isActivatingInBrowserWallet = useMemo(() => {
        return (
            currentProviderActivating === ConnectorNames.InBrowserWallet
        );
    }, [currentProviderActivating]);

    const updateCurrentProviderActivating = (
        provider: ConnectorType
    ) => {
        setCurrentProviderActivating(provider);
    };

    const resetActivatingLoader = () => {
        setCurrentProviderActivating(null);
    };

    const updateIsAuthSignerRequested = (signerRequested: boolean) => {
        setIsAuthSignerRequested(signerRequested);
    };

    const addSalt = (data: TServerSaltRespoonse | null) => {
        setServerSaltResponse(data);
        window.localStorage.setItem(LOCALLY_SAVED_SALT, data?.salt || "");
    };

    const {sessionData} = useContext(SessionDataContext);
    const {setSessionCookies} = useSessionDataCookies();

    useEffect(() => {
        if (sessionData) {
            setSessionCookies(sessionData, account);
        }
    }, [Boolean(sessionData), account]);

    const result: Web3GlobalStorageContextParams = {
        isAuthSignerRequested,
        updateIsAuthSignerRequested,
        error,
        setError,
        serverSaltResponse,
        setServerSaltResponse: addSalt,
        isAuthorizedInWithGoole,
        setIsAuthorizedInWithGoole,
        googleError,
        setGoogleError,
        userProfile,
        setUserProfile,
        isGoogleTried,
        setIsGoogleTried,
        resetActivatingLoader,
        updateCurrentProviderActivating,
        isActivatingEthereum,
        isActivatingWalletConnect,
        isActivatingInBrowserWallet
    };

    return (
        <Web3GlobalStorageContext.Provider value={result}>
            {children}
        </Web3GlobalStorageContext.Provider>
    );
}
