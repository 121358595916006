/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {api, ReturnedPromiseResolvedType} from "../apiService";
import {TChainId} from "./wineApiService";
import {getEnvConfig} from "env/envConfig";
import {Signer} from "ethers";
import {InBrowserWalletTxSigner} from "cnp-frontend-core";

export type TLoginResult = ReturnedPromiseResolvedType<typeof login>;

export type TQuoteResult = ReturnedPromiseResolvedType<
	typeof getShippingQuote
>;

export type TShippingPaymentRequest = ReturnedPromiseResolvedType<
	typeof shippingPaymentRequest
>;

export type TLogoutResult = ReturnedPromiseResolvedType<
	typeof logout
>;

export type TEmailVerifyResult = ReturnedPromiseResolvedType<
	typeof verifyEmail
>;

export type TGetUserPreference = ReturnedPromiseResolvedType<
	typeof getUserPreference
>;

export type TCreateUserPreference = ReturnedPromiseResolvedType<
	typeof createUserPreference
>;
const chainIdStr:
	| "default"
	| "eth"
	| "ethereum"
	| "ropsten"
	| "goerli"
	| "xdai"
	| "1"
	| "3"
	| "5"
	| "100" = String(getEnvConfig().defaultChainId) as TChainId;

export type TGetUserBalance = ReturnedPromiseResolvedType<
	typeof getUserBalance
>;

export type TAuthHeader = {
    headers: {[
        key: string
    ]: string}
};

export const authHeader = (bearer_token: string): TAuthHeader => {
    return {headers: {authorization: `Bearer ${bearer_token}`}};
};

export const login = async(
    payload: string,
    signature: string,
    emailAuthSaltFromBackend?: string
) => {
    try {
        const requestPayload: {
			payload: string;
			signature: string;
			salt?: string;
		} = {payload, signature};

        if (
            emailAuthSaltFromBackend &&
			emailAuthSaltFromBackend !== "undefined"
        ) {
            requestPayload.salt = emailAuthSaltFromBackend;
        }
        const result = await api.login.loginCreate(requestPayload);
        return result?.data || null;
    } catch (err) {
        throw err;
    }
};

export const logout = async(apiLoginData: TLoginResult) => {
    if (
        !apiLoginData ||
		!apiLoginData.user_id ||
		!apiLoginData.bearer_token
    ) {
        return;
    }

    try {
        await api.user.logoutCreate(
            apiLoginData.user_id,
            authHeader(apiLoginData.bearer_token)
        );
    } catch (err) {
        console.error(err);
        throw err;
    }
};

const getMessageToSign = (accountAddress: string) => {
    return `Login to cryptowine.at with ${accountAddress}`;
};

export const signAndLogin = async(
    accountAddress: string,
    signer: Signer | null,
    updateSessionData: (_: TLoginResult) => void,
    emailAuthSaltFromBackend?: string
): Promise<TLoginResult> => {
    try {
        const messageToSign = getMessageToSign(accountAddress);
        if (!signer) {
            throw new Error("Signer is not available");
        }

        const signedMessage = await signer.signMessage(messageToSign);

        const result = await login(
            messageToSign,
            signedMessage,
            emailAuthSaltFromBackend
        );
        if (result) {
            updateSessionData(result as TLoginResult);
        }
        return result;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

export const signSilentlyAndLogin = async(
    accountAddress: string,
    signer: Signer | null,
    updateSessionData: (_: TLoginResult) => void,
    emailAuthSaltFromBackend?: string
): Promise<TLoginResult> => {
    try {
        const messageToSign = getMessageToSign(accountAddress);
        if (!signer) {
            throw new Error("Signer is not available");
        }

        const signedMessage = await (
			signer as InBrowserWalletTxSigner
        ).signMessageSilently(messageToSign);

        const result = await login(
            messageToSign,
            signedMessage,
            emailAuthSaltFromBackend
        );
        if (result) {
            updateSessionData(result as TLoginResult);
        }
        return result;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

export const verifyEmail = async(
    userId: string,
    codeFromQuery: string
) => {
    try {
        const result = await api.emailVerify.emailVerifyCreate(userId, {confirm_code: codeFromQuery});
        return result.data;
    } catch (err) {
        throw err;
    }
};

export type TShippingQuote = {
	city?: string;
	country_code: string;
	phone?: string;
	name: string;
	pickup_timestamp?: number;
	self_pickup: boolean;
	street?: string;
	token_ids: number[];
	zip?: string;
};

export const shippingPaymentRequest = async(
    shipment_id: number,
    apiLoginData: TLoginResult
) => {
    try {
        return (
            await api.user.shippingStripePaymentIntentCreate(
                apiLoginData.user_id,
                {shipment_id: shipment_id},
                authHeader(apiLoginData.bearer_token)
            )
        ).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getShippingQuote = async(
    data: TShippingQuote,
    apiLoginData: TLoginResult
) => {
    try {
        const realResult = await api.user.calcShippingQuoteCreate(
            apiLoginData.user_id,
            data,
            authHeader(apiLoginData.bearer_token)
        );
        return realResult.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const submitClaimCode = async(
    claimCode: string,
    apiLoginData: TLoginResult
) => {
    try {
        const result = await api.user.claimFromEmailCreate(
            apiLoginData.user_id,
            {claim_code: claimCode},
            authHeader(apiLoginData.bearer_token)
        );
        return result.status === 200;
    } catch (err) {
        throw err;
    }
};

export const getUserPreference = async(
    userId: string,
    preference: string,
    bearer_token: string
) => {
    try {
        const result = await api.user.preferenceDetail(
            userId,
            preference as any,
            authHeader(bearer_token)
        );
        return result.data;
    } catch (err) {
        throw err;
    }
};

export const getUserEmail = async(
    userId: string,
    bearer_token: string
) => {
    try {
        const result = await api.user.emailDetail(
            userId,
            authHeader(bearer_token)
        );
        return result.data;
    } catch (err) {
        throw err;
    }
};

const userPreferenceCreate = (
    sessionData: TLoginResult,
    payload: { key: string; new_value: string }[]
) => {
    api.user.preferencesCreate(
        sessionData.user_id,
		payload as any,
		authHeader(sessionData.bearer_token)
    );
};

export const createUserPreference = async(
    sessionData: TLoginResult,
    payload: { key: string; new_value: string }[]
) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const emailVal = payload.find(
            (value) => {
                return value.key === "email";
            }
        )!.new_value;
        await api.user.emailCreate(
            sessionData.user_id,
            {new_value: emailVal},
            authHeader(sessionData.bearer_token)
        );

        const payloadNoEmail = payload.filter((value) => {
            return value.key !== "email";
        });
        await userPreferenceCreate(sessionData, payloadNoEmail);
    } catch (err) {
        throw err;
    }
};

export const updateUserPreference = async(
    sessionData: TLoginResult,
    payload: { key: string; new_value: string }[]
) => {
    try {
        await userPreferenceCreate(sessionData, payload);
    } catch (err) {
        throw err;
    }
};

export const getUserBalance = async(
    chainId: TChainId,
    account: string
) => {
    try {
        const result = await api.balance.balanceDetail(chainId, account);
        return result.data;
    } catch (err) {
        throw err;
    }
};

export const invoiceDownload = async(
    invoiceNumber: string,
    sessionData: TLoginResult
) => {
    try {
        const result = await api.user.invoicePdfCreate(
            sessionData.user_id,
            invoiceNumber,
            authHeader(sessionData.bearer_token)
        );
        return await result.blob();
    } catch (err) {
        throw err;
    }
};

export const invoicePdfCode = async(
    invoiceNumber: string,
    sessionData: TLoginResult
) => {
    try {
        const {data: {access_code}} = await api.user.invoicePdfCodeDetail(
            sessionData.user_id,
            invoiceNumber,
            authHeader(sessionData.bearer_token)
        );

        return access_code;
    } catch (err) {
        throw err;
    }
};

export const invoicePdfDetail = async(
    invoiceNumber: string,
    sessionData: TLoginResult,
    access_code: string
) => {
    try {
        const data = await api.user.invoicePdfDetail(
            sessionData.user_id,
            invoiceNumber,
            {access_code}
        );

        return data;
    } catch (err) {
        throw err;
    }
};
