/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GenericError {
  code?: string;
  info?: object;
  message: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://test.cryptowine.at/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title cryptoAgri API
 * @version 1.2.36
 * @baseUrl https://test.cryptowine.at/api
 * @contact Capacity Blockchain Solutions GmbH <hello@capacity.at> (https://capacity.at/)
 *
 * API for the cryptoAgri project
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description You get better documentation by calling the /apidocs end point that delivers swagger UI.<br/>
   *
   * @name GetRoot
   * @summary Very basic root endpoint that just lists available endpoints.
   * @request GET:/
   * @response `200` `{ supported_urls?: ({ methods?: ("GET" | "POST" | "PUT" | "DELETE")[], url?: string })[] }` A list of endpoints
   * @response `500` `GenericError`
   */
  getRoot = (params: RequestParams = {}) =>
    this.request<
      { supported_urls?: { methods?: ("GET" | "POST" | "PUT" | "DELETE")[]; url?: string }[] },
      GenericError
    >({
      path: `/`,
      method: "GET",
      format: "json",
      ...params,
    });

  admin = {
    /**
     * No description
     *
     * @name ListPermissionUsersDetail
     * @summary Set (create/edit) wine data
     * @request GET:/admin/{user_id}/list_permission_users/{permission}
     * @secure
     * @response `200` `({ account_address: string, full_name: string })[]` List of users in the system that have a certain permission.
     * @response `400` `GenericError`
     * @response `403` `GenericError`
     * @response `500` `GenericError`
     */
    listPermissionUsersDetail: (
      userId: string,
      permission: "admin" | "shipping" | "business",
      params: RequestParams = {},
    ) =>
      this.request<{ account_address: string; full_name: string }[], GenericError>({
        path: `/admin/${userId}/list_permission_users/${permission}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MassCreateCreate
     * @summary Mass-create tokens into a certain account
     * @request POST:/admin/{user_id}/mass_create
     * @secure
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `403` `GenericError`
     * @response `500` `GenericError`
     */
    massCreateCreate: (
      userId: string,
      data: {
        deposit_per_token_eurcent?: number;
        target_address?: string;
        token_ids_used_from_leftover?: number[];
        tokens_to_create?: number;
        wine_id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, GenericError>({
        path: `/admin/${userId}/mass_create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SaleCreateCreate
     * @summary Create a new sale
     * @request POST:/admin/{user_id}/sale_create
     * @secure
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `403` `GenericError`
     * @response `500` `GenericError`
     */
    saleCreateCreate: (
      userId: string,
      data: {
        api_reserve_limit?: number;
        auction_min_price_eurcent?: number;
        sale_end_timestamp?: number;
        sale_net_price_eurcent?: number;
        sale_start_timestamp: number;
        sale_type: "auction" | "direct_sale";
        token_ids_used_from_leftover?: number[];
        tokens_to_create?: number;
        wine_id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, GenericError>({
        path: `/admin/${userId}/sale_create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name WineDataCreate
     * @summary Set (create/edit) wine data
     * @request POST:/admin/{user_id}/wine_data
     * @secure
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `403` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    wineDataCreate: (
      userId: string,
      data: {
        alcohol_pct: number;
        awards: string;
        back_image?: File;
        bottle_size: "standard" | "magnum";
        brand?: string;
        closure: string;
        country_code?: string;
        cryptowine_points: number;
        description_de: string;
        description_en: string;
        drinking_timestamp_maximum: number;
        drinking_timestamp_optimal: number;
        empty_preview_image?: File;
        empty_token_image?: File;
        front_image?: File;
        grape_variety: string;
        mail_image?: File;
        name: string;
        origin: string;
        preview_image?: File;
        producer_name: string;
        quality_category?: string;
        sparkling: boolean;
        storage_id: string;
        token_image?: File;
        vineyard?: string;
        vintage_year: number;
        volume_ml: number;
        wine_category?: string;
        wine_color: "white" | "red";
        wine_id: number;
        wine_type: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, GenericError>({
        path: `/admin/${userId}/wine_data`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  asset = {
    /**
     * @description contains all wine infos, incl. shipping status<br/>translations for properties and description fall back to English<br/>fields e.g. “color”, “region”, “grape_variety”, “description”<br/>
     *
     * @name AssetDetail
     * @summary All public details of an asset that can be shown on its detail page
     * @request GET:/asset/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ alcohol_pct: number, asset_name?: string, awards?: (string)[], back_image_path: string, bottle_size?: "standard" | "magnum", chain_id: number, closure?: string, contract_address: string, contract_ensname?: string, contract_name: string, contract_symbol: string, country_code: string, cryptowine_points?: number, deliverable?: boolean, delivery_status?: number, delivery_status_name?: "Initial" | "Sold" | "ShippingSubmitted" | "ShippingConfirmed", description_de?: string, description_en?: string, drinking_timestamp_maximum?: number, drinking_timestamp_optimal?: number, fingerprint?: string, front_image_path: string, grape_variety: string, layer_network_name?: string, layer_network_symbol?: string, name: string, network_id?: string, network_name?: string, number_of_tokenized?: number, origin?: string, owner?: string, owner_chain_id?: number, owner_ens?: string, owner_is_escrow?: boolean, preview_image_path: string, producer_name?: string, sparkling: boolean, storage_valid_until_timestamp?: number, token_id: number, token_image_path: string, tokenized_amount?: number, total_number_issued?: number, uri?: string, vintage_year: number, volume_ml?: number, wine_color: "white" | "red", wine_id?: number, wine_type: string }` Info about an asset
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    assetDetail: (
      chain: "default" | "eth" | "ethereum" | "ropsten" | "goerli" | "xdai" | "1" | "3" | "5" | "100",
      symbolOrAddress: string,
      tokenId: string,
      query?: { lang?: string },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          alcohol_pct: number;
          asset_name?: string;
          awards?: string[];
          back_image_path: string;
          bottle_size?: "standard" | "magnum";
          chain_id: number;
          closure?: string;
          contract_address: string;
          contract_ensname?: string;
          contract_name: string;
          contract_symbol: string;
          country_code: string;
          cryptowine_points?: number;
          deliverable?: boolean;
          delivery_status?: number;
          delivery_status_name?: "Initial" | "Sold" | "ShippingSubmitted" | "ShippingConfirmed";
          description_de?: string;
          description_en?: string;
          drinking_timestamp_maximum?: number;
          drinking_timestamp_optimal?: number;
          fingerprint?: string;
          front_image_path: string;
          grape_variety: string;
          layer_network_name?: string;
          layer_network_symbol?: string;
          name: string;
          network_id?: string;
          network_name?: string;
          number_of_tokenized?: number;
          origin?: string;
          owner?: string;
          owner_chain_id?: number;
          owner_ens?: string;
          owner_is_escrow?: boolean;
          preview_image_path: string;
          producer_name?: string;
          sparkling: boolean;
          storage_valid_until_timestamp?: number;
          token_id: number;
          token_image_path: string;
          tokenized_amount?: number;
          total_number_issued?: number;
          uri?: string;
          vintage_year: number;
          volume_ml?: number;
          wine_color: "white" | "red";
          wine_id?: number;
          wine_type: string;
        },
        GenericError
      >({
        path: `/asset/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  assethistory = {
    /**
     * @description use event names as “event”:”transfer”,  “event”:”won_auction”<br/>
     *
     * @name AssethistoryDetail
     * @summary History of transactions and other state changes for this asset
     * @request GET:/assethistory/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `({ block: number, chain_id: number, new_owner?: string, new_owner_ens?: string, prev_owner?: string, prev_owner_ens?: string, timestamp: number, tx: string, type: "Transfer" })[]` History of an asset
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    assethistoryDetail: (
      chain: "default" | "eth" | "ethereum" | "ropsten" | "goerli" | "xdai" | "1" | "3" | "5" | "100",
      symbolOrAddress: string,
      tokenId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          block: number;
          chain_id: number;
          new_owner?: string;
          new_owner_ens?: string;
          prev_owner?: string;
          prev_owner_ens?: string;
          timestamp: number;
          tx: string;
          type: "Transfer";
        }[],
        GenericError
      >({
        path: `/assethistory/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  balance = {
    /**
     * No description
     *
     * @name BalanceDetail
     * @summary Balance of the native currency for this address
     * @request GET:/balance/{chain}/{address}
     * @response `200` `{ wei_balance: string, wei_currency_symbol?: string }` Balances of an Ethereum account
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    balanceDetail: (
      chain: "default" | "eth" | "ethereum" | "ropsten" | "goerli" | "xdai" | "1" | "3" | "5" | "100",
      address: string,
      params: RequestParams = {},
    ) =>
      this.request<{ wei_balance: string; wei_currency_symbol?: string }, GenericError>({
        path: `/balance/${chain}/${address}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  broadcast = {
    /**
     * @description This is generic and takes any already-signed transaction.<br/>Use /txinfo/<chain>/<txhash> to track the status of the transaction after broadcasting.<br/>
     *
     * @name BroadcastCreate
     * @summary Broadcast a given signed transaction to the specified chain/network.
     * @request POST:/broadcast/{chain}
     * @response `200` `{ txhash?: string }` The tx hash of the broadcast transaction
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    broadcastCreate: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      data: { tx: string },
      params: RequestParams = {},
    ) =>
      this.request<{ txhash?: string }, GenericError>({
        path: `/broadcast/${chain}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  contractInfo = {
    /**
     * @description This is a service for the UI so it does not have to have a separate copy of the JSON files.<br/>
     *
     * @name ContractInfoDetail
     * @summary Get contract infomation.
     * @request GET:/contract_info/{chain}/{contract_type}/{info_type}
     * @response `200` `(object | string | { abi?: object, address?: string, deploy_txhash?: string })` The requested info
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    contractInfoDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      contractType:
        | "l2_rate_oracle"
        | "l2_agridata"
        | "l2_cryptowine_token"
        | "l2_shipping_manager"
        | "l2_auction_v1_factory"
        | "l2_auction_v1"
        | "l2_auction_v2_factory"
        | "l2_auction_v2"
        | "l2_directsale_v1_factory"
        | "l2_directsale_v1"
        | "l2_tax_regions"
        | "l2_escrow",
      infoType: "abi" | "address" | "instance",
      params: RequestParams = {},
    ) =>
      this.request<object | string | { abi?: object; address?: string; deploy_txhash?: string }, GenericError>({
        path: `/contract_info/${chain}/${contractType}/${infoType}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  emailVerify = {
    /**
     * @description takes parameters for email verification<br/>does not log in<br/>
     *
     * @name EmailVerifyCreate
     * @summary Email address verification, not authenticated
     * @request POST:/email_verify/{user_id}
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    emailVerifyCreate: (userId: string, data: { confirm_code: string }, params: RequestParams = {}) =>
      this.request<object, GenericError>({
        path: `/email_verify/${userId}`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  googleLoginVerify = {
    /**
     * @description Takes credential as required by https://developers.google.com/identity/gsi/web/guides/verify-google-id-token<br/>If needed, creates the user DB entry with UUID and the salt<br/>
     *
     * @name GoogleLoginVerifyCreate
     * @summary Verifies Google login data provided by the UI
     * @request POST:/google_login_verify
     * @response `200` `{ account_address?: string, salt: string, user_id?: string }` Info for completing the login
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    googleLoginVerifyCreate: (data: { credential: string }, params: RequestParams = {}) =>
      this.request<{ account_address?: string; salt: string; user_id?: string }, GenericError>({
        path: `/google_login_verify`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  invalidateCache = {
    /**
     * @description This will issue a command to invalidate a certain cache entry, as defined by the cache_name and parameters.<br/>
     *
     * @name InvalidateCacheCreate
     * @summary Invalidate a cache entry (for debugging use only).
     * @request POST:/invalidate_cache/{cache_name}
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    invalidateCacheCreate: (
      cacheName:
        | "config"
        | "keys"
        | "wallet_info"
        | "asset_list"
        | "asset_data"
        | "token_data"
        | "locale_strings"
        | "email_template_file"
        | "sale_db_data"
        | "invoice_timer_check"
        | "maintenance_timer_check"
        | "quick_tasks_timer_check",
      data: { parameter1?: string; parameter2?: string; parameter3?: string },
      params: RequestParams = {},
    ) =>
      this.request<object, GenericError>({
        path: `/invalidate_cache/${cacheName}`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  login = {
    /**
     * @description responds with bearer token and other details<br/>
     *
     * @name LoginCreate
     * @summary submit a signed message (and account address)
     * @request POST:/login
     * @response `200` `{ bearer_token: string, email_confirmed?: boolean, setup_completed: boolean, user_id: string }` Login info
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    loginCreate: (data: { payload: string; salt?: string; signature: string }, params: RequestParams = {}) =>
      this.request<
        { bearer_token: string; email_confirmed?: boolean; setup_completed: boolean; user_id: string },
        GenericError
      >({
        path: `/login`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  meta = {
    /**
     * @description See https://eips.ethereum.org/EIPS/eip-721 and<br/>https://docs.rarebits.io/v1.0/docs/listing-your-assets#section-metadata for ERC 721,<br/>and https://eips.ethereum.org/EIPS/eip-1155#metadata for ERC 1155 tokens.<br/>Also, https://docs.opensea.io/docs/metadata-standards has some docs to look at for both.<br/>
     *
     * @name MetaDetail
     * @summary Metadata JSON that OpenSea and wallets can use to get images, etc. for the asset.
     * @request GET:/meta/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ attributes?: ({ display_type?: string, trait_type?: string, value: string })[], description: string, external_url?: string, image?: string, name: string, tags?: (string)[] }` Metadata for an asset
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    metaDetail: (
      chain: "default" | "eth" | "ethereum" | "ropsten" | "goerli" | "xdai" | "1" | "3" | "5" | "100",
      symbolOrAddress: string,
      tokenId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          attributes?: { display_type?: string; trait_type?: string; value: string }[];
          description: string;
          external_url?: string;
          image?: string;
          name: string;
          tags?: string[];
        },
        GenericError
      >({
        path: `/meta/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  oauthVerify = {
    /**
     * @description payload oAuth Data (code, state, redirect_uri)<br/>Gets email address for that login<br/>If needed, creates the user DB entry with UUID and the salt<br/>
     *
     * @name OauthVerifyCreate
     * @summary Verifies OAuth2 login data provided by the UI
     * @request POST:/oauth_verify
     * @response `200` `{ account_address?: string, salt: string, user_id?: string }` Info for completing the login
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    oauthVerifyCreate: (data: { code: string; redirect_uri?: string; state: string }, params: RequestParams = {}) =>
      this.request<{ account_address?: string; salt: string; user_id?: string }, GenericError>({
        path: `/oauth_verify`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  parameters = {
    /**
     * @description The nonce corresponds to the owner of the token who needs to do the transaction.<br/>
     *
     * @name TransferDetail
     * @summary Transaction parameters (nonce, gas requirements) to use for an asset transfer.
     * @request GET:/parameters/transfer/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ contract_address: string, escrow_address?: string, gaslimit: number, gasprice: string, nonce: number, signedTransferWithOperator_selector?: string, signedTransfer_selector?: string, signed_transfer_nonce?: number, signed_transfer_supported: boolean }` Metadata for an asset
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    transferDetail: (
      chain: "default" | "eth" | "ethereum" | "ropsten" | "goerli" | "xdai" | "1" | "3" | "5" | "100",
      symbolOrAddress: string,
      tokenId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          contract_address: string;
          escrow_address?: string;
          gaslimit: number;
          gasprice: string;
          nonce: number;
          signedTransferWithOperator_selector?: string;
          signedTransfer_selector?: string;
          signed_transfer_nonce?: number;
          signed_transfer_supported: boolean;
        },
        GenericError
      >({
        path: `/parameters/transfer/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  sale = {
    /**
     * @description stat parameters for calculating likelihoods<br/>Optionally, info of current active bid(s) for or activity of that buyer on that sale<br/>
     *
     * @name SaleDetail
     * @summary All details to be shown on detail sale/auction page
     * @request GET:/sale/{sale_id}
     * @response `200` `{ asset_data: { alcohol_pct: number, awards?: (string)[], back_image_path: string, bottle_size?: "standard" | "magnum", closure?: string, country_code: string, cryptowine_points?: number, description_de?: string, description_en?: string, drinking_timestamp_maximum?: number, drinking_timestamp_optimal?: number, front_image_path: string, grape_variety: string, name: string, origin?: string, preview_image_path: string, producer_name?: string, sparkling: boolean, token_image_path: string, vintage_year: number, volume_ml?: number, wine_color: "white" | "red", wine_type: string }, auction_bid_count?: number, auction_bid_stats?: object, auction_highest_bids?: ({ asset_amount: number, bidder_address: string, net_per_asset_eurcent: number, net_per_asset_wei: string })[], auction_min_price_eurcent?: number, available_asset_amount?: number, buyer_data?: { account_address: string, auction_bid_amount?: number, auction_bid_price_eurcent?: number, auction_bid_via_crypto?: boolean, auction_net_per_asset_eurcent?: number }, chain_id?: number, sale_available_amount_onchain?: number, sale_contract_abi_name?: string, sale_contract_address?: string, sale_end_timestamp?: number, sale_eur_rate: string, sale_id: number, sale_net_price_eurcent?: number, sale_nonreserved_amount_api?: number, sale_start_timestamp?: number, sale_type: "auction" | "direct_sale", token_address?: string, token_symbol?: string }` Details about a sale/auction
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    saleDetail: (saleId: string, query?: { buyer?: string }, params: RequestParams = {}) =>
      this.request<
        {
          asset_data: {
            alcohol_pct: number;
            awards?: string[];
            back_image_path: string;
            bottle_size?: "standard" | "magnum";
            closure?: string;
            country_code: string;
            cryptowine_points?: number;
            description_de?: string;
            description_en?: string;
            drinking_timestamp_maximum?: number;
            drinking_timestamp_optimal?: number;
            front_image_path: string;
            grape_variety: string;
            name: string;
            origin?: string;
            preview_image_path: string;
            producer_name?: string;
            sparkling: boolean;
            token_image_path: string;
            vintage_year: number;
            volume_ml?: number;
            wine_color: "white" | "red";
            wine_type: string;
          };
          auction_bid_count?: number;
          auction_bid_stats?: object;
          auction_highest_bids?: {
            asset_amount: number;
            bidder_address: string;
            net_per_asset_eurcent: number;
            net_per_asset_wei: string;
          }[];
          auction_min_price_eurcent?: number;
          available_asset_amount?: number;
          buyer_data?: {
            account_address: string;
            auction_bid_amount?: number;
            auction_bid_price_eurcent?: number;
            auction_bid_via_crypto?: boolean;
            auction_net_per_asset_eurcent?: number;
          };
          chain_id?: number;
          sale_available_amount_onchain?: number;
          sale_contract_abi_name?: string;
          sale_contract_address?: string;
          sale_end_timestamp?: number;
          sale_eur_rate: string;
          sale_id: number;
          sale_net_price_eurcent?: number;
          sale_nonreserved_amount_api?: number;
          sale_start_timestamp?: number;
          sale_type: "auction" | "direct_sale";
          token_address?: string;
          token_symbol?: string;
        },
        GenericError
      >({
        path: `/sale/${saleId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StatsDetail
     * @summary Get statistics on a specific sale.
     * @request GET:/sale/{sale_id}/stats
     * @response `200` `{ auction_bid_assets?: number, auction_bid_count?: number, auction_min_price_eurcent?: number, auction_min_successful_bidder?: string, auction_min_successful_price_eurcent?: number, auction_successful_bidders?: number, available_amount_api?: number, available_amount_onchain?: number, available_asset_amount?: number, reservation_amount?: number, sale_balance_wei?: string, status?: "empty" | "not_started" | "future" | "active" | "ended" | "distributed", token_address?: string }` A list of status data about the Signer
     * @response `500` `GenericError`
     */
    statsDetail: (saleId: string, params: RequestParams = {}) =>
      this.request<
        {
          auction_bid_assets?: number;
          auction_bid_count?: number;
          auction_min_price_eurcent?: number;
          auction_min_successful_bidder?: string;
          auction_min_successful_price_eurcent?: number;
          auction_successful_bidders?: number;
          available_amount_api?: number;
          available_amount_onchain?: number;
          available_asset_amount?: number;
          reservation_amount?: number;
          sale_balance_wei?: string;
          status?: "empty" | "not_started" | "future" | "active" | "ended" | "distributed";
          token_address?: string;
        },
        GenericError
      >({
        path: `/sale/${saleId}/stats`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  salesList = {
    /**
     * @description has a "sale_type" that states if it’s a direct sale or an auction<br/>references to assets ({chain}/{symbol_or_address}/{tokenid})<br/>includes sale ID so we can easily move to detail page<br/>includes all needed information so we don’t need N API requests, it's fine to request N static images.<br/>
     *
     * @name SalesListDetail
     * @summary For "active" list_type (only supported one), active sales (including auctions) with all info to be shown on overview page
     * @request GET:/sales_list/{list_type}
     * @response `200` `({ asset_data?: { awards?: (string)[], back_image_path: string, cryptowine_points?: number, drinking_timestamp_maximum?: number, drinking_timestamp_optimal?: number, front_image_path: string, grape_variety: string, name: string, preview_image_path?: string, producer_name?: string, vintage_year: number, wine_color: "white" | "red", wine_type: string }, auction_min_price_eurcent?: number, available_asset_amount?: number, chain_id: number, sale_end_timestamp?: number, sale_id: number, sale_net_price_eurcent?: number, sale_start_timestamp?: number, sale_type?: "auction" | "direct_sale", token_address: string, token_symbol: string })[]` List of sales
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    salesListDetail: (listType: "active" | "open" | "all", params: RequestParams = {}) =>
      this.request<
        {
          asset_data?: {
            awards?: string[];
            back_image_path: string;
            cryptowine_points?: number;
            drinking_timestamp_maximum?: number;
            drinking_timestamp_optimal?: number;
            front_image_path: string;
            grape_variety: string;
            name: string;
            preview_image_path?: string;
            producer_name?: string;
            vintage_year: number;
            wine_color: "white" | "red";
            wine_type: string;
          };
          auction_min_price_eurcent?: number;
          available_asset_amount?: number;
          chain_id: number;
          sale_end_timestamp?: number;
          sale_id: number;
          sale_net_price_eurcent?: number;
          sale_start_timestamp?: number;
          sale_type?: "auction" | "direct_sale";
          token_address: string;
          token_symbol: string;
        }[],
        GenericError
      >({
        path: `/sales_list/${listType}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  shipment = {
    /**
     * No description
     *
     * @name DetailsCreate
     * @summary Submit some detail fields for a shipment.
     * @request POST:/shipment/{shipment_id}/details
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    detailsCreate: (
      shipmentId: number,
      data: { access_code: string; packing_slip_created?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<object, GenericError>({
        path: `/shipment/${shipmentId}/details`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  shipmentData = {
    /**
     * No description
     *
     * @name ShipmentDataCreate
     * @summary Submit data for handling a shipment.
     * @request POST:/shipment_data/{shipment_id}
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    shipmentDataCreate: (
      shipmentId: number,
      data: { access_code: string; tokens: { sticker_code: string; token_id: number }[]; tracking_urls?: string[] },
      params: RequestParams = {},
    ) =>
      this.request<object, GenericError>({
        path: `/shipment_data/${shipmentId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  shipmentList = {
    /**
     * @description For "open" list_type, shipments that need to be handled.<br/>For "archive" list_type, any shipment that have ever been requested for shipping (and are not failed).<br/>
     *
     * @name ShipmentListDetail
     * @summary Return a list of shipments.
     * @request GET:/shipment_list/{list_type}
     * @response `200` `({ address?: { city: string, country_code: string, phone?: string, street: string, zip: string }, name: string, packing_slip_created?: boolean, pickup_timestamp?: number, self_pickup: boolean, shipment_id: number, tokens: ({ storage_id: string, token_id: number, wine_id: number, wine_name: string })[] })[]` List of shipments
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    shipmentListDetail: (listType: "open" | "archive", query: { access_code: string }, params: RequestParams = {}) =>
      this.request<
        {
          address?: { city: string; country_code: string; phone?: string; street: string; zip: string };
          name: string;
          packing_slip_created?: boolean;
          shipped: boolean;
          pickup_timestamp?: number;
          self_pickup: boolean;
          shipment_id: number;
          tokens: { storage_id: string; token_id: number; wine_id: number; wine_name: string }[];
        }[],
        GenericError
      >({
        path: `/shipment_list/${listType}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  shippingCountries = {
    /**
     * No description
     *
     * @name ShippingCountriesList
     * @summary returns list of countries supported for shipping (ISO codes)
     * @request GET:/shipping_countries
     * @response `200` `({ code: string })[]` List of supported countries to ship to
     * @response `500` `GenericError`
     */
    shippingCountriesList: (params: RequestParams = {}) =>
      this.request<{ code: string }[], GenericError>({
        path: `/shipping_countries`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  shippingInfo = {
    /**
     * No description
     *
     * @name ShippingInfoDetail
     * @summary returns mapping of amounts to be shipped to shipping price (both EUR and on-chain currency?)
     * @request GET:/shipping_info/{country}
     * @response `200` `{ prices?: ({ bottle_amount: number, bottle_size: "standard" | "magnum", price_eurcent?: number })[], tax_region_id: string, tax_vat_permil?: number, tax_volume?: ({ max_alcohol_pct: number | null, min_alcohol_pct: number | null, per_liter_eurcent: number, sparkling: boolean | null })[] }` Info about shipping to the given country
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    shippingInfoDetail: (country: string, params: RequestParams = {}) =>
      this.request<
        {
          prices?: { bottle_amount: number; bottle_size: "standard" | "magnum"; price_eurcent?: number }[];
          tax_region_id: string;
          tax_vat_permil?: number;
          tax_volume?: {
            max_alcohol_pct: number | null;
            min_alcohol_pct: number | null;
            per_liter_eurcent: number;
            sparkling: boolean | null;
          }[];
        },
        GenericError
      >({
        path: `/shipping_info/${country}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  signedTransfer = {
    /**
     * @description The actions for this could be called by anyone but via this API, the system pays for the required gas.<br/>
     *
     * @name SignedTransferCreate
     * @summary Signed-transfer a wine NFT to a target.
     * @request POST:/signed_transfer/{chain}/{symbol_or_address}/{token_id}
     * @response `200` `{ txhash?: string }` Hash of the transaction triggered for this action
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    signedTransferCreate: (
      chain: "default" | "eth" | "ethereum" | "ropsten" | "goerli" | "xdai" | "1" | "3" | "5" | "100",
      symbolOrAddress: string,
      tokenId: string,
      data: { signature: string; target_address: string },
      params: RequestParams = {},
    ) =>
      this.request<{ txhash?: string }, GenericError>({
        path: `/signed_transfer/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        ...params,
      }),
  };
  status = {
    /**
     * @description This is mainly used for monitoring.<br/>
     *
     * @name StatusList
     * @summary Get status information for the API.
     * @request GET:/status
     * @response `200` `{ api_version?: string, db_connections?: number | null, escrow_tokens?: number | null, event_threads_expected?: number, event_threads_running?: number, "layer1"?: { addresses?: object, chain_id?: number, last_chain_block?: number, last_handled_block?: number, network_id?: string, network_name?: string, "web3_connection"?: boolean }, "layer2"?: { addresses?: { agridata?: string, "auction_v1_factory"?: string, "auction_v1_prototype"?: string, cryptowine_token?: string, "directsale_v1_factory"?: string, "directsale_v1_prototype"?: string, escrow?: string, oracle?: string, shipping_manager?: string, tax_regions?: string }, chain_id?: number, last_chain_block?: number, last_handled_block?: number, network_id?: string, network_name?: string, "web3_connection"?: boolean }, memory_used_kb?: number, sales_stats?: object, timer_threads_expected?: number, timer_threads_running?: number, token_supply?: number | null, total_threads_running?: number, user_stats?: object, waiting_for_tx?: number }` A list of status data about the Signer
     * @response `500` `GenericError`
     */
    statusList: (params: RequestParams = {}) =>
      this.request<
        {
          api_version?: string;
          db_connections?: number | null;
          escrow_tokens?: number | null;
          event_threads_expected?: number;
          event_threads_running?: number;
          layer1?: {
            addresses?: object;
            chain_id?: number;
            last_chain_block?: number;
            last_handled_block?: number;
            network_id?: string;
            network_name?: string;
            web3_connection?: boolean;
          };
          layer2?: {
            addresses?: {
              agridata?: string;
              auction_v1_factory?: string;
              auction_v1_prototype?: string;
              cryptowine_token?: string;
              directsale_v1_factory?: string;
              directsale_v1_prototype?: string;
              escrow?: string;
              oracle?: string;
              shipping_manager?: string;
              tax_regions?: string;
            };
            chain_id?: number;
            last_chain_block?: number;
            last_handled_block?: number;
            network_id?: string;
            network_name?: string;
            web3_connection?: boolean;
          };
          memory_used_kb?: number;
          sales_stats?: object;
          timer_threads_expected?: number;
          timer_threads_running?: number;
          token_supply?: number | null;
          total_threads_running?: number;
          user_stats?: object;
          waiting_for_tx?: number;
        },
        GenericError
      >({
        path: `/status`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  stickerInfo = {
    /**
     * No description
     *
     * @name StickerInfoDetail
     * @summary Get token info based on a sticker code.
     * @request GET:/sticker_info/{sticker_code}
     * @response `200` `{ chain_id: number, token_address: string, token_id: number, wine_id?: number }` info about the token connected to this sticker.
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    stickerInfoDetail: (stickerCode: string, params: RequestParams = {}) =>
      this.request<{ chain_id: number; token_address: string; token_id: number; wine_id?: number }, GenericError>({
        path: `/sticker_info/${stickerCode}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  stripeWebhook = {
    /**
     * @description See https://stripe.com/docs/webhooks/quickstart<br/>
     *
     * @name StripeWebhookCreate
     * @summary Handle events from Stripe for payments.
     * @request POST:/stripe_webhook
     * @response `200` `{ success: boolean }` Indication if the hook was handled successfully.
     * @response `400` `{ success: boolean }` Indication if the hook was handled successfully.
     * @response `500` `{ success: boolean }` Indication if the hook was handled successfully.
     */
    stripeWebhookCreate: (data: object, params: RequestParams = {}) =>
      this.request<{ success: boolean }, { success: boolean }>({
        path: `/stripe_webhook`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  txinfo = {
    /**
     * @description This is generic and reports on any transaction, even not caring if it actually exists.<br/>
     *
     * @name TxinfoDetail
     * @summary Get the status/info of a transaction on the specified chain/network.
     * @request GET:/txinfo/{chain}/{txhash}
     * @response `200` `{ blockNumber?: number, error_message?: string, gasLimit?: number, gasUsed?: number, mined?: boolean, pending?: boolean, status?: number, successful?: boolean, transactionIndex?: number }` Information about this transaction
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    txinfoDetail: (
      chain:
        | "default"
        | "eth"
        | "ethereum"
        | "ropsten"
        | "goerli"
        | "xdai"
        | "polygon"
        | "1"
        | "3"
        | "5"
        | "100"
        | "137",
      txhash: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          blockNumber?: number;
          error_message?: string;
          gasLimit?: number;
          gasUsed?: number;
          mined?: boolean;
          pending?: boolean;
          status?: number;
          successful?: boolean;
          transactionIndex?: number;
        },
        GenericError
      >({
        path: `/txinfo/${chain}/${txhash}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * @description Requires authentication to make brute-forcing claim codes harder.<br/>
     *
     * @name AssetForClaimCodeCreate
     * @summary Get asset data for a claim code. Same response as the /asset endpoint.
     * @request POST:/user/{user_id}/asset_for_claim_code
     * @secure
     * @response `200` `object`
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    assetForClaimCodeCreate: (userId: string, data: { claim_code: string }, params: RequestParams = {}) =>
      this.request<object, GenericError>({
        path: `/user/${userId}/asset_for_claim_code`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AssetPrivateDetail
     * @summary Retrieve private properties for an asset owned by the user (e.g. potential gift "greeting" message)
     * @request GET:/user/{user_id}/asset_private/{chain}/{symbol_or_address}/{token_id}
     * @secure
     * @response `200` `{ gift_greeting_message: string | null, gift_sender_name?: string | null }` private properties of an asset
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    assetPrivateDetail: (
      userId: string,
      chain: "default" | "eth" | "ethereum" | "ropsten" | "goerli" | "xdai" | "1" | "3" | "5" | "100",
      symbolOrAddress: string,
      tokenId: string,
      params: RequestParams = {},
    ) =>
      this.request<{ gift_greeting_message: string | null; gift_sender_name?: string | null }, GenericError>({
        path: `/user/${userId}/asset_private/${chain}/${symbolOrAddress}/${tokenId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CalcShippingQuoteCreate
     * @summary Submit shipping data and calculate a quote for the shipment.
     * @request POST:/user/{user_id}/calc_shipping_quote
     * @secure
     * @response `200` `{ discount_eurcent?: number, quote_expire_timestamp?: number, shipment_id: number, shipping_net_eurcent?: number, storage_net_eurcent?: number, total_cost_eurcent: number, vat_eurcent?: number, volume_tax_eurcent?: number }` Calculated shipment price and ID
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    calcShippingQuoteCreate: (
      userId: string,
      data: {
        city?: string;
        country_code: string;
        name: string;
        phone?: string;
        pickup_timestamp?: number;
        self_pickup: boolean;
        street?: string;
        token_ids: number[];
        zip?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          discount_eurcent?: number;
          quote_expire_timestamp?: number;
          shipment_id: number;
          shipping_net_eurcent?: number;
          storage_net_eurcent?: number;
          total_cost_eurcent: number;
          vat_eurcent?: number;
          volume_tax_eurcent?: number;
        },
        GenericError
      >({
        path: `/user/${userId}/calc_shipping_quote`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CalcStorageDepositQuoteCreate
     * @summary Calculate a quote for a storage (and shipping) deposit for a set of tokens/bottles.
     * @request POST:/user/{user_id}/calc_storage_deposit_quote
     * @secure
     * @response `200` `{ bottle_count: number, shipping_total_eurcent: number, storage_total_eurcent: number, total_cost_eurcent: number, vat_included_percent: number, volume_tax_eurcent: number }` Calculated total rate for storage (and shipping)
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    calcStorageDepositQuoteCreate: (
      userId: string,
      data: {
        country_code: string;
        shipping_size: number;
        storage_until_timestamp: number | "optimal" | "maximal";
        tokens?: { token_address?: string; token_id: string }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          bottle_count: number;
          shipping_total_eurcent: number;
          storage_total_eurcent: number;
          total_cost_eurcent: number;
          vat_included_percent: number;
          volume_tax_eurcent: number;
        },
        GenericError
      >({
        path: `/user/${userId}/calc_storage_deposit_quote`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description UI triggers this from claim page after login<br/>
     *
     * @name ClaimFromEmailCreate
     * @summary Claim a token that was transferred via email address
     * @request POST:/user/{user_id}/claim_from_email
     * @secure
     * @response `200` `{ txhash?: string }` Hash of the transaction triggered for this action
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    claimFromEmailCreate: (userId: string, data: { claim_code: string }, params: RequestParams = {}) =>
      this.request<{ txhash?: string }, GenericError>({
        path: `/user/${userId}/claim_from_email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        ...params,
      }),

    /**
     * @description separate from preferences because of different storage and the checks required on POST, see there.<br/>
     *
     * @name EmailDetail
     * @summary Retrieve user email
     * @request GET:/user/{user_id}/email
     * @secure
     * @response `200` `{ confirmed?: boolean, value: string }` Email address
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    emailDetail: (userId: string, params: RequestParams = {}) =>
      this.request<{ confirmed?: boolean; value: string }, GenericError>({
        path: `/user/${userId}/email`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description separate from preferences as it needs to block POST completely for OAuth2-authenticated accounts,<br/>and needs to trigger verification flow for setting a news address otherwise.<br/>
     *
     * @name EmailCreate
     * @summary Set user email
     * @request POST:/user/{user_id}/email
     * @secure
     * @response `200` `{ email_confirmed: boolean, registration_email_sent: boolean }` Email storage information
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    emailCreate: (userId: string, data: { claim_code?: string; new_value: string }, params: RequestParams = {}) =>
      this.request<{ email_confirmed: boolean; registration_email_sent: boolean }, GenericError>({
        path: `/user/${userId}/email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GiftTransferListDetail
     * @summary Retrieve list of received or sent gift transfers for this user
     * @request GET:/user/{user_id}/gift_transfer_list/{direction}
     * @secure
     * @response `200` `({ completed?: boolean, greeting_message?: string | null, received_timestamp?: number | null, sender_name?: string | null, sent_timestamp?: number | null, target_email?: string | null, wine_name: string })[]` list of gift transfers
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    giftTransferListDetail: (userId: string, direction: "received" | "sent", params: RequestParams = {}) =>
      this.request<
        {
          completed?: boolean;
          greeting_message?: string | null;
          received_timestamp?: number | null;
          sender_name?: string | null;
          sent_timestamp?: number | null;
          target_email?: string | null;
          wine_name: string;
        }[],
        GenericError
      >({
        path: `/user/${userId}/gift_transfer_list/${direction}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name InvoiceListDetail
     * @summary Get a list of invoices for the specific user.
     * @request GET:/user/{user_id}/invoice_list
     * @secure
     * @response `200` `({ date: string, invoice_number: string, pdf_available?: boolean, sale_id?: number })[]`
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    invoiceListDetail: (userId: string, params: RequestParams = {}) =>
      this.request<{ date: string; invoice_number: string; pdf_available?: boolean; sale_id?: number }[], GenericError>(
        {
          path: `/user/${userId}/invoice_list`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        },
      ),

      /**
       * No description
       *
       * @name CancelPaymentIntentCreate
       * @summary Aborts a payment intent when abandoned by the user
       * @request POST:/user/{user_id}/cancel_payment_intent/{pi_id}
       * @secure
       */
      cancelPaymentIntentCreate: (
          userId: string,
          piId: string,
          data?: any,
          params: RequestParams = {}
      ) =>
          this.request<object, GenericError>({
              path: `/user/${userId}/cancel_payment_intent/${piId}`,
              method: "POST",
              body: data,
              secure: true,
              format: "json",
              ...params,
          }),
    /**
     * @description NOTE: No Bearer token required for this endpoint, even though it is in the /user/<user_id>/ namespace!<br/>The ephemeral access code replaces the Bearer token for this access.<br/>
     *
     * @name InvoicePdfDetail
     * @summary Retrieve the PDF file for an invoice with an ephemeral access code.
     * @request GET:/user/{user_id}/invoice_pdf/{invoice_number}
     * @response `200` `void`
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    invoicePdfDetail: (
      userId: string,
      invoiceNumber: string,
      query: { access_code: string },
      params: RequestParams = {},
    ) =>
      this.request<void, GenericError>({
        path: `/user/${userId}/invoice_pdf/${invoiceNumber}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name InvoicePdfCreate
     * @summary Retrieve the PDF file for an invoice.
     * @request POST:/user/{user_id}/invoice_pdf/{invoice_number}
     * @secure
     * @response `200` `void`
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    invoicePdfCreate: (userId: string, invoiceNumber: string, params: RequestParams = {}) =>
      this.request<void, GenericError>({
        path: `/user/${userId}/invoice_pdf/${invoiceNumber}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name InvoicePdfCodeDetail
     * @summary Retrieve an ephemeral access code for an invoice.
     * @request GET:/user/{user_id}/invoice_pdf_code/{invoice_number}
     * @secure
     * @response `200` `{ access_code: string, expiration_timestamp?: number }`
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    invoicePdfCodeDetail: (userId: string, invoiceNumber: string, params: RequestParams = {}) =>
      this.request<{ access_code: string; expiration_timestamp?: number }, GenericError>({
        path: `/user/${userId}/invoice_pdf_code/${invoiceNumber}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description empty request body, empty response on success<br/>
     *
     * @name LogoutCreate
     * @summary deletes the bearer token for this session
     * @request POST:/user/{user_id}/logout
     * @secure
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    logoutCreate: (userId: string, params: RequestParams = {}) =>
      this.request<object, GenericError>({
        path: `/user/${userId}/logout`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PermissionsDetail
     * @summary Get a list of user permissions
     * @request GET:/user/{user_id}/permissions
     * @secure
     * @response `200` `(string)[]` Permissions list
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    permissionsDetail: (userId: string, params: RequestParams = {}) =>
      this.request<string[], GenericError>({
        path: `/user/${userId}/permissions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API has no validation of keys or contents, generic fields in a DB table that get returned as they are<br/>Required key values for setup complete state: "full_name", "country_code", "locale"<br/>The API doesn't actually check for other keys, the spec has a list only to make UI development easier.<br/>Please coordinate with API devs and project lead for adding additional values to the list.<br/>
     *
     * @name PreferenceDetail
     * @summary Retrieve user preference
     * @request GET:/user/{user_id}/preference/{key}
     * @secure
     * @response `200` `{ value: string }` Preference value
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    preferenceDetail: (
      userId: string,
      key: "full_name" | "country_code" | "locale" | "informAboutNews" | "birthdate" | "email",
      params: RequestParams = {},
    ) =>
      this.request<{ value: string }, GenericError>({
        path: `/user/${userId}/preference/${key}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description No key validation, see equivalent GET method<br/>
     *
     * @name PreferencesCreate
     * @summary Set user preference
     * @request POST:/user/{user_id}/preferences
     * @secure
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    preferencesCreate: (
      userId: string,
      data: {
        key: "full_name" | "country_code" | "locale" | "informAboutNews" | "birthdate" | "email";
        new_value: string;
      }[],
      params: RequestParams = {},
    ) =>
      this.request<object, GenericError>({
        path: `/user/${userId}/preferences`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SaleReservationCreate
     * @summary Create a new reservation for an on-chain direct sale.
     * @request POST:/user/{user_id}/sale_reservation
     * @secure
     * @response `200` `{ current_timestamp: number, expiration_timestamp: number, signature: string, signature_nonce: number }` Information the UI needs to know about the Stripe checkout session.
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    saleReservationCreate: (userId: string, data: { quantity: number; sale_id: number }, params: RequestParams = {}) =>
      this.request<
        { current_timestamp: number; expiration_timestamp: number; signature: string; signature_nonce: number },
        GenericError
      >({
        path: `/user/${userId}/sale_reservation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ShipmentListDetail
     * @summary List shipments for this user.
     * @request GET:/user/{user_id}/shipment_list
     * @secure
     * @response `200` `({ bottle_count?: number, created_timestamp?: number, shipment_id?: number, shipping_status?: "requested" | "processing" | "shipped" | "failed" | "delivered" })[]` List of shipments with status.
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    shipmentListDetail: (userId: string, params: RequestParams = {}) =>
      this.request<
        {
          bottle_count?: number;
          created_timestamp?: number;
          shipment_id?: number;
          shipping_status?: "requested" | "processing" | "shipped" | "failed" | "delivered";
        }[],
        GenericError
      >({
        path: `/user/${userId}/shipment_list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description See https://stripe.com/docs/checkout/quickstart and other Stripe docs for more info.<br/>
     *
     * @name ShippingStripePaymentIntentCreate
     * @summary Create a new Stripe payment intent for shipping (for "custom payment flow").
     * @request POST:/user/{user_id}/shipping_stripe_payment_intent
     * @secure
     * @response `200` `{ clientSecret: string, current_timestamp: number, expiration_timestamp: number }` Information the UI needs to know about the Stripe payment intent.
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    shippingStripePaymentIntentCreate: (userId: string, data: { shipment_id: number }, params: RequestParams = {}) =>
      this.request<{ clientSecret: string; current_timestamp: number; expiration_timestamp: number }, GenericError>({
        path: `/user/${userId}/shipping_stripe_payment_intent`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * @description This will error out on any shipments that need payment, use different endpoints for those.<br/>
     *
     * @name ShippingSubmitFreeCreate
     * @summary Submit a free shipment, i.e. where the quote ended up with a total cost of 0.
     * @request POST:/user/{user_id}/shipping_submit_free
     * @secure
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    shippingSubmitFreeCreate: (userId: string, data: { shipment_id: number }, params: RequestParams = {}) =>
      this.request<object, GenericError>({
        path: `/user/${userId}/shipping_submit_free`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * @description See https://stripe.com/docs/checkout/quickstart and other Stripe docs for more info.<br/>
     *
     * @name StripeCreatePaymentIntentCreate
     * @summary Create a new Stripe payment intent (for "custom payment flow").
     * @request POST:/user/{user_id}/stripe_create_payment_intent
     * @secure
     * @response `200` `{ clientSecret: string, current_timestamp: number, expiration_timestamp: number }` Information the UI needs to know about the Stripe payment intent.
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    stripeCreatePaymentIntentCreate: (
      userId: string,
      data: { quantity: number; sale_id: number; tax_region_id: string; total_bid_eurcent?: number },
      params: RequestParams = {},
    ) =>
      this.request<{ clientSecret: string; current_timestamp: number; expiration_timestamp: number }, GenericError>({
        path: `/user/${userId}/stripe_create_payment_intent`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * @description Also performs a signed transfer to the escrow account if a signature is given.<br/>
     *
     * @name TransferToEmailCreate
     * @summary Transfer a token to an email address (or start that transfer)
     * @request POST:/user/{user_id}/transfer_to_email
     * @secure
     * @response `200` `{ clientSecret: string, current_timestamp: number, expiration_timestamp: number }` Information the UI needs to know about the Stripe payment intent.
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    transferToEmailCreate: (
      userId: string,
      data: {
        email_address: string;
        greeting_message?: string;
        locale?: "en-US" | "de";
        payment?: {
          country_code: string;
          shipping_size: number;
          storage_until_timestamp: number | "optimal" | "maximal";
          token_ids: number[];
        };
        tokens: { signature: string; token_address?: string; token_id: string }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<{ clientSecret: string; current_timestamp: number; expiration_timestamp: number }, GenericError>({
        path: `/user/${userId}/transfer_to_email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Also performs signed transfers to the escrow account using given sigatures.<br/>
     *
     * @name TransferToListCreate
     * @summary Initiate transfer of tokens to a list of people (for business use)
     * @request POST:/user/{user_id}/transfer_to_list
     * @secure
     * @response `200` `object` Empty object response on success
     * @response `400` `GenericError`
     * @response `403` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    transferToListCreate: (
      userId: string,
      data: {
        additional_message?: string;
        carbon_copy_email?: string;
        email_subject?: string;
        header_message?: string;
        lead_message?: string;
        logo_image?: string;
        logo_image_format?: "png" | "jpg";
        output_mode?: "email" | "csv";
        recipient_list: {
          email_address?: string;
          full_name?: string;
          locale?: "en-US" | "de";
          salutation?: string;
          tokens: { signature: string; token_address?: string; token_id: string }[];
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<object, GenericError>({
        path: `/user/${userId}/transfer_to_list`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  version = {
    /**
     * @description This is mainly used for debugging.<br/>
     *
     * @name VersionList
     * @summary Get version information for the API.
     * @request GET:/version
     * @response `200` `{ api?: string, cnp?: string, flasgger?: string, flask?: string, gevent?: string, python?: string, requests?: string, sqlalchemy?: string, "web3py"?: string, yaml?: string }` A list of status data about the Signer
     * @response `500` `GenericError`
     */
    versionList: (params: RequestParams = {}) =>
      this.request<
        {
          api?: string;
          cnp?: string;
          flasgger?: string;
          flask?: string;
          gevent?: string;
          python?: string;
          requests?: string;
          sqlalchemy?: string;
          web3py?: string;
          yaml?: string;
        },
        GenericError
      >({
        path: `/version`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  wallet = {
    /**
     * No description
     *
     * @name WalletDetail
     * @summary List of NFTs
     * @request GET:/wallet/{chain}/{address}
     * @response `200` `{ address: string, assets: ({ alcohol_pct: number, back_image_path: string, bottle_size?: "standard" | "magnum", chain_id: number, closure?: string, country_code: string, cryptowine_points?: number, deliverable?: boolean, description_de?: string, description_en?: string, drinking_timestamp_maximum?: number, drinking_timestamp_optimal?: number, front_image_path: string, grape_variety: string, layer_network_symbol?: string, name: string, origin?: string, preview_image_path: string, producer_name?: string, sparkling: boolean, token_address: string, token_id: number, token_image_path: string, token_symbol?: string, tokenized_amount?: number, vintage_year: number, wine_color: "white" | "red", wine_id?: number, wine_type: string })[], chain_id?: number, ensname?: string, messages?: (string)[], network_id: string, network_name: string }` Information about an Ethereum wallet
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    walletDetail: (
      chain: "default" | "eth" | "ethereum" | "ropsten" | "goerli" | "xdai" | "1" | "3" | "5" | "100",
      address: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          address: string;
          assets: {
            alcohol_pct: number;
            back_image_path: string;
            bottle_size?: "standard" | "magnum";
            chain_id: number;
            closure?: string;
            country_code: string;
            cryptowine_points?: number;
            deliverable?: boolean;
            description_de?: string;
            description_en?: string;
            drinking_timestamp_maximum?: number;
            drinking_timestamp_optimal?: number;
            front_image_path: string;
            grape_variety: string;
            layer_network_symbol?: string;
            name: string;
            origin?: string;
            preview_image_path: string;
            producer_name?: string;
            sparkling: boolean;
            token_address: string;
            token_id: number;
            token_image_path: string;
            token_symbol?: string;
            tokenized_amount?: number;
            vintage_year: number;
            wine_color: "white" | "red";
            wine_id?: number;
            wine_type: string;
          }[];
          chain_id?: number;
          ensname?: string;
          messages?: string[];
          network_id: string;
          network_name: string;
        },
        GenericError
      >({
        path: `/wallet/${chain}/${address}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  wineDetails = {
    /**
     * No description
     *
     * @name WineDetailsDetail
     * @summary Get details on a specific wine.
     * @request GET:/wine_details/{wine_id}
     * @response `200` `{ alcohol_pct: number, awards: (string)[], back_image_path: string, bottle_size: "standard" | "magnum", brand?: string, closure: string, country_code?: string, cryptowine_points: number, description_de: string, description_en: string, drinking_timestamp_maximum?: number, drinking_timestamp_optimal?: number, empty_preview_image_path: string, empty_token_image_path: string, front_image_path: string, grape_variety: string, mail_image_path: string, name: string, origin: string, preview_image_path: string, producer_name: string, quality_category?: string, sparkling: boolean, storage_id: string, token_count: number, token_image_path: string, vineyard?: string, vintage_year: number, volume_ml: number, wine_category?: string, wine_color: "white" | "red", wine_id: number, wine_type: string }` Details of the specific wine
     * @response `400` `GenericError`
     * @response `404` `GenericError`
     * @response `500` `GenericError`
     */
    wineDetailsDetail: (wineId: string, params: RequestParams = {}) =>
      this.request<
        {
          alcohol_pct: number;
          awards: string[];
          back_image_path: string;
          bottle_size: "standard" | "magnum";
          brand?: string;
          closure: string;
          country_code?: string;
          cryptowine_points: number;
          description_de: string;
          description_en: string;
          drinking_timestamp_maximum?: number;
          drinking_timestamp_optimal?: number;
          empty_preview_image_path: string;
          empty_token_image_path: string;
          front_image_path: string;
          grape_variety: string;
          mail_image_path: string;
          name: string;
          origin: string;
          preview_image_path: string;
          producer_name: string;
          quality_category?: string;
          sparkling: boolean;
          storage_id: string;
          token_count: number;
          token_image_path: string;
          vineyard?: string;
          vintage_year: number;
          volume_ml: number;
          wine_category?: string;
          wine_color: "white" | "red";
          wine_id: number;
          wine_type: string;
        },
        GenericError
      >({
        path: `/wine_details/${wineId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  winesList = {
    /**
     * No description
     *
     * @name WinesListList
     * @summary List all wines known to the system.
     * @request GET:/wines_list
     * @response `200` `({ awards?: (string)[], back_image_path: string, cryptowine_points?: number, front_image_path: string, grape_variety: string, name: string, preview_image_path: string, producer_name: string, vintage_year: number, wine_color: "white" | "red", wine_id: number, wine_type: string })[]` List of wines
     * @response `400` `GenericError`
     * @response `500` `GenericError`
     */
    winesListList: (params: RequestParams = {}) =>
      this.request<
        {
          awards?: string[];
          back_image_path: string;
          cryptowine_points?: number;
          front_image_path: string;
          grape_variety: string;
          name: string;
          preview_image_path: string;
          producer_name: string;
          vintage_year: number;
          wine_color: "white" | "red";
          wine_id: number;
          wine_type: string;
        }[],
        GenericError
      >({
        path: `/wines_list`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
