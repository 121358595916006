import {
    AuthFacadeContext,
    InBrowserWalletConnectorContext,
    InBrowserWalletContextParams,
    SessionDataContext,
    SessionDataContextParams,
    Web3AuthFacadeContext
} from "cnp-frontend-core";
import {logout} from "services/api/authApiService";
import {Web3GlobalStorageContext} from "contexts_and_wrappers/Web3GlobalStorageContext";
import {useContext} from "react";
import {useHistory} from "react-router-dom";
import {CartContext} from "contexts_and_wrappers/CartContext";
import {useSessionDataCookies} from "./useSessionDataCookies";

type UseLogoutCompletelyParams = {
	logoutCompletely: () => Promise<void>;
	logoutAndRedirect: () => Promise<void>;
};

export const useLogoutCompletely = (): UseLogoutCompletelyParams => {
    const history = useHistory();
    const {sessionData, clearSessionData}: SessionDataContextParams =
		useContext(SessionDataContext);

    const {
        setServerSaltResponse,
        updateIsAuthSignerRequested,
        setError,
        setGoogleError
    } = useContext(Web3GlobalStorageContext);

    const {
        deactivateWeb3Async,
        isAuthorizedWithWeb3,
        resetWeb3Provider,
        isAuthorizedWithApi
    }: AuthFacadeContext = useContext(Web3AuthFacadeContext);

    const {clearInBrowserEntropy}: InBrowserWalletContextParams =
		useContext(InBrowserWalletConnectorContext);

    const {clearCartStorage} = useContext(CartContext);

    const {clearSessionCookies} = useSessionDataCookies();

    const deactivateAndClearSession = async() => {
        await deactivateWeb3Async();
        clearSessionData();
        clearCartStorage();
        resetWeb3Provider();
        updateIsAuthSignerRequested(false);
        clearInBrowserEntropy();
        setError("");
        setGoogleError("");
        setServerSaltResponse(null);

        const ageConfirmation = localStorage.getItem("ageConfirmation");
        localStorage.clear();
        localStorage.setItem("ageConfirmation", String(ageConfirmation || false));

        sessionStorage.clear();
        clearSessionCookies();
    };

    const logoutCompletely = async() => {
        try {
            if (isAuthorizedWithApi) {
                await logout(sessionData);
            }
            await deactivateAndClearSession();
        } catch (err: any) {
            console.error(err);
            setError(err);
            await deactivateAndClearSession();
        }
    };

    const logoutAndRedirect = async() => {
        if (
            isAuthorizedWithWeb3 &&
			sessionData &&
			sessionData.bearer_token
        ) {
            logoutCompletely();
            history.push("/");
            window.location.reload();
        } else {
            await deactivateAndClearSession();
            history.push("/");
        }
    };

    return {
        logoutCompletely,
        logoutAndRedirect
    };
};
