import React from "react";

import styles from "./FormHorizontalSpace.module.scss";
import classNames from "classnames";

export const FormHorizontalSpace = ({variant}: {
	variant?: "medium";
}): JSX.Element => {
    return (
        <div
            className={classNames(
                styles.FormHorizontalSpace,
                variant && styles[variant]
            )}
        />
    );
};
