import React, {ReactNode, useMemo} from "react";

import styles from "./InfoBox.module.scss";
import classNames from "classnames";
import {Loader} from "../../presentational/Loader/Loader";

type TInfoBox = {
    variant: "info" | "warning" | "success" | "danger";
    title: string;
    isLoading?: boolean;
    description?: ReactNode;
    profilePage?: boolean;
    wineCellar?: boolean;
};

export const InfoBox = (params: TInfoBox): JSX.Element => {
    const {
        variant,
        title,
        isLoading,
        description,
        profilePage,
        wineCellar
    } = params;

    const icon = useMemo(() => {
        switch (variant) {
            case "info":
                return "/images/grapeInfo.svg";
            case "warning":
                return "/images/grapeWarning.svg";
            case "success":
                return "/images/grapeSuccess.svg";
            case "danger":
                return "/images/grapeDanger.svg";
            default:
                return null;
        }
    }, [variant]);

    return (
        <div
            className={classNames(
                styles.InfoBox,
                variant && styles[variant],
                profilePage && styles.profilePage,
                wineCellar && styles.wineCellar
            )}
        >
            <div className={styles.icon}>
                {icon && !isLoading && <img src={icon} alt="" />}
                {isLoading && <Loader type="info-box" />}
            </div>
            <h6 className={styles.title}>{title}</h6>
            {description && <p className="body_secondary">{description}</p>}
        </div>
    );
};
