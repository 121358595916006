import React, {PropsWithChildren} from "react";
import classNames from "classnames";

import styles from "./Label.module.scss";

export type TLabel = PropsWithChildren<{
    required?: boolean;
    color?: "red" | "white"
}>

export const Label = (params: TLabel): JSX.Element => {
    const {required, children, color = "red"} = params;
    return (
        <label
            htmlFor="fieldName"
            className={classNames("overline", styles.Label, color && styles[color])}
        >
            {children}
            {
                required &&
                <span className={styles.required}>*</span>
            }
        </label>
    );
};
