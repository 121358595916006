import styles from "./Loader.module.scss";
import classNames from "classnames";
import React from "react";

type LoaderType = "info-box" | "confirmation-popup" | "version";

type LoaderParams = {
	type?: LoaderType;
};

export const Loader = ({type}: LoaderParams): JSX.Element => {
    return (
        <div className={classNames(styles.Loader, type && styles[type])}>
            <svg
                width="19"
                height="26"
                viewBox="0 0 19 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/*compositions*/}

                {/*Stem*/}
                <path
                    className={styles.loadAnimation1}
                    d="M9.50131 7.6888H9.67877C9.75911 7.6888 9.82461 7.62362 9.82461 7.54295V2.77475C9.82461 2.69441 9.75943 2.62891 9.67877 2.62891H9.50131C9.42097 2.62891 9.35547 2.69408 9.35547 2.77475V7.54328C9.35579 7.62362 9.42097 7.6888 9.50131 7.6888Z"
                    fill="#E9E4E3"
                />

                {/*Hexagon 1 */}
                <path
                    className={styles.loadAnimation2}
                    d="M6.00862 9.82525C5.93796 9.70264 5.83632 9.5981 5.71016 9.52454L3.58515 8.29005C3.45996 8.21746 3.32057 8.18067 3.18119 8.17971C3.0376 8.17874 2.89402 8.2152 2.76528 8.29005L0.640271 9.52454C0.51508 9.59713 0.414412 9.70071 0.34375 9.82203L3.17893 11.4689L3.18087 11.4676L6.00862 9.82525Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation2}
                    d="M2.77106 14.6375L0.64605 13.403C0.392442 13.2556 0.236276 12.9836 0.236276 12.6887V10.22C0.236276 10.1307 0.250795 10.0432 0.277898 9.96094C0.247246 10.0477 0.230469 10.1403 0.230469 10.2355V12.7042C0.230469 12.851 0.269188 12.992 0.339527 13.1149C0.410188 13.2388 0.513114 13.3443 0.640563 13.4185L2.76557 14.653C2.89431 14.7275 3.0379 14.7643 3.18148 14.7633V14.7479C3.03951 14.7482 2.89819 14.7114 2.77106 14.6375Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation2}
                    d="M0.238281 12.6903C0.238281 12.9849 0.394447 13.2572 0.648055 13.4047L2.77307 14.6392C2.89987 14.7127 3.04151 14.7495 3.18316 14.7495V11.4684L3.18122 11.4671L0.346047 9.82031C0.342175 9.82709 0.337658 9.83386 0.334109 9.84064C0.312814 9.87968 0.295068 9.92034 0.280226 9.9626C0.253123 10.0449 0.238604 10.1323 0.238604 10.2217V12.6903H0.238281Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation2}
                    d="M5.71257 13.4198C5.84099 13.3452 5.94456 13.2384 6.01522 13.1129C6.03555 13.0768 6.05297 13.039 6.06782 13C6.00038 13.1675 5.87874 13.3107 5.71838 13.404L3.59337 14.6384C3.46656 14.712 3.32524 14.7488 3.18359 14.7488V14.7643C3.3233 14.7633 3.46269 14.7265 3.58756 14.6539L5.71257 13.4198Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation2}
                    d="M6.12234 10.2379C6.12234 10.0969 6.08621 9.96102 6.02103 9.84132C6.0178 9.83551 6.01425 9.83003 6.01103 9.82422L3.18359 11.4665V11.4691V14.7502C3.32524 14.7502 3.46656 14.7134 3.59337 14.6399L5.71838 13.4054C5.87906 13.3121 6.00038 13.1685 6.06782 13.0014C6.10298 12.9088 6.12234 12.8091 6.12234 12.7068V10.2379Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation2}
                    d="M6.64453 10.2199V12.6886C6.64453 12.7995 6.66679 12.9073 6.70777 13.0064C6.67067 12.9115 6.65034 12.8092 6.65034 12.704V10.2354C6.65034 10.0944 6.68648 9.95856 6.75165 9.83885C6.75553 9.83176 6.75972 9.8253 6.76359 9.81853C6.83425 9.69721 6.93492 9.59396 7.06011 9.52104L9.18512 8.28656C9.31386 8.21202 9.45745 8.17524 9.60103 8.17621C9.74074 8.17718 9.88012 8.21396 10.005 8.28656L12.13 9.52104C12.2562 9.59428 12.3578 9.69914 12.4285 9.82175C12.4317 9.82756 12.4352 9.83305 12.4385 9.83885C12.4546 9.86822 12.4688 9.89887 12.4811 9.92984C12.4152 9.75367 12.291 9.60235 12.1239 9.50523L9.99886 8.27075C9.74525 8.12329 9.4326 8.12329 9.17899 8.27075L7.05398 9.50523C6.8007 9.65268 6.64453 9.925 6.64453 10.2199Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation2}
                    d="M0.343165 9.8182C0.413826 9.69689 0.514495 9.59364 0.639686 9.52072L2.7647 8.28623C2.89344 8.2117 3.03702 8.17492 3.1806 8.17589C3.32031 8.17685 3.4597 8.21364 3.58457 8.28623L5.70958 9.52072C5.83574 9.59396 5.93737 9.69882 6.00803 9.82143C6.01126 9.82724 6.01481 9.83272 6.01804 9.83853C6.08321 9.95824 6.11935 10.0938 6.11935 10.2351V12.7037C6.11935 12.806 6.09999 12.9057 6.06482 12.9983C6.10386 12.9012 6.12516 12.7963 6.12516 12.6882V10.2196C6.12516 9.925 5.96899 9.65268 5.71539 9.50523L3.59037 8.27075C3.33677 8.12329 3.02411 8.12329 2.77051 8.27075L0.645495 9.50523C0.46868 9.60783 0.339616 9.7711 0.277344 9.9605C0.292186 9.91855 0.309931 9.87757 0.331227 9.83853C0.335099 9.83176 0.339293 9.82498 0.343165 9.8182Z"
                    fill="#E9E4E3"
                />

                {/*Hexagon 2 */}
                <path
                    className={styles.loadAnimation3}
                    d="M9.60306 11.4676L12.4305 9.82525C12.3598 9.70264 12.2582 9.5981 12.132 9.52454L10.007 8.29005C9.88183 8.21746 9.74245 8.18067 9.60306 8.17971C9.45948 8.17874 9.3159 8.2152 9.18716 8.29005L7.06215 9.52454C6.93696 9.59713 6.83629 9.70071 6.76562 9.82203L9.6008 11.4689L9.60306 11.4676Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation3}
                    d="M9.18248 14.6385L7.05747 13.404C6.89937 13.3121 6.77902 13.1717 6.71094 13.0078C6.72546 13.0452 6.74256 13.0814 6.76256 13.1162C6.83323 13.2401 6.93615 13.3456 7.0636 13.4198L9.18861 14.6543C9.31735 14.7289 9.46094 14.7656 9.60452 14.7647V14.7489C9.45868 14.7508 9.31284 14.7143 9.18248 14.6385Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation3}
                    d="M7.05599 13.4047L9.181 14.6392C9.31135 14.715 9.45719 14.7515 9.60271 14.7492V11.4684L9.60077 11.4671L6.7656 9.82031C6.76172 9.82709 6.75721 9.83386 6.75366 9.84064C6.68848 9.96035 6.65234 10.0959 6.65234 10.2372V12.7058C6.65234 12.811 6.67267 12.9133 6.70978 13.0082C6.77753 13.1724 6.89789 13.3127 7.05599 13.4047Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation3}
                    d="M12.5384 12.6913C12.5384 12.9858 12.3822 13.2582 12.1286 13.4056L10.0036 14.6401C9.88037 14.7117 9.74324 14.7482 9.60547 14.7501V14.7659C9.74518 14.765 9.88456 14.7282 10.0094 14.6556L12.1344 13.4211C12.2629 13.3466 12.3664 13.2398 12.4371 13.1143C12.5061 12.992 12.5442 12.8523 12.5442 12.7071V10.2384C12.5442 10.1423 12.5271 10.0487 12.4958 9.96094C12.5236 10.0442 12.5384 10.1323 12.5384 10.2229V12.6913Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation3}
                    d="M12.4844 9.92969C12.485 9.932 12.486 9.93397 12.4867 9.93628C12.486 9.93397 12.4854 9.93167 12.4844 9.92969Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation3}
                    d="M12.4329 9.82422L9.60547 11.4665V11.4691V14.7499C9.74292 14.7479 9.88037 14.7115 10.0036 14.6399L12.1286 13.4054C12.3822 13.2579 12.5384 12.9859 12.5384 12.691V10.2224C12.5384 10.132 12.5236 10.0436 12.4958 9.96038C12.4932 9.95328 12.491 9.94618 12.4881 9.93908C12.4874 9.93683 12.4865 9.93489 12.4858 9.93263C12.4732 9.90133 12.459 9.871 12.4432 9.84164C12.4397 9.83551 12.4361 9.83003 12.4329 9.82422Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation3}
                    d="M12.4844 9.9375C12.4873 9.9446 12.4895 9.9517 12.4921 9.9588C12.4895 9.9517 12.487 9.94492 12.4844 9.9375Z"
                    fill="#E9E4E3"
                />

                {/*Hexagon 3 */}
                <path
                    className={styles.loadAnimation4}
                    d="M13.2553 9.68359C13.2179 9.72554 13.1846 9.77103 13.1562 9.81943L13.1601 9.82169C13.1869 9.77265 13.2188 9.72618 13.2553 9.68359Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation4}
                    d="M15.9918 11.4689L15.9937 11.4676L18.8212 9.82525C18.7505 9.70264 18.6489 9.5981 18.5227 9.52454L16.3977 8.29005C16.2725 8.21746 16.1331 8.18067 15.9937 8.17971C15.8501 8.17874 15.7066 8.2152 15.5778 8.29005L13.4528 9.52454C13.3776 9.56809 13.3115 9.62295 13.2553 9.68586C13.2189 9.72845 13.1869 9.77492 13.1602 9.82396L15.9918 11.4689Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation4}
                    d="M15.5923 14.6392L13.4673 13.4047C13.2137 13.2572 13.0575 12.9852 13.0575 12.6903V10.2217C13.0575 10.0797 13.0943 9.94292 13.1601 9.82257L13.1562 9.82031C13.1523 9.82709 13.1478 9.83386 13.1443 9.84064C13.0791 9.96035 13.043 10.0959 13.043 10.2372V12.7058C13.043 12.8526 13.0817 12.9936 13.152 13.1166C13.2227 13.2405 13.3256 13.346 13.4531 13.4202L15.5781 14.6547C15.7068 14.7292 15.8504 14.766 15.994 14.765V14.7492C15.8549 14.7479 15.7165 14.7115 15.5923 14.6392Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation4}
                    d="M13.1612 9.82422C13.0951 9.94457 13.0586 10.0811 13.0586 10.2233V12.692C13.0586 12.9866 13.2148 13.2589 13.4684 13.4063L15.5934 14.6408C15.7176 14.7131 15.856 14.7496 15.9948 14.7512V11.4701L15.9928 11.4688L13.1612 9.82422Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation4}
                    d="M16.4107 14.6392C16.2813 14.7144 16.1367 14.7509 15.9922 14.7496V14.7654C16.1319 14.7644 16.2713 14.7276 16.3962 14.6551L18.5212 13.4206C18.5905 13.3802 18.6525 13.3302 18.706 13.2734C18.656 13.3241 18.5989 13.3683 18.5357 13.4051L16.4107 14.6392Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation4}
                    d="M18.9309 12.7065V10.2379C18.9309 10.0969 18.8948 9.96102 18.8296 9.84132C18.8264 9.83551 18.8228 9.83003 18.8196 9.82422L15.9922 11.4665V11.4691V14.7499C16.1367 14.7515 16.2813 14.7147 16.4107 14.6395L18.5357 13.4051C18.5989 13.3683 18.656 13.3241 18.706 13.2734C18.7515 13.225 18.7912 13.1715 18.8238 13.1137C18.8929 12.9917 18.9309 12.852 18.9309 12.7065Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation4}
                    d="M18.5358 9.50523L16.4108 8.27075C16.1572 8.12329 15.8445 8.12329 15.5909 8.27075L13.4659 9.50523C13.3846 9.55234 13.3133 9.61267 13.2539 9.68237C13.31 9.61945 13.3762 9.5646 13.4514 9.52104L15.5764 8.28656C15.7051 8.21202 15.8487 8.17524 15.9923 8.17621C16.132 8.17718 16.2714 8.21396 16.3963 8.28656L18.5213 9.52104C18.6474 9.59428 18.7491 9.69914 18.8197 9.82175C18.8229 9.82756 18.8265 9.83305 18.8297 9.83885C18.8949 9.95856 18.931 10.0941 18.931 10.2354V12.704C18.931 12.8492 18.893 12.9893 18.8239 13.1112C18.7913 13.169 18.7516 13.2222 18.7061 13.2709C18.8575 13.1183 18.9456 12.9096 18.9456 12.6882V10.2196C18.9456 9.92468 18.7894 9.65236 18.5358 9.50523Z"
                    fill="#E9E4E3"
                />

                {/*Hexagon 4 */}
                <path
                    className={styles.loadAnimation5}
                    d="M9.26643 15.4073C9.19577 15.2847 9.09413 15.1801 8.96798 15.1066L6.84296 13.8721C6.71777 13.7995 6.57839 13.7627 6.439 13.7617C6.29542 13.7608 6.15183 13.7972 6.02309 13.8721L3.89808 15.1066C3.77289 15.1792 3.67222 15.2827 3.60156 15.4041L6.43674 17.0509L6.43868 17.0496L9.26643 15.4073Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation5}
                    d="M6.01661 20.2257L3.8916 18.9912C3.76834 18.9196 3.668 18.8183 3.59766 18.6992C3.59862 18.7008 3.59927 18.7021 3.59992 18.7037C3.67058 18.8276 3.7735 18.9331 3.90095 19.0074L6.02596 20.2418C6.1547 20.3164 6.29829 20.3532 6.44187 20.3522V20.3361C6.29506 20.3383 6.14793 20.3019 6.01661 20.2257Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation5}
                    d="M3.8926 18.9903L6.01762 20.2248C6.14894 20.3009 6.29607 20.3374 6.44255 20.3348V17.0544L6.44062 17.0531L3.60544 15.4062C3.60157 15.413 3.59705 15.4198 3.5935 15.4266C3.52833 15.5463 3.49219 15.6818 3.49219 15.8231V18.2918C3.49219 18.4366 3.53026 18.576 3.59866 18.698C3.66933 18.817 3.76935 18.9187 3.8926 18.9903Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation5}
                    d="M8.97006 19.0036C9.09848 18.929 9.20205 18.8222 9.27271 18.6967C9.34176 18.5744 9.37983 18.4347 9.37983 18.2895V15.8209C9.37983 15.6799 9.3437 15.5441 9.27852 15.4244C9.27594 15.4195 9.27303 15.4147 9.27013 15.4102C9.33466 15.5292 9.3708 15.6644 9.3708 15.8051V18.2737C9.3708 18.5683 9.21463 18.8406 8.96102 18.9881L6.83601 20.2226C6.71373 20.2936 6.57789 20.33 6.44141 20.3326V20.3487C6.58112 20.3478 6.7205 20.311 6.84537 20.2384L8.97006 19.0036Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation5}
                    d="M6.44141 17.0489V17.0515V20.3316C6.57789 20.329 6.71373 20.2926 6.83601 20.2216L8.96103 18.9871C9.21463 18.8396 9.3708 18.5676 9.3708 18.2727V15.8041C9.3708 15.6637 9.33498 15.5285 9.27013 15.4092C9.26948 15.4082 9.26916 15.4072 9.26852 15.4062L6.44141 17.0489Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation5}
                    d="M3.48983 15.8213C3.48983 15.6803 3.52596 15.5445 3.59114 15.4248C3.59501 15.418 3.59921 15.4112 3.60308 15.4045C3.67374 15.2831 3.77441 15.1799 3.8996 15.107L6.02461 13.8725C6.15335 13.798 6.29693 13.7612 6.44051 13.7621C6.58022 13.7631 6.71961 13.7999 6.84448 13.8725L8.96949 15.107C9.09565 15.1802 9.19729 15.2851 9.26795 15.4077C9.26859 15.4087 9.26891 15.4096 9.26956 15.4106C9.19858 15.2799 9.09307 15.1683 8.96013 15.0912L6.83512 13.8567C6.58151 13.7092 6.26886 13.7092 6.01525 13.8567L3.89024 15.0912C3.63663 15.2386 3.48047 15.5106 3.48047 15.8055V18.2742C3.48047 18.4255 3.52177 18.5707 3.5963 18.6962C3.52758 18.5742 3.48983 18.4348 3.48983 18.29V15.8213Z"
                    fill="#E9E4E3"
                />

                {/*Hexagon 5 */}
                <path
                    className={styles.loadAnimation6}
                    d="M15.5946 15.4073C15.5239 15.2847 15.4223 15.1801 15.2961 15.1066L13.1711 13.8721C13.0459 13.7995 12.9065 13.7627 12.7671 13.7617C12.6235 13.7608 12.48 13.7972 12.3512 13.8721L10.2262 15.1066C10.101 15.1792 10.0003 15.2827 9.92969 15.4041L12.7649 17.0509L12.7668 17.0496L15.5946 15.4073Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation6}
                    d="M12.3496 20.2247L10.2246 18.9902C10.0065 18.8637 9.861 18.6446 9.82422 18.3984C9.83809 18.5065 9.87326 18.6098 9.92618 18.7027C9.99684 18.8266 10.0998 18.9321 10.2272 19.0063L12.3522 20.2408C12.481 20.3153 12.6246 20.3521 12.7681 20.3512V20.335C12.6236 20.3363 12.479 20.2995 12.3496 20.2247Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation6}
                    d="M10.2236 18.9903L12.3486 20.2248C12.478 20.3 12.6225 20.3364 12.7668 20.3351V17.0544L12.7648 17.0531L9.92966 15.4062C9.92579 15.413 9.92127 15.4198 9.91772 15.4266C9.85254 15.5463 9.81641 15.6818 9.81641 15.8231V18.2918C9.81641 18.3276 9.81867 18.3634 9.82318 18.3982C9.85997 18.6447 10.0055 18.8635 10.2236 18.9903Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation6}
                    d="M15.7018 15.8077V18.2763C15.7018 18.5709 15.5456 18.8432 15.292 18.9907L13.167 20.2251C13.0428 20.2974 12.9044 20.3339 12.7656 20.3355V20.3516C12.9053 20.3507 13.0447 20.3139 13.1696 20.2413L15.2946 19.0068C15.423 18.9323 15.5266 18.8255 15.5973 18.7C15.6663 18.5777 15.7044 18.438 15.7044 18.2928V15.8241C15.7044 15.7831 15.7011 15.7428 15.695 15.7031C15.6995 15.7373 15.7018 15.7722 15.7018 15.8077Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation6}
                    d="M15.5931 15.4062L12.7656 17.0486V17.0511V20.3316C12.9044 20.3303 13.0428 20.2935 13.167 20.2212L15.292 18.9868C15.5456 18.8393 15.7018 18.5673 15.7018 18.2724V15.8038C15.7018 15.7683 15.6995 15.7334 15.695 15.6989C15.6805 15.6015 15.6492 15.5082 15.6031 15.4234C15.5998 15.4175 15.5963 15.4121 15.5931 15.4062Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation6}
                    d="M9.81508 15.8217C9.81508 15.6807 9.85122 15.5448 9.91639 15.4251C9.92027 15.4183 9.92446 15.4116 9.92833 15.4048C9.99899 15.2835 10.0997 15.1802 10.2249 15.1073L12.3499 13.8728C12.4786 13.7983 12.6222 13.7615 12.7658 13.7625C12.9055 13.7634 13.0449 13.8002 13.1697 13.8728L15.2947 15.1073C15.4209 15.1805 15.5225 15.2854 15.5932 15.408C15.5964 15.4138 15.6 15.4193 15.6032 15.4251C15.6497 15.51 15.681 15.6032 15.6952 15.7007C15.6629 15.4474 15.5151 15.2209 15.2922 15.0912L13.1672 13.8567C12.9135 13.7092 12.6009 13.7092 12.3473 13.8567L10.2223 15.0912C9.96867 15.2386 9.8125 15.5106 9.8125 15.8055V18.2742C9.8125 18.3155 9.81605 18.3564 9.82186 18.3968C9.81734 18.3616 9.81508 18.3261 9.81508 18.2903V15.8217Z"
                    fill="#E9E4E3"
                />

                {/*Hexagon 6 */}
                <path
                    className={styles.loadAnimation7}
                    d="M7.05787 20.7025L9.18288 19.468C9.43649 19.3206 9.74914 19.3206 10.0027 19.468L12.1278 20.7025C12.2523 20.7748 12.3533 20.8774 12.424 20.9981L12.432 20.9932C12.3614 20.8706 12.2597 20.7661 12.1336 20.6925L10.0086 19.458C9.88337 19.3854 9.74398 19.3486 9.60459 19.3477C9.46101 19.3467 9.31743 19.3832 9.18869 19.458L7.06368 20.6925C6.95301 20.7567 6.86169 20.8451 6.79297 20.9487C6.86105 20.8496 6.95075 20.7648 7.05787 20.7025Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation7}
                    d="M9.6008 22.6349L9.60274 22.6336L12.4221 20.9961C12.3514 20.8754 12.2505 20.7728 12.1259 20.7005L10.0009 19.4661C9.74729 19.3186 9.43463 19.3186 9.18103 19.4661L7.05602 20.7005C6.94889 20.7628 6.85919 20.8477 6.79144 20.9467C6.7824 20.9603 6.77369 20.9742 6.76562 20.988L9.6008 22.6349Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation7}
                    d="M6.7656 20.9883C6.76172 20.9951 6.75721 21.0018 6.75366 21.0086C6.68848 21.1283 6.65234 21.2642 6.65234 21.4052V23.8738C6.65234 24.0206 6.69106 24.1616 6.7614 24.2845C6.83206 24.4084 6.93499 24.5139 7.06244 24.5882L9.18745 25.8226C9.31619 25.8972 9.45977 25.9339 9.60335 25.933V22.6364L9.60142 22.6351L6.7656 20.9883Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation7}
                    d="M12.4299 20.9922L12.4219 20.997C12.4951 21.1219 12.5355 21.2658 12.5355 21.4158V23.8845C12.5355 24.0297 12.4974 24.1694 12.4286 24.2913C12.4306 24.2881 12.4325 24.2852 12.4345 24.282C12.5035 24.1597 12.5416 24.02 12.5416 23.8745V21.4058C12.5416 21.2648 12.5054 21.129 12.4403 21.0093C12.4367 21.0035 12.4332 20.998 12.4299 20.9922Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation7}
                    d="M12.5384 21.4188C12.5384 21.2688 12.4978 21.1249 12.4248 21L9.60547 22.6375V22.6401V25.9366C9.74518 25.9357 9.88456 25.8989 10.0094 25.8263L12.1344 24.5918C12.2596 24.5192 12.3609 24.4156 12.4316 24.2943C12.5003 24.1724 12.5384 24.0326 12.5384 23.8874V21.4188Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation7}
                    d="M12.1307 24.5904L10.0056 25.8249C9.88045 25.8975 9.74106 25.9343 9.60167 25.9352C9.45809 25.9362 9.31451 25.8997 9.18577 25.8249L7.06076 24.5904C6.93331 24.5165 6.8307 24.411 6.75972 24.2868C6.68938 24.1638 6.65066 24.0228 6.65066 23.876V21.4074C6.65066 21.2664 6.6868 21.1306 6.75198 21.0108C6.75585 21.0041 6.76004 20.9973 6.76391 20.9905C6.77198 20.9763 6.78069 20.9628 6.78973 20.9492C6.6968 21.0847 6.64453 21.247 6.64453 21.4174V23.886C6.64453 24.1806 6.8007 24.4529 7.0543 24.6004L9.17932 25.8349C9.43292 25.9823 9.74558 25.9823 9.99918 25.8349L12.1242 24.6004C12.2529 24.5255 12.3562 24.4187 12.4272 24.2929C12.3568 24.4142 12.2558 24.5175 12.1307 24.5904Z"
                    fill="#E9E4E3"
                />

                {/* Left list*/}
                <path
                    className={styles.loadAnimation8}
                    d="M8.58789 6.64933V5.03927C8.58789 3.83738 7.6196 2.86328 6.42513 2.86328C5.8408 2.86328 5.311 3.09688 4.92188 3.47568L8.44754 7.02264C8.53465 6.92326 8.58789 6.79258 8.58789 6.64933Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation8}
                    d="M6.42448 7.21614H8.02485C8.19328 7.21614 8.34364 7.14129 8.44689 7.02352L4.92123 3.47656C4.51468 3.87214 4.26172 4.42646 4.26172 5.04015C4.26172 6.24205 5.23001 7.21614 6.42448 7.21614Z"
                    fill="#E9E4E3"
                />

                {/* Right List*/}
                <path
                    className={styles.loadAnimation9}
                    d="M12.7559 4.4118H11.1555C11.0145 4.4118 10.8861 4.35921 10.7873 4.27338L10.7773 4.28338C10.8764 4.37115 11.0061 4.42471 11.1484 4.42471H12.7488C13.5735 4.42471 14.2901 3.96008 14.655 3.27734C14.2885 3.95331 13.5754 4.4118 12.7559 4.4118Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation9}
                    d="M14.3047 0.734375L10.7891 4.27165C10.8878 4.3578 11.0162 4.41007 11.1572 4.41007H12.7576C13.5771 4.41007 14.2902 3.95158 14.6571 3.27561C14.8206 2.96941 14.9136 2.61901 14.9136 2.24699C14.9136 1.65911 14.6813 1.12608 14.3047 0.734375Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation9}
                    d="M10.7815 4.28323L10.7915 4.27323C10.6724 4.16933 10.5969 4.01672 10.5969 3.84571V2.23566C10.5969 1.86364 10.6899 1.51355 10.8535 1.20703C10.6857 1.51646 10.5898 1.87106 10.5898 2.24856V3.85862C10.5902 4.02801 10.6644 4.17934 10.7815 4.28323Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation9}
                    d="M10.5938 3.84345C10.5938 4.01446 10.6693 4.16707 10.7883 4.27097L14.304 0.734016C13.9107 0.324888 13.3599 0.0703125 12.7497 0.0703125C11.9302 0.0703125 11.2171 0.528806 10.8503 1.20477C10.6867 1.51097 10.5938 1.86105 10.5938 2.2334V3.84345Z"
                    fill="#E9E4E3"
                />
                <path
                    className={styles.loadAnimation9}
                    d="M12.7507 0.0715C13.3609 0.0715 13.9116 0.326075 14.3049 0.73488C14.6815 1.12658 14.9135 1.65961 14.9135 2.24749C14.9135 2.61951 14.8206 2.96959 14.657 3.27612C14.8247 2.96669 14.9206 2.61209 14.9206 2.23458C14.9206 1.03269 13.9523 0.0585938 12.7578 0.0585938C11.9331 0.0585938 11.2165 0.523218 10.8516 1.20596C11.2184 0.529994 11.9312 0.0715 12.7507 0.0715Z"
                    fill="#E9E4E3"
                />
            </svg>
        </div>
    );
};
