import {useQuery, UseQueryResult} from "react-query";
import {getUserPreference, TLoginResult} from "services/api/authApiService";
import {giftTransferList, TGiftTransferList} from "services/api/wineApiService";
import {extractErrorMessage} from "utils/extractErrorMessage";

export type TUseUserPreferences = {
	getDataProfile: (nameRequest: string, namePermission: string) => UseQueryResult<{
        value: string;
    }, unknown>;
    getGiftTransferList: (nameRequest: string, namePermission: "sent" | "received") => UseQueryResult<
        TGiftTransferList[], unknown
    >;
};

export const useUserPreferences = (
    sessionData: TLoginResult,
    setError?: (_: string) => void
): TUseUserPreferences => {
    const getDataProfile = (nameRequest: string, namePermission: string) => {
        return useQuery<{
        value: string;
    }>(
        [nameRequest, sessionData?.user_id],
        async() => {
            if (!sessionData) {
                return {value: ""};
            }
            const profileValue = await getUserPreference(
                sessionData.user_id,
                namePermission,
                sessionData.bearer_token
            );
            return {value: profileValue.value};
        },
        {
            refetchOnWindowFocus: false,
            enabled: Boolean(sessionData),
            onError: (err: unknown) => {
                if (setError) {
                    setError(extractErrorMessage(err));
                }
                console.error(err);
                return {value: ""};
            }
        }
    );
    };

    const getGiftTransferList = (nameRequest: string, namePermission: "sent" | "received") => {
        return useQuery(
            [nameRequest],
            async() => {
                if (!sessionData) {
                    return [];
                }
                return await giftTransferList(
                    sessionData.user_id,
                    namePermission,
                    sessionData.bearer_token
                );
            },
            {
                refetchOnWindowFocus: false,
                enabled: Boolean(sessionData),
                onError: (err: unknown) => {
                    if (setError) {
                        setError(extractErrorMessage(err));
                    }
                    return [];
                }
            }
        );
    };

    return {getDataProfile, getGiftTransferList};
};