import classNames from "classnames";
import styles from "./ImageButton.module.scss";
import {Loader} from "components/presentational/Loader/Loader";
import React from "react";

type TImageButtonType =
	| "primary"
	| "secondary"
	| "darken"
	| "lighten";

type TImageButton = {
	src: string;
	alt: string;
	label: string;
	type?: TImageButtonType;
	strictPadding?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	loading?: boolean;
};

export const ImageButton = (params: TImageButton): JSX.Element => {
    const {
        src,
        alt,
        type = "lighten",
        strictPadding,
        onClick,
        label,
        disabled,
        loading
    } = params;

    const clickHandler = () => {
        if (disabled) {
            return;
        }

        const action = onClick || (() => {});
        action();
    };

    return (
        <div
            className={classNames(
                styles.ImageButtonContent,
                disabled && styles.disabled,
                loading && styles.loading
            )}
            onClick={clickHandler}
            aria-hidden="true"
        >
            <div
                className={classNames(
                    styles.ImageContainer,
                    styles[type],
                    strictPadding && styles.strictPadding
                )}
            >
                <img className={styles.ImageButton} src={src} alt={alt} />

                <div className={styles.loader}>
                    <Loader />
                </div>

                {disabled && <div className={styles.Overlay}></div>}
            </div>
            <span className={styles.ImageButtonLabel}>{label}</span>
        </div>
    );
};
