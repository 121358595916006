import React, {ReactNode} from "react";

import styles from "./FullPageContentWrapper.module.scss";

export const FullPageContentWrapper = ({children}: {
	children: ReactNode;
}): JSX.Element => {
    return (
        <div className={styles.FullPageContentWrapper}>{children}</div>
    );
};
