import React from "react";
import ReactDOM from "react-dom";
import {App} from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import "./i18n";
import {whitelistedRoutes} from "./components/pages";
import {getEnvConfig} from "env/envConfig";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.warn))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// Compare the current pathname with whitelisted pathNames. In terms of receiving "false" it is needed to unregister serviceWorker.
if (
    getEnvConfig().useServiceWorkers &&
    window.location.pathname !== "/" &&
    whitelistedRoutes.some((value) => {
        return window.location.pathname.startsWith(value);
    }
    )
) {
    serviceWorkerRegistration.register();
} else {
    serviceWorkerRegistration.unregister();
}

reportWebVitals();
