import {TConditionalRouter} from "cnp-frontend-core";
import React from "react";
import {TFunction} from "i18next";
import {getEnvConfig} from "env/envConfig";

export const whitelistedRoutes = [
    "/claim-email",
    "/verify-email",
    "/version",
    "/authorize-options",
    "/ethereum-auth",
    "/email-auth/login",
    "/email-auth/register",
    "/google/login",
    "/google/register",
    "/register",
    "/auctions",
    "/sale",
    "/auction",
    "/wine-details",
    "/cart",
    "/contact",
    "/about",
    "/about/project",
    "/about/team",
    "/about/wines",
    "/error",
    "/wine-cellar",
    "/profile",
    "/dataprivacy",
    "/transactions"
];

const env = getEnvConfig();

export const pages = (t: TFunction): TConditionalRouter[] => {
    const filteredPages = [
        {
            exact: true,
            path: "/",
            component: React.lazy(() => {
                return import("./Home/Home");
            }),
            title: () => {
                return t("pageTitles.home");
            }
        }, {
            exact: true,
            path: "/claim-email",
            component: React.lazy(() => {
                return import("components/pages/ClaimForEmail/ClaimForEmail");
            }),
            title: () => {
                return t("pageTitles.signUp");
            }
        }, {
            exact: true,
            path: "/verify-email",
            component: React.lazy(() => {
                return import("components/pages/VerifyEmail/VerifyEmail");
            }),
            title: () => {
                return t("pageTitles.signUp");
            }
        }, {
            exact: true,
            path: "/version",
            component: React.lazy(() => {
                return import("components/pages/Version/Version");
            }),
            title: () => {
                return "Version";
            }
        }, {
            exact: true,
            path: "/authorize-options/:backLinkUrlEncoded?",
            component: React.lazy(() => {
                return import("./Auth/AuthorizeOptions/AuthorizeOptions");
            }),
            title: () => {
                return t("pageTitles.signUp");
            }
        }, {
            exact: true,
            path: "/ethereum-auth/:backLinkUrlEncoded?",
            component: React.lazy(() => {
                return import("./Auth/Ethereum/EthereumAuthorize/EthereumAuthorize");
            }),
            title: () => {
                return t("pageTitles.ethLogin");
            }
        }, {
            // withWallet: true,
            exact: true,
            path: "/email-auth/login/:backLinkUrlEncoded?",
            component: React.lazy(() => {
                return import("./Auth/SimpleEmail/EmailAuthorize/EmailAuthorize");
            }),
            title: () => {
                return t("pageTitles.emailLogin");
            }
        }, {
            // withWallet: true,
            exact: true,
            path: "/email-auth/register/:backLinkUrlEncoded?",
            component: React.lazy(() => {
                return import("./Auth/SimpleEmail/EmailRegister/EmailRegister");
            }),
            title: () => {
                return t("pageTitles.emailLogin");
            }
        }, {
            exact: true,
            path: "/register/:backLinkUrlEncoded?",
            component: React.lazy(() => {
                return import("./Auth/Ethereum/RegisterEthereum/RegisterEthereum");
            }),
            title: () => {
                return t("pageTitles.register");
            }
        }, {
            exact: true,
            path: "/google/login/:backLinkUrlEncoded?",
            component: React.lazy(() => {
                return import("./Auth/Google/GoogleLogin/GoogleLogin");
            }),
            title: () => {
                return t("pageTitles.googleLogin");
            }
        }, {
            exact: true,
            path: "/google/register/:backLinkUrlEncoded?",
            component: React.lazy(() => {
                return import("./Auth/Google/GoogleRegister/GoogleRegister");
            }),
            title: () => {
                return t("pageTitles.googleLogin");
            }
        }, {
            exact: true,
            path: "/auctions",
            component: React.lazy(() => {
                return import("./AuctionList/AuctionList");
            }),
            title: () => {
                return t("pageTitles.auction");
            }
        }, {
            exact: true,
            path: "/auction/:saleId",
            component: React.lazy(() => {
                return import("./AuctionDetails/AuctionDetails");
            }),
            title: () => {
                return t("pageTitles.wine");
            }
            // withWallet: true,
        }, {
            exact: true,
            path: "/sale",
            component: React.lazy(() => {
                return import("./NormalSaleList/NormalSaleList");
            }),
            title: () => {
                return t("pageTitles.sale");
            }
        }, {
            exact: true,
            path: "/valentine-special",
            component: React.lazy(() => {
                return import("./ValentineSpecialSaleList/ValentineSpecialSaleList");
            }),
            title: () => {
                return t("pageTitles.sale");
            }
        }, {
            exact: true,
            path: "/sale/:saleId",
            component: React.lazy(() => {
                return import("./DirectSaleDetails/DirectSaleDetails");
            }),
            title: () => {
                return t("pageTitles.wine");
            }
            // withWallet: true,
        }, {
            exact: true,
            path: "/wine-details/:tokenId",
            component: React.lazy(() => {
                return import("./WineDetails/WineDetails");
            }),
            title: () => {
                return t("pageTitles.wine");
            }
        }, {
            exact: true,
            path: "/wine-details/:tokenId/manage",
            component: React.lazy(() => {
                return import("./ManageBottle/ManageBottle");
            }),
            title: () => {
                return t("pageTitles.manageBottle");
            }
        }, {
            exact: true,
            path: "/cart",
            component: React.lazy(() => {
                return import("./Cart/CartPage");
            }),
            title: () => {
                return t("pageTitles.cart");
            }
        }, {
            exact: true,
            path: "/contact",
            component: React.lazy(() => {
                return import("./Contacts/Contacts");
            }),
            title: () => {
                return t("pageTitles.contacts");
            }
        }, {
            exact: true,
            path: "/dataprivacy",
            component: React.lazy(() => {
                return import("./PrivacyPolicy/PrivacyPolicy");
            }),
            title: () => {
                return t("pageTitles.privacyPolicy");
            }
        }, {
            exact: true,
            path: "/about",
            component: React.lazy(() => {
                return import("./About/About");
            }),
            title: () => {
                return t("pageTitles.about");
            }
        },
        {
            exact: true,
            path: "/about/project",
            component: React.lazy(() => {
                return env.showAboutProjectsPageWithCmsData
                    ? import("./AboutProject/AboutProject")
                    : import("./AboutProject/AboutProjectFallback");
            }),
            title: () => {
                return t("pageTitles.aboutProject");
            }
        },
        {
            exact: true,
            path: "/about/team",
            component: React.lazy(() => {
                return env.showAboutTeamPageWithCmsData
                    ? import("./AboutTeam/AboutTeam")
                    : import("./AboutTeam/AboutTeamFallback");
            }),
            title: () => {
                return t("pageTitles.aboutTeam");
            }
        },
        {
            exact: true,
            path: "/about/wines",
            component: React.lazy(() => {
                return import("./AboutWines/AboutWines");
            }),
            title: () => {
                return "Our Wines";
            }
        }, {
            exact: true,
            path: "/error",
            component: React.lazy(() => {
                return import("./Error/Error");
            }),
            title: () => {
                return t("pageTitles.error");
            }
        }, {
            // withWallet: true,
            exact: true,
            path: "/wine-cellar",
            component: React.lazy(() => {
                return import("./WineCellar/WineCellar");
            }),
            title: () => {
                return t("pageTitles.wineCellar");
            }
        }, {
            exact: true,
            path: "/wine-cellar/:address",
            component: React.lazy(() => {
                return import("./WineCellar/WineCellar");
            }),
            title: () => {
                return t("pageTitles.wineCellar");
            }
        }, {
            exact: true,
            path: "/wine-cellar/:address/:tokenId",
            component: React.lazy(() => {
                return import("./WineDetails/WineDetails");
            }),
            title: () => {
                return t("pageTitles.wine");
            }
        }, {
            exact: true,
            path: "/faq",
            component: React.lazy(() => {
                return env.showFaqPageWithCmsData ? import("./Faq/Faq") : import("./Faq/FaqFallback");
            }),
            title: () => {
                return t("pageTitles.faq");
            }
        }, {
            exact: true,
            path: "/faq/:anchor",
            component: React.lazy(() => {
                return env.showFaqPageWithCmsData ? import("./Faq/Faq") : import("./Faq/FaqFallback");
            }),
            title: () => {
                return t("pageTitles.faq");
            }
        },
        env.showProfile && {
            // withWallet: true,
            exact: true,
            path: "/profile",
            component: React.lazy(() => {
                return import("components/pages/Profile/Profile");
            }),
            title: () => {
                return t("pageTitles.profile");
            }
        },
        env.showProfile && {
            // withWallet: true,
            exact: true,
            path: "/transactions",
            component: React.lazy(() => {
                return import("components/pages/Transactions/Transactions");
            }),
            title: () => {
                return t("pageTitles.transactions");
            }
        },
        {
            exact: true,
            path: "/admin/shippingFulfillment",
            component: React.lazy(() => {
                return import("components/pages/Admin/AdminShipping/AdminShipping");
            }),
            title: () => {
                return t("pageTitles.admin");
            }
        },
        {
            exact: true,
            path: "/admin/shippingFulfillment/slip",
            component: React.lazy(() => {
                return import("components/pages/Admin/AdminShipping/AdminShipping");
            }),
            title: () => {
                return t("pageTitles.admin");
            }
        },
        {
            // withWallet: true,
            exact: true,
            path: "/admin",
            component: React.lazy(() => {
                return import("components/pages/Admin/Admin/Admin");
            }),
            title: () => {
                return t("pageTitles.admin");
            }
        },
        {
            // withWallet: true,
            exact: true,
            path: "/admin/bottles",
            component: React.lazy(() => {
                return import("components/pages/Admin/AdminBottlesList/AdminBottlesList");
            }),
            title: () => {
                return t("pageTitles.admin");
            }
        },
        {
            // withWallet: true,
            exact: true,
            path: "/admin/bottles/new",
            component: React.lazy(() => {
                return import("components/pages/Admin/AdminAddBottle/AdminAddBottle");
            }),
            title: () => {
                return t("pageTitles.admin");
            }
        },
        {
            // withWallet: true,
            exact: true,
            path: "/admin/bottles/edit/:wineId",
            component: React.lazy(() => {
                return import("components/pages/Admin/AdminEditBottle/AdminEditBottle");
            }),
            title: () => {
                return t("pageTitles.admin");
            }
        },
        {
            // withWallet: true,
            exact: true,
            path: "/admin/auctions-and-sales/new",
            component: React.lazy(() => {
                return import("components/pages/Admin/AdminAddAuctionOrSale/AdminAddAuctionOrSale");
            }),
            title: () => {
                return t("pageTitles.admin");
            }
        },
        {
            // withWallet: true,
            exact: true,
            path: "/admin/left-over-bottles",
            component: React.lazy(() => {
                return import("components/pages/Admin/AdminLeftOverAccout/AdminLeftOverAccout");
            }),
            title: () => {
                return t("pageTitles.admin");
            }
        },
        {
            // withWallet: true,
            exact: true,
            path: "/admin/add-multiple-wines",
            component: React.lazy(() => {
                return import("components/pages/Admin/AdminAddMultipleWines/AdminAddMultipleWines");
            }),
            title: () => {
                return t("pageTitles.admin");
            }
        },
        {
            // withWallet: true,
            exact: true,
            path: "/b2b",
            component: React.lazy(() => {
                return import("components/pages/B2B/B2BHomePage/B2BHomePage");
            }),
            title: () => {
                return t("pageTitles.b2b");
            }
        },
        {
            // withWallet: true,
            exact: true,
            path: "/b2b/mass-gift-sending",
            component: React.lazy(() => {
                return import("components/pages/B2B/B2BMassGiftSendingRoot/B2BMassGiftSendingRoot");
            }),
            title: () => {
                return t("pageTitles.b2b");
            }
        },
        {
            exact: true,
            path: "/t/:stickerCode",
            component: React.lazy(() => {
                return import("components/pages/StikerCodeInfo/StikerCodeInfo");
            }),
            title: () => {
                return t("pageTitles.stickerCode");
            }
        },
        {
            exact: false,
            path: "/not-found",
            component: React.lazy(() => {
                return import("./NotFound/NotFound");
            }),
            title: () => {
                return t("pageTitles.404");
            }
        },
        {
            exact: false,
            path: "*",
            component: React.lazy(() => {
                return import("./NotFound/NotFound");
            }),
            title: () => {
                return t("pageTitles.404");
            }
        }
    ];

    return filteredPages;
};
