/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
    SessionDataContext,
    SessionDataContextParams,
    Web3AuthFacadeContext
} from "cnp-frontend-core";
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {useQuery} from "react-query";
import {
    TAssetDetail,
    TChainId,
    TWalletDetailItem,
    TWineDetail,
    walletDetail
} from "services/api/wineApiService";
import {getEnvConfig} from "env/envConfig";

export type TCart = {
	name: string;
	bottle_size: "standard" | "magnum";
	vintage_year: number;
	image: string;
	token_ids: number[];
	wine_id: number;
	color?: "white" | "red";
	deliverable?: boolean;
};

export type TInfoDelivery = {
    selectedWines: TCart[];
    selfPickupWineCellar: boolean;
}

export type UseCartParams = {
	cart?: TCart[];
	isCartNotEmpty?: boolean;
	qtyPerBottle?: { key: string; qty: number }[];
	submittedWineIds?: TWineDetail[];
	wineListToSubmit?: TWalletDetailItem[];
	updateCart: (cart: TCart[]) => void;
	addToCart: (item: TAssetDetail) => void;
	clearCartStorage: () => void;
    clearCart: () => void;
	updateSubmittedTokenIds: () => void;
	addTokenIdToItemInCart: (item: TCart) => void;
	removeTokenIdFromItemInCart: (item: TCart) => void;
	canAddOneMore?: (item: TCart) => boolean;
	canRemoveMore?: (item: TCart) => boolean;
	matchesWineIdByImagePath?: (
		wineId: number,
		tokenImagePath: string
	) => boolean;
	getCartWithFilteredTokenIdsAfterSubmit: () => TCart[];
    infoAboutDelivery?: boolean;
    updateInfoAboutDelivery: () => void;
    clearCartorInfoDelivery: (item: string) => void;
    setSubmittedWineIds: (item: any[]) => void;
};

function matchesWineId(value: TWalletDetailItem, wineInfo: TCart) {
    const expectedWineIdPath = `/${wineInfo.wine_id}.png`;
    const matched: boolean = value.token_image_path.endsWith(
        expectedWineIdPath
    );
    return matched;
}

/*export const MAX_STANDARD_BOTTLES_PER_REQUEST = 18;
export const MAX_MAGNUM_BOTTLES_PER_REQUEST = 3;*/

export const useCart = (): UseCartParams => {
    const [cart, setCart] = useState<TCart[]>([]);
    const [userDeliverableWallet, setUserliverableWallet] = useState<
		TWalletDetailItem[]
	>([]);
    const [updateFilerData, setUpdateFilerData] = useState<
		TWalletDetailItem[]
	>([]);

    const [wineListToSubmit, setWineListToSubmit] = useState<
		TWalletDetailItem[]
	>([]);
    const [infoAboutDelivery, setInfoAboutDelivery] = useState<boolean>();

    const {account} = useContext(Web3AuthFacadeContext);
    const {defaultChainId} = getEnvConfig();

    const [submittedWineIds, setSubmittedWineIds] = useState<any[]>([]);

    const {sessionData}: SessionDataContextParams = useContext(
        SessionDataContext
    );

    const [selectItem, setSelectItem] = useState<TWineDetail>();

    const mapAssetDetailToCartItem = (
        wineInfo: TAssetDetail | TWineDetail
    ): TCart => {
        return {
            name: wineInfo.name,
            bottle_size: wineInfo.bottle_size ?? "standard",
            vintage_year: wineInfo.vintage_year,
            image: wineInfo.front_image_path,
            token_ids: [+wineInfo.token_id],
            wine_id: wineInfo.wine_id || 0,
            color: wineInfo.wine_color
        };
    };

    const mapWineDetatails = (
        wineInfo: TWineDetail[]
    ): TCart[] => {
        return wineInfo.map((wineItem: TWineDetail) => {
            return {
                name: wineItem.name,
                bottle_size: wineItem.bottle_size ?? "standard",
                vintage_year: wineItem.vintage_year,
                image: wineItem.front_image_path,
                token_ids: [+wineItem.token_id],
                wine_id: wineItem.wine_id || 0,
                color: wineItem.wine_color
            };
        });
    };

    const {data} = useQuery(
        ["walletDetail", account],
        () => {
            if (account) {
                return walletDetail(String(defaultChainId) as TChainId, account);
            }
            return null;
        },
        {
            refetchOnWindowFocus: false,
            enabled: Boolean(account && sessionData)
        }
    );

    const matchesWineIdByImagePath = (
        wineId: number,
        tokenImagePath: string
    ) => {
        const expectedWineIdPath = new RegExp(`${wineId}(_.*)?\\.png$`);
        const matched = tokenImagePath.match(expectedWineIdPath);
        // eslint-disable-next-line no-debugger
        // debugger;
        return !!matched;
    };

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            const updateCartList = sessionStorage.getItem(
                "submitedTokenIds"
            );
            if (updateCartList && updateCartList !== "undefined") {
                setWineListToSubmit(JSON.parse(updateCartList) || []);
            }
        }
        return () => {
            mounted = false;
        };
    }, []);

    const tokenId = submittedWineIds.find((tokenId: number) => {
        return tokenId;
    });

    const updateInfoAboutDelivery = useCallback(() => {
        const selectWines = sessionStorage.getItem("infoAboutDelivery");
        if (selectWines) {
            const parseSelectWines = JSON.parse(selectWines);
            setInfoAboutDelivery(parseSelectWines.selfPickupWineCellar);
            if (parseSelectWines.selectedWines) {
                const formatParsSelectWines: TCart[] = mapWineDetatails(parseSelectWines.selectedWines as TWineDetail[]);
                const resultArray: TCart[] = [];
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < formatParsSelectWines.length; i++) {
                    const currentWineSelection = formatParsSelectWines[i];
                    const foundIndex = resultArray.findIndex((item) => {
                        return item.wine_id === currentWineSelection.wine_id;
                    });

                    if (foundIndex !== -1) {
                        resultArray[foundIndex].token_ids =
                        resultArray[foundIndex].token_ids.concat(currentWineSelection.token_ids);
                    } else {
                        resultArray.push(currentWineSelection);
                    }
                }
                if (resultArray) {
                    setCart(resultArray);
                }
            }
        }
    }, []);
    useEffect(() => {
        const currentCart = sessionStorage.getItem("cart");

        if (currentCart && JSON.parse(currentCart)[0] !== null) {
            const savedCart = JSON.parse(currentCart);

            if (
                savedCart &&
				savedCart !== "undefined" &&
				Array.isArray(savedCart)
            ) {
                setCart(savedCart);
            }
        }

        updateInfoAboutDelivery();

        if (data && data.assets) {
            setUserliverableWallet(
                data.assets.filter((value) => {
                    return value.deliverable;
                })
            );
        }
    }, [data, sessionStorage]);

    useEffect(() => {
        const tokenIdsFromStorage = sessionStorage.getItem(
            "submitedTokenIds"
        );

        if (tokenIdsFromStorage && tokenIdsFromStorage !== "undefined") {
            setSubmittedWineIds(JSON.parse(tokenIdsFromStorage) || []);
        }
    }, [wineListToSubmit]);


    const cartTokenIds = useMemo(() => {
        return cart.flatMap((cartItem) => {
            return cartItem.token_ids;
        });
    }, [cart]);

    useEffect(() => {
        setUpdateFilerData(
            userDeliverableWallet.filter(
                (value) => {
                    return value.token_id !== tokenId?.token_id;
                }
            )
        );
    }, [userDeliverableWallet, tokenId]);

    const canAddOneMore: (wineInfo: TCart) => boolean = useCallback(
        (wineInfo: TCart) => {
            return updateFilerData.some((value) => {
                return (
                    matchesWineId(value, wineInfo) &&
					!wineInfo.token_ids.includes(value.token_id)
                );
            });
        },
        [updateFilerData, cart]
    );

    const canRemoveMore: (wineInfo: TCart) => boolean = useCallback(
        (wineInfo: TCart) => {
            return updateFilerData.some((value) => {
                return (
                    matchesWineId(value, wineInfo) &&
					wineInfo.token_ids.length > 0
                );
            });
        },
        [updateFilerData]
    );

    const groupBottlesBy = useCallback(
        (bottles: TWalletDetailItem[], property: string) => {
            return bottles.reduce((accending, currValue) => {
                // @ts-ignore
                if (!accending[currValue[property]]) {
                    // @ts-ignore
                    accending[currValue[property]] = [];
                }
                // @ts-ignore
                accending[currValue[property]].push(currValue);
                return accending;
            }, {});
        },
        []
    );

    const qtyPerBottle: { key: string; qty: number }[] = useMemo(() => {
        const groups = groupBottlesBy(
            updateFilerData,
            "token_image_path"
        // "wine_id" todo match via wine_id in the future?
        );

        return Object.keys(groups).map((key) => {
            return {
                key: key,
                qty: ((groups as any)[key] as TWalletDetailItem[])?.length || 0
            };
        });
    }, [updateFilerData, groupBottlesBy]);

    const addToCart = useCallback((wineInfo: TAssetDetail) => {
        const formatCartItem: TCart = mapAssetDetailToCartItem(wineInfo);
        const exist = cart.find(
            (cartItem) => {
                return cartItem.wine_id === formatCartItem.wine_id;
            }
        );
        const selectWines = sessionStorage.getItem("infoAboutDelivery");
        if (selectWines) {
            const parseSelectWines = JSON.parse(selectWines);
            setInfoAboutDelivery(parseSelectWines.selfPickupWineCellar);
        }

        if (!exist) {
            const newCart = [...cart, formatCartItem];
            setCart(newCart);
            sessionStorage.setItem("cart", JSON.stringify(newCart));
        }
    }, [cart]);

    const addTokenIdToItemInCart = (item: TCart) => {
        const newCart = cart.map((cartItem) => {
            if (cartItem.wine_id === item.wine_id) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const tokenIdToAdd = updateFilerData.find((value) => {
                    return (
                        matchesWineId(value, item) &&
						!item.token_ids.includes(value.token_id)
                    );
                })!.token_id;
                const oneMoreToken = cartItem.token_ids.concat(tokenIdToAdd);
                return {...cartItem, token_ids: oneMoreToken};
            } else {
                return cartItem;
            }
        });
        if (newCart && newCart[0]) {
            setCart(newCart);
            sessionStorage.setItem("cart", JSON.stringify(newCart));
        }
    };

    const clearCartStorage = () => {
        setCart([]);
        sessionStorage.removeItem("cart");
        sessionStorage.removeItem("submitedTokenIds");
        sessionStorage.removeItem("newBottles");
    };

    const clearCart = () => {
        sessionStorage.removeItem("cart");
        setCart([]);
        setTimeout(() => {
            sessionStorage.removeItem("submitedTokenIds");
        }, 180000);
    };

    const clearCartorInfoDelivery = (removeStatus: string) => {
        if (removeStatus === "cart") {
            sessionStorage.removeItem("cart");
        } else {
            setInfoAboutDelivery(undefined);
            sessionStorage.removeItem("infoAboutDelivery");
        }
    };

    const updateSubmittedTokenIds = useCallback(() => {
        const newCart: TWalletDetailItem[] = userDeliverableWallet.map(
            (cartItem) => {
                const isDeliverable = cartTokenIds.includes(
                    cartItem.token_id
                );
                cartItem.deliverable = !isDeliverable;
                return cartItem;
            }
        );

        const submittedTokens = newCart.filter(
            (cartItem) => {
                return !cartItem.deliverable;
            }
        );

        const updateCartList = sessionStorage.getItem(
            "submitedTokenIds"
        );
        if (updateCartList && updateCartList !== "undefined") {
            const updateSubmitedIds = [...submittedTokens, ...JSON.parse(updateCartList)];
            sessionStorage.setItem(
                "submitedTokenIds",
                JSON.stringify(updateSubmitedIds)
            );
        } else {
            setSubmittedWineIds(submittedTokens);
            sessionStorage.setItem(
                "submitedTokenIds",
                JSON.stringify(submittedTokens)
            );
        }
    }, [cartTokenIds, userDeliverableWallet]);

    const updateCart = (cart: TCart[]) => {
        setCart(cart);
        sessionStorage.setItem("cart", JSON.stringify(cart));
    };

    const getCartWithFilteredTokenIdsAfterSubmit = useCallback(() => {
        if (!cart || !Array.isArray(cart)) {
            return [];
        }
        return cart.map((cartItem) => {
            if (submittedWineIds && Array.isArray(submittedWineIds)) {
                const submittedToken = submittedWineIds.find((tokenId) => {
                    return cartItem.token_ids.includes(tokenId.token_id);
                }
                );
                if (submittedToken) {
                    cartItem.token_ids = cartItem.token_ids.filter(
                        (tokenId) => {
                            return tokenId !== submittedToken.token_id;
                        }
                    );
                }
            }

            return cartItem;
        });
    }, [cart, submittedWineIds]);

    const removeTokenIdFromItemInCart = (item: TCart) => {
        const existItem = cart.find(
            (cartItem) => {
                return cartItem.wine_id === item.wine_id;
            }
        );
        if (existItem) {
            const newCart: TCart[] = cart.map((cartItem) => {
                if (cartItem.wine_id === item.wine_id) {
                    const modifiedItem: TCart = {...cartItem};
                    // @FIXME: need to review later with slice
                    const oneLessToken = cartItem.token_ids.filter(
                        (_, index) => {
                            return index !== 0;
                        }
                    );
                    modifiedItem.token_ids = oneLessToken;
                    return modifiedItem;
                } else {
                    return cartItem;
                }
            });
            if (newCart && newCart[0]) {
                setCart(newCart);
                sessionStorage.setItem("cart", JSON.stringify(newCart));
            }
        }
    };

    const isCartNotEmpty = useMemo(() => {
        return cart && cart.length > 0;
    }, [cart]);

    return {
        cart,
        updateCart,
        isCartNotEmpty,
        qtyPerBottle,
        submittedWineIds,
        wineListToSubmit,
        addToCart,
        addTokenIdToItemInCart,
        removeTokenIdFromItemInCart,
        canAddOneMore,
        canRemoveMore,
        matchesWineIdByImagePath,
        clearCartStorage,
        clearCart,
        updateSubmittedTokenIds,
        getCartWithFilteredTokenIdsAfterSubmit,
        infoAboutDelivery,
        updateInfoAboutDelivery,
        clearCartorInfoDelivery,
        setSubmittedWineIds
    };
};
