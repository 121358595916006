import React, {Suspense, useEffect} from "react";

import {pages} from "./components/pages";

import {Routers} from "./components/common/Routers/Routers";
import {GlobalPageLoader} from "./components/presentational/GlobalPageLoader/GlobalPageLoader";
import {QueryClient, QueryClientProvider} from "react-query";
import {Web3WalletProvider} from "cnp-frontend-core";
import {Api} from "./services/cryptoAgriApi";
import {BrowserRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useMounted} from "./hooks";
import {GlobalLoaderContextProvider} from "./contexts_and_wrappers/GlobalLoaderContext";
import {ErrorContextProvider} from "./contexts_and_wrappers/ErrorContext";
import {ComingSoon} from "./components/pages/ComingSoon/ComingSoon";
import Contacts from "./components/pages/Contacts/Contacts";

import {ArrowBackContextProvider} from "./contexts_and_wrappers/ArrowBackContext";
import {CartContextProvider} from "contexts_and_wrappers/CartContext";
import {getEnvConfig} from "env/envConfig";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/app.scss";
import {Web3GlobalStorageContextProvider} from "./contexts_and_wrappers/Web3GlobalStorageContext";
import {SessionExpirationCheckingContextProvider} from "contexts_and_wrappers/SessionExpirationCheckingContext";
import {InBrowserConfirmationPopup} from "components/common/InBrowserConfirmationPopup/InBrowserConfirmationPopup";
import {AgeRestriction} from "./components/pages/AgeRestriction/AgeRestriction";
import {UserAccessContextProvider} from "contexts_and_wrappers/UserAccessContext";
import {B2BMassSendingContextProvider} from "contexts_and_wrappers/B2BMassSendingContext";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            retryDelay: 0,
            refetchOnWindowFocus: false
        }
    }
});

export const App = (): JSX.Element => {
    const env = getEnvConfig();
    const {t, i18n} = useTranslation();

    const mounted = useMounted();

    useEffect(() => {
        if (!mounted.current || !i18n) {
            return;
        }

        const language =
			localStorage.getItem("selectedLanguage") ??
			window.navigator.language;

        const locale =
			language.length >= 5 ? language.substring(0, 2).toLowerCase() : language;

        i18n.changeLanguage(
            ["de", "en"].includes(locale) ? locale : "de"
        );
    }, [i18n, mounted]);

    if (env && env.showComingSoonPage) {
        return (
            <>
                <ComingSoon />
                <Contacts />
            </>
        );
    }

    return (
        <AgeRestriction>
            <Web3WalletProvider
                env={env}
                contracts={{}}
                ConfirmationPopup={InBrowserConfirmationPopup}
                api={Api}
                apiConfig={{baseUrl: env?.apiBaseUri}}
            >
                <ArrowBackContextProvider>
                    <GlobalLoaderContextProvider>
                        <QueryClientProvider client={queryClient}>
                            <ErrorContextProvider>
                                <Web3GlobalStorageContextProvider>
                                    <Suspense fallback={<GlobalPageLoader text="" />}>
                                        <CartContextProvider>
                                            <SessionExpirationCheckingContextProvider>
                                                <UserAccessContextProvider>
                                                    <B2BMassSendingContextProvider>
                                                        <BrowserRouter>
                                                            <div className="hiddenGrape">
                                                                <img src="/images/grapeRed.svg" alt="" />
                                                            </div>
                                                            <Routers routes={pages(t)} />
                                                        </BrowserRouter>
                                                    </B2BMassSendingContextProvider>
                                                </UserAccessContextProvider>
                                            </SessionExpirationCheckingContextProvider>
                                        </CartContextProvider>
                                    </Suspense>
                                </Web3GlobalStorageContextProvider>
                            </ErrorContextProvider>
                        </QueryClientProvider>
                    </GlobalLoaderContextProvider>
                </ArrowBackContextProvider>
            </Web3WalletProvider>
        </AgeRestriction>
    );
};
