import React from "react";

import styles from "./StaticFormValue.module.scss";
import classNames from "classnames";

type TStaticFormValue = {
	label: string;
	value: string;
	secondary?: boolean;
};

export const StaticFormValue = (params: TStaticFormValue): JSX.Element => {
    const {label, value, secondary} = params;

    return (
        <div
            className={classNames(
                styles.StaticFormValue,
                secondary && styles.secondary
            )}
        >
            <label htmlFor="">{label}</label>
            <p className="button_secondary">{value}</p>
        </div>
    );
};
