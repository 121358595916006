import React, {ReactNode} from "react";

import classNames from "classnames";

import styles from "./FormEntryBlock.module.scss";

type TFormEntryBlock = {
	children: ReactNode;
	type?:
		| "primary"
		| "lighten"
		| "transparent"
		| "surface"
		| "red"
		| "white";
	align?: "left";
	contentSize?: "content-lg" | "content-md";
	largeDesktop?: boolean;
	registerGoogleForm?: boolean;
};

export const FormEntryBlock = (params: TFormEntryBlock): JSX.Element => {
    const {
        children,
        type = "lighten",
        align,
        contentSize,
        largeDesktop,
        registerGoogleForm
    } = params;

    return (
        <section
            className={classNames(
                styles.FormEntryBlock,
                type && styles[type],
                align && styles[align],
                contentSize && styles[contentSize],
                largeDesktop && styles.largeDesktop,
                registerGoogleForm && styles.registerGoogleForm
            )}
        >
            <div className="body_secondary">
                <div className="container">{children}</div>
            </div>
        </section>
    );
};
