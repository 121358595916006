/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import React, {ReactNode, useEffect} from "react";
import {FormProvider, UseFormReturn} from "react-hook-form";
import styles from "./Form.module.scss";

type TForm = {
	children: ReactNode;
	onSubmit?: (data: any) => void;
	methods: UseFormReturn;
	profilePage?: boolean;
	registerGoogleForm?: boolean;
};

export const Form = (params: TForm): JSX.Element => {
    const {
        children,
        onSubmit,
        methods,
        profilePage,
        registerGoogleForm
    } = params;

    useEffect(
        () => {
            return void methods.setValue("touched", false);
        },
        [methods]
    );

    const handleSubmitWrapper = (data: any) => {
        onSubmit && onSubmit(data);
    };

    return (
        <FormProvider {...methods}>
            <form
                className={classNames(
                    styles.Form,
                    profilePage && styles.profilePage,
                    registerGoogleForm && styles.registerGoogleForm
                )}
                onSubmit={methods?.handleSubmit(handleSubmitWrapper)}
            >
                {children}
            </form>
        </FormProvider>
    );
};
