import {api} from "../apiService";

export type TAuthHeader = {
    headers: {[
        key: string
    ]: string}
};

export const authHeader = (bearer_token: string): TAuthHeader => {
    return {headers: {authorization: `Bearer ${bearer_token}`}};
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getUserPermissions = async(userId: string, bearerToken: string) => {
    try {
        const permissions = await api.user.permissionsDetail(userId, authHeader(bearerToken));
        return permissions.data;
    } catch (err) {
        throw err;
    }
};
