import {AuthFacadeContext, Web3AuthFacadeContext} from "cnp-frontend-core";
import {TDistibutedTokens} from "components/common/B2B";
import {Signer} from "ethers";
import React, {
    Context,
    createContext,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import {useTranslation} from "react-i18next";
import {TTransferTokenRecipient} from "services/api/b2bApiService";
import {getTokensWithSignatures} from "utils/b2b";

export type TEmailTextData = {
    salutation: string;
    fullName: string;
    email: string;
};

type B2BMassSendingContextParams = {
    combineEmailListWithDistributedTokens: () => Promise<boolean>;
    updateEmailDataList: (emails: TEmailTextData[]) => void;
    updateDistributedTokens: (distributedTokens: TDistibutedTokens[]) => void;
    updatePersonCount: (personQty: number) => void;
    updateCustomInputPersonCount: (personQty: number) => void;
    cancelDistributedTokensSelection: () => void;
    cancelEmailInfoListSetting: () => void;
    cancelEmailTemplateSetting: () => void;
    combinedEmailsAndTokensWithSignatures?: TTransferTokenRecipient[];
    distributedTokens?: TDistibutedTokens[];
    personCount?: number;
    customInputPersonCount?: number;
    emailDataList?: TEmailTextData[];
    signer?: Signer | null;
}

export const B2BMassSendingContext: Context<B2BMassSendingContextParams> =
    createContext({
        combineEmailListWithDistributedTokens: () => {
            return Promise.resolve(false as boolean);
        },
        updateEmailDataList: (_: TEmailTextData[]) => {},
        updateDistributedTokens: (_: TDistibutedTokens[]) => {},
        updatePersonCount: (_: number) => {},
        updateCustomInputPersonCount: (_: number) => {},
        cancelDistributedTokensSelection: () => {},
        cancelEmailInfoListSetting: () => {},
        cancelEmailTemplateSetting: () => {}
    });

export function B2BMassSendingContextProvider({children}: {
	children: ReactElement;
}): JSX.Element {
    const [personCount, setPersonCount] = useState<number>(0);
    const [customInputPersonCount, setCustomInputPersonCount] = useState<number>(0);
    const [distributedTokens, setDistributedTokens] = useState<TDistibutedTokens[]>([]);
    const [emailDataList, setEmailDataList] = useState<TEmailTextData[]>([]);
    const [
        combinedEmailsAndTokensWithSignatures,
        setCombinedEmailsAndTokensWithSignatures
    ] = useState<TTransferTokenRecipient[]>([]);

    const [signer, setSigner] = useState<Signer | null>(null);

    const {
        isAuthorizedCompletely,
        account,
        bearerToken,
        userId,
        getSilentSignerAsync
    }: AuthFacadeContext = useContext(Web3AuthFacadeContext);

    const {t, i18n} = useTranslation();
    const currentLocale = i18n.language;

    const updateEmailDataList = (emails: TEmailTextData[]) => {
        setEmailDataList(emails);
    };

    const updateDistributedTokens = (distributedTokens: TDistibutedTokens[]): void => {
        setDistributedTokens(distributedTokens);
    };
    const updatePersonCount = (personQty: number): void => {
        setPersonCount(personQty);
    };
    const updateCustomInputPersonCount = (personQty: number): void => {
        setCustomInputPersonCount(personQty);
    };

    useEffect(() => {
        let mounted = true;

        if (mounted && isAuthorizedCompletely) {
            (async() => {
                if (mounted) {
                    const currSigner = await getSilentSignerAsync();
                    setSigner(currSigner);
                }
            })();
        }

        return () => {
            mounted = false;
        };
    }, [isAuthorizedCompletely, getSilentSignerAsync]);

    const combineEmailListWithDistributedTokens = useCallback(async() => {
        const notAllowedToContinue = (
            !account ||
            !isAuthorizedCompletely ||
            !signer ||
            !userId ||
            !bearerToken ||
            !personCount ||
            isNaN(Number(personCount))
        );

        if (notAllowedToContinue) {
            return false;
        }

        try {
            const combinedDitributedTokens: TTransferTokenRecipient[] = await getTokensWithSignatures(
                distributedTokens,
                personCount,
                emailDataList,
                account,
                isAuthorizedCompletely,
                signer,
                currentLocale as "en-US" | "de"
            );
            setCombinedEmailsAndTokensWithSignatures(combinedDitributedTokens);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    }, [
        distributedTokens,
        signer,
        account,
        isAuthorizedCompletely,
        userId,
        bearerToken,
        emailDataList,
        personCount
    ]);

    const cancelDistributedTokensSelection = () => {
        setPersonCount(0);
        setCustomInputPersonCount(0);
        setDistributedTokens([]);
        setCombinedEmailsAndTokensWithSignatures([]);
    };

    const cancelEmailInfoListSetting = () => {
        setPersonCount(0);
        setCustomInputPersonCount(0);
        setDistributedTokens([]);
        setCombinedEmailsAndTokensWithSignatures([]);
    };

    const cancelEmailTemplateSetting = () => {
        setPersonCount(0);
        setCustomInputPersonCount(0);
        setDistributedTokens([]);
        setCombinedEmailsAndTokensWithSignatures([]);
    };

    const params: B2BMassSendingContextParams = {
        combineEmailListWithDistributedTokens,
        updateEmailDataList,
        updateDistributedTokens,
        updatePersonCount,
        updateCustomInputPersonCount,
        cancelDistributedTokensSelection,
        cancelEmailInfoListSetting,
        cancelEmailTemplateSetting,
        combinedEmailsAndTokensWithSignatures,
        distributedTokens,
        personCount,
        customInputPersonCount,
        emailDataList,
        signer
    };

    return (
        <B2BMassSendingContext.Provider value={params}>
            {children}
        </B2BMassSendingContext.Provider>
    );
}
