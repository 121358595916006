import {
    AuthFacadeContext,
    SessionDataContext,
    SessionDataContextParams,
    Web3AuthFacadeContext
} from "cnp-frontend-core";
import React, {
    Context,
    createContext,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import {
    getUserPreference,
    TLoginResult
} from "services/api/authApiService";
import {Web3GlobalStorageContext} from "./Web3GlobalStorageContext";

export const SessionExpirationCheckingContext: Context<null> =
	createContext(null);

export function SessionExpirationCheckingContextProvider({children}: {
	children: ReactElement;
}): JSX.Element {
    const {sessionData, clearSessionData}: SessionDataContextParams =
		useContext(SessionDataContext);

    const {updateIsAuthSignerRequested} = useContext(
        Web3GlobalStorageContext
    );

    const {
        deactivateWeb3Async,
        resetWeb3Provider,
        isAuthorizedCompletely
    }: AuthFacadeContext = useContext(Web3AuthFacadeContext);

    const deactivateAndClearSession = useCallback(async() => {
        await deactivateWeb3Async();
        clearSessionData();
        resetWeb3Provider();
        updateIsAuthSignerRequested(false);
    }, [
        deactivateWeb3Async,
        clearSessionData,
        resetWeb3Provider,
        updateIsAuthSignerRequested
    ]);

    const [isFullNameRequested, setIsFullNameRequested] =
		useState(false);

    useEffect(() => {
        let mounted = true;

        if (mounted && isAuthorizedCompletely && !isFullNameRequested) {
            setIsFullNameRequested(true);
            (async() => {
                if (mounted) {
                    try {
                        await getUserPreference(
                            (sessionData as TLoginResult).user_id,
                            "full_name",
                            sessionData.bearer_token
                        );
                    } catch (err: any) {
                        // @FIXME:
                        //during initial in browser account creation, this will have an error
                        // BUT it is status 404, because we are just about to create the preferences.
                        //in case of timeout it is code 401. with code 404, this error should rightfully be ignored.
                        // cleanup: make sure this whole useEffect will not trigger during account creation.
                        if (err && err.status === 401) {
                            deactivateAndClearSession();
                        }
                    }
                }
            })();
        }

        return () => {
            mounted = false;
        };
    }, [
        sessionData,
        deactivateAndClearSession,
        isAuthorizedCompletely,
        isFullNameRequested
    ]);

    return (
        <SessionExpirationCheckingContext.Provider value={null}>
            {children}
        </SessionExpirationCheckingContext.Provider>
    );
}
