/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {getEnvConfig} from "env/envConfig";
import {api, ReturnedPromiseResolvedType, Unarray} from "../apiService";

export type TSalesDetail = ReturnedPromiseResolvedType<typeof salesDetails> & { asset_data: { deliverable?: boolean; wine_id?: number; } };
export type TSalesList = ReturnedPromiseResolvedType<typeof offersList>;
export type TSalesItem = Unarray<TSalesList>;

export type TAuctionDetail = ReturnedPromiseResolvedType<typeof auctionDetails>;
export type TAuctionList = ReturnedPromiseResolvedType<typeof auctionList>;
export type TAuctionItem = Unarray<TAuctionList>;

// export type TWineListITem = Unarray<ReturnedPromiseResolvedType<typeof winesList>>;
export type TInvoiceList = ReturnedPromiseResolvedType<typeof invoicesList>;
export type TInvoiceItem = Unarray<TInvoiceList>;
export type TValentineList = ReturnedPromiseResolvedType<typeof valentineSpecialSaleList>;

export type TSaleReservationData = ReturnedPromiseResolvedType<typeof getSalerReservationData>;

export const authHeader = (bearer_token: string) => {
    return {headers: {authorization: `Bearer ${bearer_token}`}};
};

export const offersList = async() => {
    try {
        const result = await api.salesList.salesListDetail("active");
        return result.data;
    } catch (err) {
        throw err;
    }
};

export const salesList = async() => {
    const env = getEnvConfig();
    try {
        const result = await api.salesList.salesListDetail("active");
        const saleList = (result.data && result.data.filter((value) => {
            return value.sale_type === "direct_sale";
        })) || [];
        return env.valentineDayEnabled
            // @ts-ignore
            ? saleList.filter((sale: { deliverable?: boolean; wine_id: number; }) => {
                return !env.valentineTokens.includes(sale.wine_id);
            })
            : saleList;
    } catch (err) {
        throw err;
    }
};

export const allWinesList = async() => {
    try {
        const result = await api.winesList.winesListList();
        return result.data;
    } catch (err) {
        throw err;
    }
};

export const valentineSpecialSaleList = async() => {
    const env = getEnvConfig();
    try {
        const result = await api.salesList.salesListDetail("active");
        const saleList = (result.data && result.data.filter((value) => {
            return value.sale_type === "direct_sale";
        })) || [];
        return env.valentineDayEnabled
            // @ts-ignore
            ? saleList.filter((sale: { deliverable?: boolean; wine_id: number; }) => {
                return env.valentineTokens.includes(sale.wine_id);
            })
            : saleList;
    } catch (err) {
        throw err;
    }
};

export const getSalerReservationData = async(userId: string, bearerToken: string, quantity: number, saleId: number) => {
    try {
        const result = await api.user.saleReservationCreate(userId, {quantity, sale_id: saleId}, authHeader(bearerToken));
        return result.data;
    } catch (err) {
        throw err;
    }
};

export const auctionList = async() => {
    try {
        const result = await api.salesList.salesListDetail("active");
        return (result.data && result.data.filter((value) => {
            return value.sale_type === "auction";
        })) || [];
    } catch (err) {
        throw err;
    }
};

export const salesDetails = async(saleId: number, buyer?: string) => {
    try {
        const result = await api.sale.saleDetail(String(saleId), {buyer});
        return result.data || [];
    } catch (err) {
        throw err;
    }
};

export const auctionDetails = async(auctionId: number, buyer?: string) => {
    try {
        const result = await api.sale.saleDetail(String(auctionId), {buyer});
        return result.data || [];
    } catch (err) {
        throw err;
    }
};

export const invoicesList = async(userId: string, bearer_token: string) => {
    try {
        const result = await api.user.invoiceListDetail(userId, authHeader(bearer_token));

        return result.data;
    } catch (err) {
        throw err;
    }
};
