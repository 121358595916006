import {
    RouteComponentProps,
    useHistory,
    useLocation,
    useRouteMatch
} from "react-router-dom";

export const useRouter = (): RouteComponentProps => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();

    return {history, location, match};
};
