import {AuthFacadeContext, SessionDataContext, Web3AuthFacadeContext} from "cnp-frontend-core";
import React, {Context, createContext, ReactElement, useContext, useMemo, useState} from "react";
import {useQuery} from "react-query";
import {getUserPermissions} from "services/api/adminUserService";

type UserAccessContextParams = {
    userPermissions?: { value: string[] };
    hasAdminPermission?: boolean;
    hasShippingPermission?: boolean;
    isLoadingPermissions?: boolean;
    hasB2BPermission?: boolean;
};

export const UserAccessContext: Context<UserAccessContextParams> = createContext({});

export function UserAccessContextProvider({children}: {
	children: ReactElement;
}): JSX.Element {
    const {isAuthorizedCompletely}: AuthFacadeContext = useContext(Web3AuthFacadeContext);

    const {
        userId,
        bearerToken
    } = useContext(SessionDataContext);

    const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);

    const {data: userPermissions} = useQuery<{
        value: string[];
    }>(
        ["userPermissions", userId, bearerToken, isAuthorizedCompletely],
        async() => {
            let result: { value: string[] };
            try {
                const userPermissions = await getUserPermissions(userId as string, bearerToken as string);
                result = {value: userPermissions};
            } catch (err: unknown) {
                console.error(err);
                result = {value: []};
            }
            setTimeout(() => {
                setIsLoadingPermissions(false);
            }, 1000);

            return result;
        },
        {
            refetchOnWindowFocus: false,
            enabled: Boolean(isAuthorizedCompletely)
        }
    );

    const hasAdminPermission = useMemo(() => {
        return (
            userPermissions &&
            userPermissions.value &&
            userPermissions.value.includes("admin")
        );
    }, [userPermissions]);

    const hasShippingPermission = useMemo(() => {
        return (
            userPermissions &&
            userPermissions.value &&
            userPermissions.value.includes("shipping")
        );
    }, [userPermissions]);

    const hasB2BPermission = useMemo(() => {
        return (
            userPermissions &&
            userPermissions.value &&
            userPermissions.value.includes("business")
        );
    }, [userPermissions]);

    const params: UserAccessContextParams = {
        userPermissions,
        hasAdminPermission,
        hasShippingPermission,
        isLoadingPermissions,
        hasB2BPermission
    };

    return (
        <UserAccessContext.Provider value={params}>
            {children}
        </UserAccessContext.Provider>
    );
}
