import React, {useContext, useEffect, useMemo, useState} from "react";
import HamburgerMenu from "react-hamburger-menu";

import OutsideClick from "react-outside-click-handler";
import classNames from "classnames";
import {useHistory} from "react-router";

import styles from "./Header.module.scss";
import {useTranslation} from "react-i18next";
import {ArrowBack} from "components/common/ArrowBack/ArrowBack";
import {ArrowBackContext} from "contexts_and_wrappers/ArrowBackContext";
import {Link} from "react-router-dom";
import {getEnvConfig, isTnd} from "env/envConfig";
import {ProviderSelection} from "../ProviderSelection/ProviderSelection";
import {
    AuthFacadeContext,
    SessionDataContext,
    SessionDataContextParams,
    Web3AuthFacadeContext
} from "cnp-frontend-core";
import {Web3GlobalStorageContext} from "contexts_and_wrappers/Web3GlobalStorageContext";
import {useLogoutCompletely} from "hooks";
import {Loader} from "components/presentational/Loader/Loader";
import {CartContext} from "contexts_and_wrappers/CartContext";
import {UserAccessContext} from "contexts_and_wrappers/UserAccessContext";

export const Header = (): JSX.Element => {
    const [navigationState, setNavigationState] = useState(false);
    const {isShown, changeIsShown} = useContext(ArrowBackContext);
    const history = useHistory();
    const {t} = useTranslation();
    const [size, setSize] = useState<{ height: number; width: number }>(
        {height: 10, width: 23}
    );
    const [isLogoutInProgress, setIsLogoutInProgress] = useState(false);

    const {isCartNotEmpty} = useContext(CartContext);

    const {sessionData}: SessionDataContextParams = useContext(
        SessionDataContext
    );

    const {setServerSaltResponse} = useContext(
        Web3GlobalStorageContext
    );

    const {isAuthorizedWithWeb3, isAuthorizedCompletely}: AuthFacadeContext = useContext(
        Web3AuthFacadeContext
    );

    const {hasAdminPermission, hasB2BPermission} = useContext(UserAccessContext);

    const {logoutAndRedirect} = useLogoutCompletely();

    const logoutHandler = () => {
        setIsLogoutInProgress(true);
        setTimeout(() => {
            logoutAndRedirect();
        }, 2000);
    };

    useEffect(() => {
        changeIsShown(true);
    }, [history.location.pathname, changeIsShown]);

    useEffect(() => {
        const w = window.screen.width;

        if (w >= 768) {
            return setSize({height: 19.5, width: 45});
        }

        window.addEventListener("resize", () => {
            const w = window.screen.width;

            if (w >= 768) {
                setSize({height: 19.5, width: 45});
            } else {
                setSize({height: 10, width: 23});
            }
        });
    }, []);

    const isGooglePage = useMemo(() => {
        const currentRoute = window.location.pathname;
        return /\/google/.test(currentRoute);
    }, []);

    const onArrowBackClick = async() => {
        if (isGooglePage) {
            setServerSaltResponse(null);
            await logoutAndRedirect();
            changeIsShown(false);
        }
    };

    // Menu navigation list

    const renderAdminLink = () => {
        if (isAuthorizedCompletely && hasAdminPermission) {
            return (
                <li className={classNames(styles.li, styles.admin)}>
                    <Link
                        to="/admin"
                        onClick={() => {
                            setNavigationState(false);
                        }}
                    >
                        {t("menu.admin")}
                    </Link>
                </li>
            );
        }
        return <></>;
    };

    const renderB2BLink = () => {
        if (isAuthorizedCompletely && hasB2BPermission) {
            return (
                <li className={classNames(styles.li, styles.admin)}>
                    <Link
                        to="/b2b"
                        onClick={() => {
                            setNavigationState(false);
                        }}
                    >
                        {t("menu.b2b")}
                    </Link>
                </li>
            );
        }
        return <></>;
    };

    const renderTradingPlatform = () => {
        if (!getEnvConfig().tradingPlatformLink) {
            return <></>;
        }
        return (
            <li className={classNames(styles.li)}>
                <Link
                    to={{pathname: getEnvConfig().tradingPlatformLink}}
                    target={"_blank"}
                    rel="noreferrer"
                    onClick={() => {
                        setNavigationState(false);
                    }}
                >
                    {t("menu.trading")}
                </Link>
            </li>
        );
    };

    const renderWineOffers = () => {
        return (
            <li className={styles.li}>
                <Link
                    to="/sale"
                    onClick={() => {
                        setNavigationState(false);
                    }}
                >
                    {t("menu.wineOffers")}
                </Link>
            </li>
        );
    };

    const renderLimitedAuctions = () => {
        return (
            <li className={styles.li}>
                <Link to="/auctions" onClick={() => {
                    setNavigationState(false);
                }}>{t("menu.limitedActions")}</Link>
            </li>
        );
    };

    // const renderValentineOffers = () => {
    //     return (
    //         <li className={styles.li}>
    //             <Link to="/valentine-special" onClick={() => {
    //                 setNavigationState(false);
    //             }}>{t("menu.valentineSpecialOffers")}</Link>
    //         </li>
    //     );
    // };

    const renderWineCellar = () => {
        if (isAuthorizedWithWeb3 && sessionData) {
            return (
                <li className={styles.li}>
                    <Link
                        to="/wine-cellar"
                        onClick={() => {
                            setNavigationState(false);
                        }}
                    >{t("menu.myWineCellar")}</Link>
                </li>
            );
        }
        return <></>;
    };

    const renderLoginOrLogoutItem = () => {
        if (isAuthorizedWithWeb3 && sessionData) {
            return (
                <li className={classNames(
                    styles.li,
                    styles.logoutOption
                )}>
                    <div
                        className={styles.logoutButton}
                        onClick={logoutHandler}
                    >
                        {t("menu.logout")}
                    </div>
                    <div className={styles.loader}>
                        {isLogoutInProgress && (
                            <Loader/>
                        )}
                    </div>
                </li>
            );
        }
        return (<>
            <li className={styles.li}>
                <Link to="/authorize-options?variant=login" onClick={() => {
                    setNavigationState(false);
                }}>{t("menu.login")}</Link>
            </li>
            <li className={styles.li}>
                <Link to="/authorize-options?variant=register" onClick={() => {
                    setNavigationState(false);
                }}>{t("menu.register")}</Link>
            </li>
        </>
        );
    };

    const renderProfile = () => {
        if (
            getEnvConfig().showProfile &&
            sessionData &&
            isAuthorizedWithWeb3
        ) {
            return (
                <li className={styles.li}>
                    <Link
                        to="/profile"
                        onClick={() => {
                            setNavigationState(false);
                        }}
                    >
                        {t("menu.profile")}
                    </Link>
                </li>
            );
        }
        return <></>;
    };

    const renderTransactions = () => {
        if (
            getEnvConfig().showProfile &&
            sessionData &&
            isAuthorizedWithWeb3
        ) {
            return (
                <li className={styles.li}>
                    <Link
                        to="/transactions"
                        onClick={() => {
                            setNavigationState(false);
                        }}
                    >
                        {t("menu.transactions")}
                    </Link>
                </li>
            );
        }
        return <></>;
    };

    const renderAbout = () => {
        return (
            <li className={styles.li}>
                <Link
                    to="/about"
                    onClick={() => {
                        setNavigationState(false);
                    }}
                >
                    {t("menu.aboutUs")}
                </Link>
            </li>
        );
    };

    const renderContacts = () => {
        return (
            <li className={styles.li}>
                <Link
                    to="/contact"
                    onClick={() => {
                        setNavigationState(false);
                    }}
                >
                    {t("menu.contacts")}
                </Link>
            </li>
        );
    };

    const renderEthereumAddress = () => {
        if (isAuthorizedWithWeb3) {
            return (
                <li className={styles.li}>
                    <Link to="/profile" onClick={() => {
                        setNavigationState(false);
                    }}>
                        <ProviderSelection isTruncated={true}/>
                    </Link>
                </li>
            );
        }
        return <></>;
    };

    const renderMyInvoices = () => {
        if (
            getEnvConfig().showProfile &&
            sessionData &&
            isAuthorizedWithWeb3
        ) {
            return <></>;
        }
        return <></>;
    };

    const renderTransportWirehouseCost = () => {
        if (
            getEnvConfig().showProfile &&
            sessionData &&
            isAuthorizedWithWeb3
        ) {
            return <></>;
        }
        return <></>;
    };

    const renderFAQ = () => {
        if (
            getEnvConfig().showProfile &&
            sessionData &&
            isAuthorizedWithWeb3
        ) {
            return <></>;
        }
        return <></>;
    };

    return (
        <OutsideClick onOutsideClick={() => {
            return setNavigationState(false);
        }}>
            <header className={styles.Header}>
                {isShown && (
                    <ArrowBack
                        showOnDesktop={isGooglePage}
                        onClick={onArrowBackClick}
                    />
                )}

                <Link
                    to={"/"}
                    onClick={() => {
                        setNavigationState(false);
                    }}
                >
                    <div className={styles.logo}>
                        <img src={isTnd ? "/images/logo_tnd.svg" : "/images/logo.svg"} alt="site logo"/>
                    </div>
                </Link>

                <button className={styles.button}>
                    <HamburgerMenu
                        isOpen={navigationState}
                        menuClicked={() => {
                            return setNavigationState((state) => {
                                return !state;
                            });
                        }}
                        width={size.width}
                        height={size.height}
                        color="black"
                        animationDuration={0.3}
                    />
                </button>

                <button className={styles.button}>
                    <HamburgerMenu
                        isOpen={navigationState}
                        menuClicked={() => {
                            return setNavigationState((state) => {
                                return !state;
                            });
                        }}
                        width={size.width}
                        height={size.height}
                        color="black"
                        animationDuration={0.3}
                    />
                </button>

                <nav
                    className={classNames(
                        styles.nav,
                        navigationState && styles.navExpanded
                    )}
                >
                    <ul className={styles.ul}>
                        {renderAdminLink()}
                        {renderB2BLink()}
                        {renderTradingPlatform()}
                        {renderWineCellar()}
                        {renderWineOffers()}
                        {!isTnd && renderLimitedAuctions()}
                        {/* {renderValentineOffers()} */}
                        {renderProfile()}
                        {renderTransactions()}
                        {/* {renderMyActivities()} */}
                        {renderMyInvoices()}
                        {renderTransportWirehouseCost()}
                        {renderFAQ()}
                        {renderAbout()}
                        {renderContacts()}
                        {renderLoginOrLogoutItem()}
                        {renderEthereumAddress()}
                    </ul>
                </nav>
            </header>
        </OutsideClick>
    );
};
