import {TWalletDetailItem} from "services/api/wineApiService";

export type TGroupedWineAssets = {
    wineName: string;
    wineId: number;
    tokenIds: number[];
};

export const groupWineAssetsByWineId = (wineAssets: TWalletDetailItem[]): TGroupedWineAssets[] => {
    if (!wineAssets || !Array.isArray(wineAssets)) {
        return [];
    }

    const groups: TGroupedWineAssets[] = wineAssets
        .reduce((prev: TGroupedWineAssets[], curr: TWalletDetailItem) => {
            const existingGroupIndex = prev.findIndex((group) => {
                return group.wineId === curr.wine_id;
            });

            if (existingGroupIndex !== -1) {
                prev[existingGroupIndex].tokenIds.push(curr.token_id);
            } else {
                const newGroup = {
                    wineName: curr.name,
                    wineId: curr.wine_id,
                    tokenIds: [curr.token_id]
                } as TGroupedWineAssets;

                prev.push(newGroup);
            }
            return prev;
        }, []);

    return groups;
};