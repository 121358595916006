import React, {Context, createContext, ReactElement} from "react";
import {GlobalPageLoader} from "../components/presentational/GlobalPageLoader/GlobalPageLoader";
import {useGlobalLoader, UseGlobalLoaderParams} from "../hooks";

export const GlobalLoaderContext: Context<UseGlobalLoaderParams> =
    createContext({
        showLoader: (_: string | undefined) => { },
        hideLoader: () => { }
    });

export function GlobalLoaderContextProvider({children}: {
    children: ReactElement;
}): JSX.Element {
    const params = useGlobalLoader();

    return (
        <GlobalLoaderContext.Provider value={params}>
            {params.isLoaderShown && (
                <GlobalPageLoader text={params.loaderText || ""} />
            )}
            {children}
        </GlobalLoaderContext.Provider>
    );
}
