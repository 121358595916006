import {
    ChangeHandler,
    RegisterOptions,
    useFormContext
} from "react-hook-form";
import classNames from "classnames";
import styles from "./TextInput.module.scss";
import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {Label} from "../Label/Label";

type InputFieldType =
    | "text"
    | "password"
    | "number"
    | "email"
    | "textarea"
    | "time";

type TTextInput = {
    fieldName: string,
    validation: RegisterOptions,
    listedFormValue?: boolean,
    centered?: boolean,
    label?: string,
    type?: InputFieldType,
    isStatic?: boolean,
    max?: number,
    min?: number,
    color?: string,
    textHint?: string,
    largeDesktop?: boolean,
    dismissTouchedValidation?: boolean,
    value?: string | number,
    disabled?: boolean,
    noBottomPadding?: boolean,
    manageBottlePage?: boolean,
    afterInputComponent?: ReactNode,
    isCustomOnChangeHandler?: boolean,
    enableAutocomplete?: boolean;
    exampleText?: string;
    maxLength?: number
    valueStep?: string;
    placeholder?: string;
    maxWineId?: number;
    minWineId?: number;
    defaultValue?: number;
    enableTextAreResize?: boolean;
    onPaste?: ((eventVal: string | number) => void);
    checkEmpySpaces?: boolean
};

export const TextInput = (params: TTextInput): JSX.Element => {
    const {
        fieldName,
        validation,
        listedFormValue,
        label,
        textHint,
        type = "text",
        isStatic,
        max,
        min,
        color,
        centered,
        largeDesktop,
        dismissTouchedValidation,
        value,
        noBottomPadding,
        manageBottlePage,
        afterInputComponent,
        isCustomOnChangeHandler,
        enableAutocomplete,
        exampleText,
        maxLength,
        valueStep,
        placeholder,
        maxWineId,
        minWineId,
        defaultValue,
        enableTextAreResize,
        onPaste,
        checkEmpySpaces
    } = params;

    const {register, formState, watch, setFocus, trigger, setValue} =
        useFormContext();

    const {t, i18n} = useTranslation();

    const touched = watch("touched") || dismissTouchedValidation;
    const fieldValue = watch(fieldName);
    const error = formState.errors[fieldName];
    const registerField = register(fieldName, validation);

    if (isCustomOnChangeHandler) {
        registerField.onChange = (({target}) => {
            setValue(fieldName, target.value);
            trigger();
            return Promise.resolve();
        }) as ChangeHandler;
    }

    const minValue = type === "number" ? min || 0 : min;

    const currentLang = i18n.language;

    const inputBar = {
        autoComplete: enableAutocomplete ? "on" : "nope",
        min: minValue,
        max,
        id: fieldName,
        value,
        ...registerField,
        type,
        step: valueStep && !isNaN(Number(valueStep)) ? valueStep : "any", // for type number
        placeholder,
        lang: currentLang,
        emptyspaces: checkEmpySpaces && fieldValue && fieldValue.trim().length === 0 ? "on" : "off"
    };

    const renderTargetElement = (): JSX.Element => {
        return type === "textarea"
            ? (
                <textarea
                    className={classNames(enableTextAreResize && styles.enableTextAreResize)}
                    maxLength={maxLength}
                    onPaste={(evt) => {
                        if (onPaste) {
                            evt.preventDefault();
                            onPaste && onPaste(evt.clipboardData.getData("Text"));
                        }
                    }}
                    {...inputBar}
                />
            ) : (
                <input
                    defaultValue={defaultValue}
                    {...inputBar}
                />
            );
    };

    const renderTextHint = (): JSX.Element => {
        return (
            <>
                {renderTargetElement()}
                <h6>{textHint}</h6>
            </>
        );
    };

    const isFieldRequired = !!(validation.required);

    return (
        <div className={classNames(
            styles.textinputSection,
            isStatic && styles.isStatic,
            color && styles[color],
            centered && styles.centered,
            listedFormValue && styles.listedFormValue,
            largeDesktop && styles.largeDesktop,
            manageBottlePage && styles.largeDesktop,
            error && touched && styles.error,
            noBottomPadding && styles.noBottomPadding,
            noBottomPadding && styles.noBottomPadding
        )}>
            {
                label && (
                    <Label required={isFieldRequired} color={color as "red" | "white"}>
                        {label}
                    </Label>
                )
            }

            <div
                className={classNames(
                    styles.inputWrapper,
                    textHint && styles.withHint
                )}
                onClick={() => {
                    return setFocus(fieldName);
                }}
            >
                {
                    !isStatic
                        ? textHint
                            ? renderTextHint()
                            : renderTargetElement()
                        : <h6>{fieldValue} / {max}</h6>
                }
            </div>

            {afterInputComponent}

            {maxLength && (
                <div className={classNames(
                    styles.lengthDescription,
                    error && styles.maxLengthError
                )}>
                    {fieldValue?.length}/{t("adminEditBottle.descriptionLength")}
                </div>
            )}

            {inputBar.emptyspaces === "on" && (
                <div className={styles.errorMessage}>{t("fieldErrors.required")}</div>
            )}

            {error && touched && (
                <div className={styles.errorMessage}>{error.message}</div>
            )}

            {exampleText && (
                <div className={styles.example}>{exampleText}</div>
            )}
        </div>
    );
};
