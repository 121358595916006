/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
    api,
    ReturnedPromiseResolvedType,
    Unarray
} from "../apiService";
import {authHeader} from "services/api/authApiService";
import {TLoginResult} from "cnp-frontend-core";
import {getEnvConfig} from "env/envConfig";

export type TChainId =
    | "default"
    | "eth"
    | "ethereum"
    | "ropsten"
    | "goerli"
    | "xdai"
    | "1"
    | "3"
    | "5"
    | "100";

export type TDirection =
    | "sent"
    | "received";

export type TBottleMessage = {
    gift_greeting_message: string | null;
    gift_sender_name?: string | null;
}

export type TGiftTransferList = {
    completed?: boolean;
    greeting_message?: string | null;
    received_timestamp?: number | null;
    sender_name?: string | null;
    sent_timestamp?: number | null;
    target_email?: string | null;
    wine_name: string;
}

export type TAssetDetail = ReturnedPromiseResolvedType<typeof assetDetail>;
export type TWalletDetail = ReturnedPromiseResolvedType<typeof walletDetail>;
export type TWalletDetailItem = Unarray<TWalletDetail["assets"]>;
export type TWineDetail = TWalletDetailItem | TAssetDetail;

export type TWineInfoDetails = ReturnedPromiseResolvedType<typeof wineDetail>;

export const assetDetail = async(
    chainId: TChainId,
    tokenId: string
) => {
    try {
        return (await api.asset.assetDetail(chainId, getEnvConfig().blockchainSymbol, tokenId))
            .data;
    } catch (err) {
        throw err;
    }
};

export const assetPrivateDetail = async(
    userId: string,
    chainId: TChainId,
    tokenId: string,
    sessionData: TLoginResult
): Promise<TBottleMessage> => {
    try {
        return (await api.user.assetPrivateDetail(
            userId,
            chainId,
            getEnvConfig().blockchainSymbol,
            tokenId,
            authHeader(sessionData.bearer_token)))
            .data;
    } catch (err) {
        throw err;
    }
};

export const giftTransferList = async(
    userId: string,
    direction: "sent" | "received",
    bearer_token: string
): Promise<TGiftTransferList[]> => {
    try {
        const result = (await api.user.giftTransferListDetail(
            userId,
            direction,
            authHeader(bearer_token)));
        return result.data;
    } catch (err) {
        throw err;
    }
};

export const assetDetailByClaimCode = async(
    claimCode: string,
    sessionData: TLoginResult
): Promise<TAssetDetail> => {
    const payload = {claim_code: claimCode};
    try {
        const result = (await api.user.assetForClaimCodeCreate(
            sessionData.user_id,
            payload,
            authHeader(sessionData.bearer_token)
        )) as any; // @TODO: fix API docs for return type

        return result.data;
    } catch (err) {
        throw err;
    }
};

export const walletDetail = async(
    chainId: TChainId,
    ethereumAddress: string
) => {
    try {
        const result = await api.wallet.walletDetail(
            chainId,
            ethereumAddress
        );
        return result.data;
    } catch (err) {
        throw err;
    }
};

export const wineDetail = async(wineId: string) => {
    try {
        const result = await api.wineDetails.wineDetailsDetail(wineId);
        return result.data;
    } catch (err) {
        throw err;
    }
};

