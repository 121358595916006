import React, {useContext, useMemo, useState} from "react";
import styles from "./ProviderSelection.module.scss";
import classNames from "classnames";
import {
    AuthFacadeContext,
    ConnectorNames,
    Web3AuthFacadeContext
} from "cnp-frontend-core";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copy from "../../images/copy.svg";
import copyDone from "../../images/copy-done.svg";
import {useTranslation} from "react-i18next";

type TProviderSelection = {
	isTruncated: boolean;
	profilePage?: boolean;
	balance?: number | undefined;
};

export const ProviderSelection = (params: TProviderSelection): JSX.Element => {
    const {profilePage, isTruncated, balance} = params;
    const [isCopiedToClipboard, setCopiedToClipboard] = useState(false);
    const {
        currentWeb3Connector,
        account,
        isAuthorizedCompletely
    }: AuthFacadeContext = useContext(Web3AuthFacadeContext);

    const {t} = useTranslation();

    const truncatedAccount: string = useMemo(() => {
        // first bytes of address: 0x0000.....
        return (
            (isTruncated
                ? account && `${account.slice(0, 8)}...`
                : account) || ""
        );
    }, [account, isTruncated]);

    const imageForWeb3ProviderItem = useMemo(() => {
        if (currentWeb3Connector === ConnectorNames.EthereumBrowser) {
            return "/images/ethereumBrowser.svg";
        }
        if (currentWeb3Connector === ConnectorNames.WalletConnect) {
            return "/images/walletconnect-circle-blue.svg";
        }
        if (currentWeb3Connector === ConnectorNames.InBrowserWallet) {
            return "/images/in-browser-wallet-icon.svg";
        }
        return "";
    }, [currentWeb3Connector]);

    const changeClipboardIcon = () => {
        setCopiedToClipboard(true);
        setTimeout(() => {
            setCopiedToClipboard(false);
        }, 3000);
    };

    const isInBrowserWalletBalanceShown = useMemo(() => {
        return (
            currentWeb3Connector === ConnectorNames.InBrowserWallet &&
			profilePage &&
			balance !== null
        );
    }, [currentWeb3Connector, profilePage, balance]);

    if (currentWeb3Connector && account && isAuthorizedCompletely) {
        return (
            <>
                <div
                    className={classNames(
                        styles.web3ProviderSelection,
                        profilePage && styles.profilePage
                    )}
                >
                    <div className={styles.web3ProviderInfo}>
                        <>
                            <div className={styles.web3ProviderItem}>
                                <img
                                    src={imageForWeb3ProviderItem}
                                    alt={currentWeb3Connector}
                                    className={styles.web3ProviderImage}
                                />
                            </div>
                            {truncatedAccount && (
                                <CopyToClipboard text={truncatedAccount}>
                                    <span className={styles.address}>
                                        {truncatedAccount}
                                        <img
                                            className={classNames(
                                                styles.copyIcon,
                                                isCopiedToClipboard && styles.isCopiedToClipboard
                                            )}
                                            src={isCopiedToClipboard ? copyDone : copy}
                                            alt=""
                                            onClick={changeClipboardIcon}
                                        />
                                        {isCopiedToClipboard ? (
                                            <div className={styles.copyText}>
                                                {t("providerSelector.copiedToClipboard")}
                                            </div>
                                        ) : <></>}
                                    </span>
                                </CopyToClipboard>
                            )}

                        </>
                    </div>
                    {/* { isInBrowserWalletBalanceShown &&
                        <p className={styles.address}>{balance} ETH</p>
                    } */}
                </div>
            </>
        );
    }

    return <></>;
};
