import React, {ReactChildren, ReactNode} from "react";

import classNames from "classnames";

import styles from "./Button.module.scss";
import {Loader} from "../../presentational/Loader/Loader";

export type TButtonType = "red" | "white" | "darken" | "lighten";

type TActionType = "submit" | "button" | "reset";

type TButton = {
	children: ReactNode | ReactChildren | string;
	type?: TButtonType;
	strictPadding?: boolean;
	actionType?: TActionType;
	disabled?: boolean;
	onClick?: () => void;
	loading?: boolean;
	profilePage?: boolean;
	registerGoogleForm?: boolean;
    switchButton?: boolean;
};

export const Button = (params: TButton): JSX.Element => {
    const {
        children,
        type = "darken",
        disabled,
        actionType,
        strictPadding,
        onClick,
        loading,
        profilePage,
        registerGoogleForm,
        switchButton
    } = params;

    return (
        <div
            className={classNames(
                styles.ButtonWrapper,
                loading && styles.loading,
                switchButton && styles.switchButton,
                profilePage && styles.profilePage,
                registerGoogleForm && styles.registerGoogleForm
            )}
        >
            <button
                className={classNames(
                    styles.Button,
                    styles[type],
                    strictPadding && styles.strictPadding,
                    disabled && styles.disabled
                )}
                onClick={onClick || (() => {
                    return;
                })}
                disabled={Boolean(disabled)}
                type={actionType || "button"}
            >
                {children}
            </button>
            <div className={styles.loader}>
                <Loader />
            </div>
        </div>
    );
};
