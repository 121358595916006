import {SessionDataContext, SessionDataContextParams} from "cnp-frontend-core";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {invoicesList, TInvoiceList} from "services/api/auctionAndSaleApiService";
import {extractErrorMessage} from "utils/extractErrorMessage";
import {Button} from "../Button/Button";
import {InvoiceDownload} from "../FormComponents/InvoiceDownload/InvoiceDownload";
import styles from "./InvoiceList.module.scss";

type TInvoice = {
    setError: (_: string) => void;
}

export const InvoiceList = ({setError}: TInvoice): JSX.Element => {
    const [moreInvoices, setMoreInvoices] = useState(false);

    const {t} = useTranslation();

    const {
        userId,
        bearerToken
    }: SessionDataContextParams = useContext(SessionDataContext);

    const {data: invoicesListData} = useQuery<TInvoiceList>(
        ["invoicesListData", userId, bearerToken],
        async() => {
            if (!userId || !bearerToken) {
                throw new Error("Not authorized");
            }
            const invoices = await invoicesList(userId, bearerToken);
            return invoices;
        },
        {
            refetchOnWindowFocus: false,
            enabled: Boolean(userId && bearerToken),
            onError: (err: unknown) => {
                setError(extractErrorMessage(err));
                return [];
            }
        }
    );

    if (!invoicesListData || !Array.isArray(invoicesListData)) {
        return (
            <div className={styles.noInvoicesList}>
                <span className={styles.noInvoiceWarning}>
                    {t("profile.no_invoices")}
                </span>
            </div>
        );
    }

    const invoicesWithPdfAvailable = invoicesListData
        .filter((e) => {
            return e.pdf_available;
        })
        .map((e) => {
            return (
                <div key={e.invoice_number}>
                    <InvoiceDownload value={e} />
                </div>
            );
        });

    return (
        <>
            { invoicesWithPdfAvailable.length === 0 && (
                <div className={styles.noInvoicesList}>
                    <span className={styles.noInvoiceWarning}>
                        {t("profile.no_invoices")}
                    </span>
                </div>
            ) }

            { invoicesWithPdfAvailable.length > 0 && (
                <div className={styles.InvoicesList}>
                    {moreInvoices ? invoicesWithPdfAvailable : invoicesWithPdfAvailable?.slice(0, 5)}
                </div>
            ) }

            {invoicesListData && invoicesListData?.length > 6 && (
                <Button
                    onClick={() => {
                        return setMoreInvoices((state) => {
                            return !state;
                        });
                    }}
                    profilePage={true}
                >
                    {!moreInvoices
                        ? t("home.introductionMore")
                        : t("home.introductionHidde")}
                </Button>
            )}
        </>
    );
};
