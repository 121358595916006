/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {TFunction} from "i18next";
import {TGroupsCellar} from "components/pages/WineCellar/entitites";
import {Duration} from "luxon";
import {ProfileFormPayload} from "components/pages/Profile/Profile";
import {TWineDetail} from "services/api/wineApiService";
import {RegistrationPayload} from "components/presentational/Auth/declarations";
import {isTnd} from "env/envConfig";


const SortArray = (x: TGroupsCellar, y: TGroupsCellar) => {
    return x.key.localeCompare(y.key);
};

export const groupWines = (array: TWineDetail[], filterField: string) => {
    const groups = array.reduce((
        acc: { [name: string]: TWineDetail[] },
        value: TWineDetail
    ) => {
        if (
            filterField === "drinking_timestamp_optimal" ||
                filterField === "drinking_timestamp_maximum"
        ) {
            // @FIXME: later check types there
            // @ts-ignore
            const time = new Date(Number(value[filterField]) * 1000);
            const month = time.getMonth();
            const year = time.getFullYear();
            const formatName = `${month}-${year}`;

            (acc[formatName] = acc[formatName] || []).push(value);
            return acc;
        }
        // @FIXME: later check types there
        // @ts-ignore
        (acc[value[filterField]] = acc[value[filterField]] || []).push(value);

        return acc;
    },
    {});

    const result: TGroupsCellar[] = Object.keys(groups).map((key: string) => {
        return {key: key, list: groups[key]};
    });
    const sortedResult = result.sort(SortArray);

    return sortedResult;
};

export const getFormatDateString = (
    startDateSeconds: number,
    endDateSeconds: number,
    t: TFunction,
    locale: string
) => {
    if (!startDateSeconds && !endDateSeconds) return "";

    const startDateMillis = startDateSeconds * 1000;
    const endDateMillis = endDateSeconds
        ? endDateSeconds * 1000
        : 0;

    let duration: Duration;
    let formatEnd: Record<string, string>;

    const now = Date.now();

    if (now < startDateMillis) {
        duration = Duration.fromMillis(startDateMillis - now);
        formatEnd = {
            de: "'startet in' d 'Tagen' h 'Stunden'",
            en: "'starts in' d 'days' h 'hours'"
        };
    } else if (now < endDateMillis) {
        duration = Duration.fromMillis(endDateMillis - now);
        formatEnd = {
            de: "'noch' d 'Tage' h 'Stunden'",
            en: "d 'days' h 'hours remaining'"
        };
    } else if (Date.now() >= endDateMillis) {
        duration = Duration.fromMillis(now - endDateMillis);
        formatEnd = {
            de: "'noch' d 'Tage' h 'Stunden'",
            en: "d 'days' h 'hours remaining'"
        };
    } else {
        throw Error("unknown date range!?");
    }

    return duration.toFormat(formatEnd[locale]);
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const getDescription = (
    assetData: { description_de?: string; description_en?: string },
    lang: string
) => {
    if (assetData) {
        const descripition = lang.toLowerCase().startsWith("de")
            ? assetData.description_de
            : assetData.description_en;
        return descripition || "";
    } else {
        return "";
    }
};

export const getFormatPayloadForRegistration = (payload: RegistrationPayload | ProfileFormPayload) => {
    const newPayload: { key: string; new_value: string }[] = [];
    Object.keys(payload).forEach((key) => {
        newPayload.push({
            key,
            new_value: String((payload as any)[key] || "")
        });
    });
    return newPayload;
};

export const THRID_COUNTRY_TAX_REGION = "XX";
//because of VAT handling within the EU-OSS (one stop shop), we do have to use tax region XX (27%) everywhere except Austria - VAT will be handled on shipping quote request
// export const SUPPORTED_TAX_REGIONS = ["AT"];
export const ALL_EU_TAX_REGIONS = ["DE", "IT", "BE", "NL", "FR", "IE", "LU", "PT", "ES", "FI", "AT", "DK", "GR", "HU", "SK", "CZ", "PL", "LT", "MT", "BG", "RO", "EE", "LV", "HR", "SI", "CY"];
export const SUPPORTED_TAX_REGIONS = ALL_EU_TAX_REGIONS;

type TChainId =
    | 1
    | 3
    | 5
    | 100;

export const isChainId = (arg: any): arg is TChainId => {
    return arg && (
        arg === "default" ||
        arg === "eth" ||
        arg === "ethereum" ||
        arg === "ropsten" ||
        arg === "goerli" ||
        arg === "xdai" ||
        arg === "1" ||
        arg === "3" ||
        arg === "5" ||
        arg === "100"
    );
};
export const TAX_PERMIL: Record<string, number> = {
    "XX": 270,
    "DE": 190,
    "IT": 220,
    "BE": 210,
    "NL": 210,
    "FR": 200,
    "IE": 230,
    "LU": 170,
    "PT": 230,
    "ES": 210,
    "FI": 240,
    "AT": 200,
    "DK": 250,
    "GR": 240,
    "HU": 270,
    "SK": 200,
    "CZ": 210,
    "PL": 230,
    "LT": 210,
    "MT": 180,
    "BG": 200,
    "RO": 190,
    "EE": 200,
    "LV": 210,
    "HR": 250,
    "SI": 220,
    "CY": 190
};

export const getTaxPermil = (countryCode: string) => {
    return TAX_PERMIL[countryCode] || TAX_PERMIL["XX"];
};

export const getMinEuroGrossPrice = (minPriceCents: number, taxRegion: string) => {
    const defaultTaxPermil = TAX_PERMIL[taxRegion];
    const netMinEuro = Number(minPriceCents) / 100;
    const estimatedGross = netMinEuro * (1 + (defaultTaxPermil / 1000));

    return isNaN(estimatedGross)
        ? 0
        : Math.round(estimatedGross * 100) / 100;
};

export const convertFileToBase64 = (file: File): Promise<string | null> => {
    if (!file) {
        return Promise.resolve(null);
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            return resolve(reader.result as string | null);
        };
        reader.onerror = (error) => {
            return reject(error);
        };
    });
};

export type TImageExtension = "png" | "jpg";
export const getImageExtension = (file: File | null): TImageExtension => {
    if (!file) {
        return "png";
    }
    const fileParts = file.name.split(".");
    return (fileParts[fileParts.length - 1] as TImageExtension) || "png";
};
export const getBas64ImageExtension = (imageBase64: string): TImageExtension => {
    if (!imageBase64) {
        return "png";
    }
    const [, extension] = (imageBase64 as string).split(";")?.[0]?.split("/");
    return extension as TImageExtension || "png";
};

export const getSizeDescription = (volumeMl: number) => {
    if (!volumeMl || isNaN(Number(volumeMl))) return "---";
    if (volumeMl === 1500) return "Magnum 1500ml";
    return `${volumeMl} ml`;
};

export function formatAwardsString(awards?: string[]) {
    return awards?.join(", ");
}

export function getDocumentLink(language: string, linkType: "privacy_policy_current" | "agb_current") {
    let ret = "/agb/";
    if (isTnd) {
        ret = `${ret}tnd/`;
    }
    ret = ret + linkType;
    ret = `${ret}_${language}.pdf`;
    return ret;
}
