import React, {Context, createContext, ReactElement} from "react";
import {
    useArrowBack,
    UseArrowBackParams
} from "../hooks/useArrowBack";

export const ArrowBackContext: Context<UseArrowBackParams> =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createContext({changeIsShown: (_: boolean) => {}});

export function ArrowBackContextProvider({children}: {
	children: ReactElement;
}): JSX.Element {
    const params = useArrowBack();

    return (
        <ArrowBackContext.Provider value={params}>
            {children}
        </ArrowBackContext.Provider>
    );
}
