import React from "react";
import styles from "./SelectLanguage.module.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

type SelectLanguageParams = {
	homePage?: boolean;
	footer?: boolean;
	manageBottlePage?: boolean;
};

export const SelectLanguage = (params: SelectLanguageParams): JSX.Element => {
    const {homePage, footer, manageBottlePage} = params;
    const {i18n} = useTranslation();

    return (
        <div
            className={classNames(
                styles.languageSelection,
                homePage && styles.homePage,
                footer && styles.footer,
                manageBottlePage && styles.manageBottlePage
            )}
        >
            <button
                onClick={() => {
                    return i18n.changeLanguage("de");
                }}
                className={classNames(
                    i18n.language === "de" && styles.selected
                )}
            >
				DE
            </button>
			|
            <button
                onClick={() => {
                    return i18n.changeLanguage("en");
                }}
                className={classNames(
                    i18n.language === "en" && styles.selected
                )}
            >
				EN
            </button>
        </div>
    );
};
