import classNames from "classnames";
import React from "react";
import {useTranslation} from "react-i18next";
import {TGiftTransferList} from "services/api/wineApiService";
import styles from "./MessageBox.module.scss";

type TMessageBox = {
    typeMessage: string;
    descriptionMessage?: TGiftTransferList[];
};

export const MessageBox = (params: TMessageBox): JSX.Element => {
    const {
        typeMessage,
        descriptionMessage
    } = params;

    const {i18n} = useTranslation();

    return (
        <>
            <div className={classNames(
                styles.MessageList,
                (!descriptionMessage || descriptionMessage.length === 0) && styles.NoDataAvailable
            )}>
                {descriptionMessage && descriptionMessage.length > 0 && descriptionMessage.map((message, key) => {
                    const messageDataString = new Date(
                        typeMessage === "sent"
                            ? (message.sent_timestamp as number) * 1000
                            : (message.received_timestamp as number) * 1000
                    ).toLocaleDateString(i18n.language);

                    return (
                        <div className={styles.Message} key={`${key}-${descriptionMessage.length}`}>
                            <p className={styles.MessageDate}>{messageDataString}</p>
                            <div className={styles.MessageBox}>
                                <p className={styles.MessageBoxWineName}>{message.wine_name}</p>
                                <p className={styles.MessageBoxWineName}>
                                    <span>{typeMessage}</span>
                                    {typeMessage === "sent" ? message.target_email : message.sender_name}
                                </p>
                                <span className={styles.MessageText}>{message.greeting_message}</span>
                            </div>
                        </div>
                    );
                })}

                {(!descriptionMessage || descriptionMessage.length === 0) && (
                    <div className={styles.Message}>No data available</div>
                )}
            </div>
        </>
    );
};