import React from "react";
import styles from "./ComingSoon.module.scss";
import {useTranslation} from "react-i18next";

export const ComingSoon = (): JSX.Element => {
    const {t} = useTranslation();

    return (
        <>
            <section className={styles.ComingSoonBlock}>
                <img
                    className={styles.GrapesBackground}
                    src="/images/grapesBackground.svg"
                    alt="grapes"
                />
                <h3 className={styles.WarningMessage}>
                    <span className={styles.ComingSoonTitle}>
                        {t("comingSoon")}
                    </span>
                </h3>
            </section>
        </>
    );
};
