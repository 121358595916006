/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
    api,
    ReturnedPromiseResolvedType
} from "services/apiService";
import {getEnvConfig} from "env/envConfig";
import {TChainId} from "services/api/wineApiService";

export type TApiVersion = ReturnedPromiseResolvedType<
	typeof getApiVersionAsync
>;
export type TTransferParams = ReturnedPromiseResolvedType<
	typeof getTransferParamsForEscrow
>;

export const getApiVersionAsync = async() => {
    try {
        const result = await api.status.statusList();
        return result.data.api_version || null;
    } catch (err) {
        throw err;
    }
};
const chainIdStr:
	| "default"
	| "eth"
	| "ethereum"
	| "ropsten"
	| "goerli"
	| "xdai"
	| "1"
	| "3"
	| "5"
	| "100" = String(getEnvConfig().defaultChainId) as TChainId;

export const getTransferParamsForEscrow = async(tokenId: string) => {
    try {
        const result = await api.parameters.transferDetail(
            chainIdStr,
            getEnvConfig().blockchainSymbol,
            tokenId
        );
        return result.data;
    } catch (err) {
        throw err;
    }
};
