import React, {ReactNode} from "react";
import {useFormContext, ValidationRule} from "react-hook-form";

import styles from "./Checkbox.module.scss";
import classNames from "classnames";

type TCheckbox = {
	fieldName: string;
	validation: { [name: string]: ValidationRule };
	listedFormValue?: boolean;
	label: ReactNode;
	disabled?: boolean;
	invertFormColors?: boolean;
};

export const Checkbox = (params: TCheckbox): JSX.Element => {
    const {fieldName, label, validation, disabled, invertFormColors} =
		params;

    const {register, watch, formState} = useFormContext();

    const touched = watch("touched");
    const error = formState.errors[fieldName];

    return (
        <div className={styles.Checkbox}>
            <input
                className={classNames(
                    styles.check,
                    error && touched && styles.error,
                    invertFormColors && styles.invertFormColors
                )}
                type="checkbox"
                id={fieldName}
                disabled={disabled}
                {...register(fieldName, validation)}
            />
            <label className={styles.checkboxLabel} htmlFor={fieldName}>
                {label}
            </label>
        </div>
    );
};
