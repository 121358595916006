export const splitTabSeparatedValues = (value: string | undefined): string[][] => {
    if (!value || !value.trim()) {
        return [];
    }

    return value
        .split("\n")
        .map((line: string) => {
            return line.split("\t");
        });
};

export const clearRowsValue = (value: string): string => {
    if (!value || !value.trim()) {
        return "";
    }

    return value
        .trim()
        .split("\n")
        .map((line: string) => {
            return line.trim();
        })
        .join("\n");
};
