import {useState} from "react";

export type UseArrowBackParams = {
	isShown?: boolean;
	changeIsShown: (_: boolean) => void;
};

export const useArrowBack = (): UseArrowBackParams => {
    const [isShown, setIsShown] = useState<boolean>(true);

    const changeIsShown = (visibility: boolean) => {
        setIsShown(visibility);
    };

    return {
        isShown,
        changeIsShown
    };
};
