import React, {useContext, useRef} from "react";
import styles from "./InvoiceDownload.module.scss";
import downloadBtn from "components/images/downloadBtn.svg";
import {TInvoiceItem} from "services/api/auctionAndSaleApiService";
import {
    invoicePdfCode,
    invoicePdfDetail
} from "services/api/authApiService";
import {
    SessionDataContext,
    SessionDataContextParams
} from "cnp-frontend-core";

type InvoiceDownloadParams = {
	value: TInvoiceItem;
};

export const InvoiceDownload = ({value}: InvoiceDownloadParams): JSX.Element => {
    const {sessionData}: SessionDataContextParams = useContext(
        SessionDataContext
    );
    const hrefRef = useRef(null);

    function savePdf(url: string, fileName: string) {
        const a = document.createElement("a");

        a.setAttribute("download", fileName);
        a.setAttribute("href", url);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const onInvoiceChooseHandler = async() => {
        if (!sessionData) {
            return;
        }
        const accessCode = await invoicePdfCode(
            value.invoice_number,
            sessionData
        );
        const {url} = await invoicePdfDetail(
            value.invoice_number,
            sessionData,
            accessCode
        );

        savePdf(url, `${value.invoice_number}.pdf`);
    };

    return (
        <div
            onClick={onInvoiceChooseHandler}
            className={styles.downloadCheck}
            ref={hrefRef}
        >
            <div className={styles.downloadCheckDescripition}>
                <span>{value.date.replace(/-/gi, ".")}</span>
                <span>#{value.invoice_number}</span>
            </div>
            <button type="button">
                <img src={downloadBtn} alt="download" />
            </button>
        </div>
    );
};
