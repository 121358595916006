import React, {Context, createContext, ReactElement} from "react";
import {useError, UseErrorParams} from "../hooks/useError";

export const ErrorContext: Context<UseErrorParams> = createContext({
    changeErrorText: (_: string | undefined) => {},
    clearErrorText: () => {}
});

export function ErrorContextProvider({children}: {
	children: ReactElement;
}): JSX.Element {
    const params = useError();

    return (
        <ErrorContext.Provider value={params}>
            {children}
        </ErrorContext.Provider>
    );
}
