import classNames from "classnames";
import React from "react";
import {useRouter} from "../../../hooks";
import {ArrowLeft} from "../../icons/Arrows/ArrowLeft";
import styles from "./ArrowBack.module.scss";

type ArrowBackParams = {
    showOnDesktop?: boolean;
    onClick?: (() => void) | (() => Promise<void>);
};

export const ArrowBack = (params: ArrowBackParams): JSX.Element => {
    const {
        showOnDesktop,
        onClick
    } = params;

    const {history} = useRouter();

    const handleClick = () => {
        if (onClick !== null && typeof onClick === "function") {
            onClick();
        }
        history.goBack();
    };

    return (
        <div
            className={classNames(styles.ArrowBackContainer, showOnDesktop && styles.showOnDesktop)}
            onClick={handleClick}
            aria-hidden="true"
        >
            <ArrowLeft type="black" height={14} width={23} />
        </div>
    );
};
