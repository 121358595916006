import React from "react";

import {Loader} from "../Loader/Loader";

import styles from "./GlobalPageLoader.module.scss";

export type GlobalPageLoaderParams = {
	text: string;
};

export const GlobalPageLoader = ({text}: GlobalPageLoaderParams): JSX.Element => {
    return (
        <div className={styles.GlobalPageLoader}>
            <div className={styles.LoaderWrapper}>
                <Loader />
                {text && (
                    <h6 className={styles.LoaderOptionalText}>{text}</h6>
                )}
            </div>
        </div>
    );
};
